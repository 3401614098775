import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { InternalApiHttpError } from '@triple/triad';
import { createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import { catchError, filter, firstValueFrom, map, of, tap, throwError } from 'rxjs';

import { UserApi } from '../apis/user.api';
import { UserActions, selectCurrentUser } from '../store/user';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly store = inject(Store);
  private readonly userApi = inject(UserApi);

  get currentUser$() {
    return this.store.select(selectCurrentUser).pipe(filter((v) => v !== undefined));
  }
  get isEnabled$() {
    return this.currentUser$.pipe(map((v) => !!v?.enabled));
  }
  get isSmsVerified$() {
    return this.currentUser$.pipe(map((v) => !!v?.smsVerified));
  }

  fetchCurrentUser() {
    return this.userApi.fetchCurrentUser().pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return of(null);
        }
        return throwError(() => err);
      }),
      tap((user) => {
        this.store.dispatch(UserActions.setCurrentUser({ user }));
      }),
    );
  }

  deleteCurrentUser() {
    const source$ = this.userApi.deleteCurrentUser().pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('アカウントの削除に失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }
}
