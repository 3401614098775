import { IdpConfig, IdpAssets } from '@triple/triad-idp';

import { environment } from '@environment';

export const idpConfig: IdpConfig = {
  apiOrigin: environment.apiOrigin,
  authEmailDomain: '__FIXME__',
};

export const idpAssets: IdpAssets = {
  relatedSiteLinks: {
    ticketSiteEntryHistoryLink: {
      type: 'external',
      label: 'チケット申込履歴',
      href: `${environment.groupSiteUrls.ticket}/mypage/entries`,
    },
    ecommerceSitePurchaseHistoryLink: {
      type: 'external',
      label: 'ショップ注文履歴',
      href: `${environment.groupSiteUrls.store}/account`,
    },
  },
};
