import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, inject } from '@angular/core';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';
import { takeUntil } from 'rxjs';

import { ButtonComponent } from '../button/button.component';
import { ModalComponents } from '../modal';

import { AlertConfig, AlertResult } from './alert.type';

@Component({
  selector: 'tpl-alert',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, ModalComponents, ButtonComponent],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, OnDestroy {
  readonly data = inject<AlertConfig>(DIALOG_DATA);
  private readonly dialogRef = inject<DialogRef<AlertResult, AlertComponent>>(DialogRef);
  private readonly onDestroy$ = new EventEmitter();

  get message() {
    return this.data.message.replace(/\n/g, '<br>');
  }

  ngOnInit() {
    this.dialogRef.backdropClick.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const canceledButton = this.data.buttons.find((b) => b.role === 'cancel');
      if (canceledButton) {
        this.dialogRef.close({ role: 'cancel', value: canceledButton.value });
      } else {
        this.dialogRef.close({ role: 'cancel' });
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit();
  }

  buttonHandler(index: number) {
    const button = this.data.buttons[index];
    this.dialogRef.close({ role: button?.role, value: button?.value });
  }
}
