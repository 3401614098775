import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, inject } from '@angular/core';
import { IconComponent, TouchableDirective } from '@twogate-npm/toolbox-angular';

import { ButtonColor, ButtonType } from './button.type';

@Component({
  selector: 'a[tpl-button], button[tpl-button]',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  hostDirectives: [TouchableDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input()
  color: ButtonColor = 'primary';

  @Input()
  buttonType: ButtonType = 'filled';

  @Input()
  size: 'regular' | 'small' = 'regular';

  @Input()
  width: 'full' | 'auto' | 'fixed' = 'fixed';

  @Input()
  icon:
    | 'arrow-right'
    | 'arrow-left'
    | 'external-link'
    | 'plus'
    | 'minus'
    | 'close'
    | 'alert'
    | 'alert-outline'
    | 'alert-diamond'
    | 'return'
    | 'sync'
    | 'sign-in'
    | 'sign-out'
    | 'account'
    | null = null;

  @Input()
  iconPosition: 'left' | 'right' = 'right';

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  private readonly elementRef = inject<ElementRef<HTMLButtonElement | HTMLAnchorElement>>(ElementRef);

  ngOnInit() {
    this.elementRef.nativeElement.classList.add(...this.buttonClass);
  }

  private get buttonClass() {
    return [
      `color-${this.color}`,
      `size-${this.size}`,
      `type-${this.buttonType}`,
      `width-${this.width}`,
      `icon-${this.iconPosition}`,
    ];
  }
}
