<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <a routerLink="/mypage">{{ 'shared.mypage.title' | locale }}</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>メールアドレス変更</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <idp-box>
    <span slot="header">メールアドレスの有効確認</span>
    @if (state$ | async; as state) {
      <tpl-form-field>
        <tpl-form-label>現在のメールアドレス</tpl-form-label>
        <div class="form-value">{{ state.email }}</div>
      </tpl-form-field>
    }
    <tpl-form-field>
      <tpl-form-label>変更先メールアドレス</tpl-form-label>
      <input
        type="email"
        placeholder="user@example.com"
        [formControl]="emailForm"
        tplPressEnter
        (pressEnter)="submit()"
      />
    </tpl-form-field>
    <div class="description">
      メールアドレスを入力し、［送信する］を押してください。 確認コードをメールをお送りいたします。
      次に表示されるページで確認コードを入力し、1時間以内にお客様情報をご登録ください。
    </div>
    <div class="description">
      ※ドメイン（アドレス）指定受信・拒否などを設定されている場合、 メールが届きません。「{{
        authEmailDomain
      }}」が受信できるように設定の上、お手続きください。
    </div>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        icon="arrow-right"
        width="fixed"
        [disabled]="emailForm.invalid"
        (click)="submit()"
      >
        送信する
      </button>
    </div>
  </idp-box>
  <div class="box-action">
    <button
      tpl-button
      class="plain-button size-small"
      buttonType="plain"
      width="auto"
      size="small"
      icon="arrow-left"
      iconPosition="left"
      routerLink="/mypage"
      color="basic"
    >
      {{ 'shared.mypage.title' | locale }}へ戻る
    </button>
  </div>
</idp-page-container>
