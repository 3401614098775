import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInfoOverviewComponent } from '@triple/triad';

import { User } from '../../models/user.type';

@Component({
  selector: 'idp-user-info',
  standalone: true,
  imports: [CommonModule, UserInfoOverviewComponent],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {
  @Input()
  user!: User;

  get name() {
    return `${this.user.profile.lastName} ${this.user.profile.firstName}`;
  }

  get email() {
    return this.user.email;
  }
}
