import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent, FormComponents, PressEnterDirective } from '@triple/triad';

import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { AuthUsecaseService } from '../../services/auth-usecase.service';

@Component({
  selector: 'page-email-code-handler',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    ButtonComponent,
    FormComponents,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
  ],
  templateUrl: './email-code-handler.page.html',
  styleUrls: ['./email-code-handler.page.scss'],
})
export class EmailCodeHandlerPage implements OnInit {
  @Input() userId?: string;
  @Input() email?: string;

  codeForm = new FormControl<string>('', { nonNullable: true, validators: [Validators.required] });

  private readonly router = inject(Router);
  private readonly authUsecase = inject(AuthUsecaseService);

  ngOnInit() {
    if (!this.userId) {
      this.router.navigate(['/auth/sign_in']);
    }
  }

  submit() {
    if (this.codeForm.invalid) {
      return;
    }

    if (this.userId && this.email) {
      this.authUsecase.verifyEmail(this.userId, this.codeForm.value, this.email);
    }
  }
}
