<div class="actions">
  <button
    tpl-button
    buttonType="filled"
    [color]="signInButtonColor"
    icon="sign-in"
    iconPosition="left"
    class="sign-in-button"
    (click)="navigateSignIn()"
  >
    <div class="text">ログイン</div>
  </button>
  <div class="sign-up">
    <div class="note">アカウントをお持ちでない方</div>
    <button
      tpl-button
      buttonType="filled"
      width="auto"
      [color]="signUpButtonColor"
      icon="account"
      iconPosition="left"
      size="small"
      class="sign-up-button"
      (click)="navigateSignUp()"
    >
      <div class="text">アカウントを作成する</div>
    </button>
  </div>
</div>
