import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

export type SiteMenuButtonBarType = 'slim' | 'default';

@Component({
  selector: 'tpl-site-menu-button',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents],
  templateUrl: './site-menu-button.component.html',
  styleUrls: ['./site-menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMenuButtonComponent {
  @Input()
  siteType: 'ticket' | 'idp' = 'ticket';
  @Input()
  isOpen = false;
  @Input()
  barType: SiteMenuButtonBarType = 'default';
}
