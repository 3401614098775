import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TriadAssetsToken, SiteMenuButtonComponent } from '@triple/triad';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { SiteMenuComponent } from '../site-menu/site-menu.component';

@Component({
  selector: 'idp-site-header',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, SiteMenuButtonComponent, RouterLink, SiteMenuComponent],
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderComponent {
  @Input()
  siteType: 'ticket' | 'idp' = 'ticket';
  isOpen = false;
  readonly showSiteType = inject(TriadAssetsToken).siteHeader?.showSiteType;
  readonly menuButtonBarType = inject(TriadAssetsToken).siteHeader?.menuButtonBarType ?? 'default';

  private readonly asset = inject(TriadAssetsToken);

  get logoImage() {
    return this.asset.logoImage;
  }
}
