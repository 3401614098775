import { Injectable, inject } from '@angular/core';
import { BaseLocaleService } from '@twogate-npm/toolbox-angular';

import { IdpLocaleMaster } from '../models/locale-master.type';
import { IdpLocaleToken } from '../models/locale.type';

@Injectable({ providedIn: 'root' })
export class IdpLocaleService extends BaseLocaleService<IdpLocaleMaster> {
  locale = inject(IdpLocaleToken);
}
