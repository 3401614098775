<idp-page-container>
  <idp-box>
    <span slot="header">パスワードの登録</span>
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <div class="form-value">{{ email }}</div>
    </tpl-form-field>
    @if (phoneNumber) {
      <tpl-form-field>
        <tpl-form-label>電話番号</tpl-form-label>
        <div class="form-value">{{ phoneNumber }}</div>
      </tpl-form-field>
    }
    <tpl-form-field>
      <tpl-form-label [required]="true">パスワード</tpl-form-label>
      <idp-password-form
        passwordType="new-password"
        [form]="passwordForm"
        tplPressEnter
        (pressEnter)="submit()"
      ></idp-password-form>
      <idp-password-pattern-error [errors]="passwordForm.errors"></idp-password-pattern-error>
    </tpl-form-field>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="passwordForm.invalid"
        (click)="submit()"
      >
        パスワードを登録する
      </button>
    </div>
  </idp-box>
</idp-page-container>
