<div class="modal">
  <div class="modal-header" #headerRef>
    <ng-content select="tpl-modal-header"></ng-content>
  </div>
  @if (headerRef.childNodes.length === 0) {
    <div class="modal-close" tbxTouchable (click)="close.emit()"></div>
  }

  <div class="modal-content" #modalContent>
    <ng-content></ng-content>
  </div>

  <div class="modal-footer">
    <ng-content select="tpl-modal-footer"></ng-content>
  </div>
</div>
