<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <a routerLink="/mypage">{{ 'shared.mypage.title' | locale }}</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>パスワード変更</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <idp-box>
    <span slot="header">パスワード変更</span>
    <div class="description">新しいパスワードを設定してください</div>
    <tpl-form-field>
      <tpl-form-label>パスワード</tpl-form-label>
      <idp-password-form
        [form]="passwordForm"
        passwordType="new-password"
        tplPressEnter
        (pressEnter)="submit()"
      ></idp-password-form>
      <idp-password-pattern-error [errors]="passwordForm.errors"></idp-password-pattern-error>
    </tpl-form-field>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="passwordForm.invalid"
        (click)="submit()"
      >
        パスワードを変更する
      </button>
    </div>
  </idp-box>
  <div class="box-action">
    <button
      tpl-button
      class="plain-button size-small"
      buttonType="plain"
      width="auto"
      size="small"
      icon="arrow-left"
      iconPosition="left"
      routerLink="/mypage"
      color="basic"
    >
      {{ 'shared.mypage.title' | locale }}へ戻る
    </button>
  </div>
</idp-page-container>
