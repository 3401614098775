import { Dialog } from '@angular/cdk/dialog';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { WINDOW_REF } from '@triad/shared/providers/window';

import {
  ReCaptchaModalData,
  ReCaptchaModalResult,
  RecaptchaModalComponent,
} from '../components/recaptcha-modal/recaptcha-modal.component';

@Injectable({ providedIn: 'root' })
export class ReCaptchaModalService {
  private readonly dialog = inject(Dialog);
  private readonly windowRef = inject(WINDOW_REF);

  async openReCaptchaModal(data: ReCaptchaModalData) {
    // NOTE: チャレンジモーダルが開いたときに位置がバグるのを回避するため、一旦スクロールをトップに戻す
    this.windowRef.scrollTo(0, 0);
    const dialogRef = this.dialog.open<ReCaptchaModalResult, ReCaptchaModalData, RecaptchaModalComponent>(
      RecaptchaModalComponent,
      {
        maxWidth: '500px',
        width: '95%',
        disableClose: true,
        data,
      },
    );

    const result = await firstValueFrom(dialogRef.closed);
    if (result) {
      return result.token;
    } else {
      return null;
    }
  }
}
