import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, inject } from '@angular/core';
import { IconComponent } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'a[tpl-link]',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnInit {
  @Input()
  color: 'primary' | 'accent' | 'attention' = 'primary';

  @Input()
  icon?: 'external-link';

  @Input()
  decoration: 'underline' | 'none' = 'underline';

  private elementRef = inject<ElementRef<HTMLAnchorElement>>(ElementRef);

  ngOnInit() {
    this.elementRef.nativeElement.classList.add(...this.linkClass);
  }

  private get linkClass() {
    return [`color-${this.color}`];
  }
}
