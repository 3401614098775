import { HttpErrorResponse } from '@angular/common/http';

export class InternalApiHttpError extends Error {
  override readonly name = 'InternalApiHttpError';
  originalErrorResponse: HttpErrorResponse;
  constructor(
    message: string,
    options: {
      cause?: unknown;
      originalErrorResponse: HttpErrorResponse;
    },
  ) {
    super(message);
    this.cause = options.cause;
    this.originalErrorResponse = options.originalErrorResponse;
  }
}
