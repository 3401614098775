import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonComponent, PageTitleComponent } from '@triple/triad';
import { PrefecturePipe, nonNullable } from '@twogate-npm/toolbox-angular';
import { Observable, filter } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { GenderPipe, IdpPageContainerComponent } from '@idp/shared';
import { CountryPipe } from '@idp/shared/pipes/country/country.pipe';
import { PhoneNumberPipe } from '@idp/shared/pipes/phone-number/phone-number.pipe';

import { InputProfile } from '../../models/profile.type';
import { ProfileUsecaseService } from '../../services/profile-usecase.service';
import { selectInputProfile } from '../../store/profile';

@Component({
  selector: 'page-register-profile-confirm',
  standalone: true,
  imports: [
    CommonModule,
    PageTitleComponent,
    IdpPageContainerComponent,
    GenderPipe,
    PrefecturePipe,
    RouterLink,
    ButtonComponent,
    PhoneNumberPipe,
    CountryPipe,
  ],
  templateUrl: './register-profile-confirm.page.html',
  styleUrls: ['./register-profile-confirm.page.scss'],
})
export class RegisterProfileConfirmPage implements OnInit {
  readonly state$: Observable<InputProfile>;

  readonly verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;

  private readonly store = inject(Store);
  private readonly profileUsecase = inject(ProfileUsecaseService);

  constructor() {
    this.state$ = this.store.select(selectInputProfile).pipe(
      filter((profile) => !!profile),
      nonNullable(),
    );
  }

  ngOnInit() {
    this.profileUsecase.fetchProfile();
    window.scrollTo(0, 0);
  }

  registerProfile(inputProfile: InputProfile) {
    return this.profileUsecase.registerProfile(inputProfile);
  }
}
