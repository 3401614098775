import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TriadAssetsToken } from '@triad/features/config';
import { ButtonComponent } from '@triad/shared/components/button';

import { SessionService } from '../../services/session.service';

@Component({
  selector: 'tpl-auth-actions',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './auth-actions.component.html',
  styleUrl: './auth-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthActionsComponent {
  private readonly assets = inject(TriadAssetsToken);
  private readonly sessionService = inject(SessionService);

  get signInButtonColor() {
    return this.assets.authAction?.signInButtonColor ?? 'basic';
  }

  get signUpButtonColor() {
    return this.assets.authAction?.signUpButtonColor ?? 'accent';
  }

  navigateSignIn() {
    this.sessionService.navigateSignIn({});
  }

  navigateSignUp() {
    this.sessionService.navigateSignUp({});
  }
}
