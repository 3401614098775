import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'tpl-recaptcha-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './recaptcha-message.component.html',
  styleUrls: ['./recaptcha-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecaptchaMessageComponent {}
