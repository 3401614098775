import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent, FormComponents, PressEnterDirective } from '@triple/triad';

import { SubHeaderComponent } from '@idp/features/layout/components/sub-header/sub-header.component';
import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { PasswordFormComponent } from '../../components/password-form/password-form.component';
import { PasswordPatternErrorComponent } from '../../components/password-pattern-error/password-pattern-error.component';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthUsecaseService } from '../../services/auth-usecase.service';

@Component({
  selector: 'page-register-password',
  standalone: true,
  templateUrl: './register-password.page.html',
  styleUrls: ['./register-password.page.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    FormComponents,
    SubHeaderComponent,
    PasswordFormComponent,
    PasswordPatternErrorComponent,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
  ],
})
export class RegisterPasswordPage {
  @Input() email?: string;
  @Input() phoneNumber?: string;

  passwordForm;

  private readonly authUsecase = inject(AuthUsecaseService);
  private readonly authFormService = inject(AuthFormService);

  constructor() {
    this.passwordForm = new FormControl('', {
      nonNullable: true,
      validators: [...this.authFormService.passwordValidators()],
    });
  }

  async submit() {
    if (this.passwordForm.invalid) {
      return;
    }

    const status = await this.authUsecase.setPassword(this.passwordForm.value);
    if (status) {
      await this.authUsecase.navigateAfterAuthSuccess();
    }
  }
}
