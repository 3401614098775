<idp-page-container>
  <div class="description">
    <p>必須事項を入力し、[登録内容を確認する]を押してください。</p>
    <p>必ず、サービスの利用を希望されているご本人様の情報を、正確にご登録ください。</p>
    <p class="description-attention">※名・メイ(フリガナ)・生年月日・性別は登録後の変更はできません。</p>
  </div>
  <idp-box [formGroup]="form">
    <span slot="header">個人情報の登録</span>
    <tpl-form-group formGroupName="profile">
      <tpl-form-field>
        <tpl-form-label [required]="true">氏名</tpl-form-label>
        <div class="input-group">
          <input type="text" placeholder="姓" formControlName="lastName" autocomplete="family-name" />
          <input type="text" placeholder="名" formControlName="firstName" autocomplete="given-name" />
        </div>
        <tpl-form-note>邉・邊・髙・﨑などの旧字は、新字（常用漢字）で入力してください。</tpl-form-note>
        <span class="form-error-group" form-errors>
          @if (profileForm.get('lastName')?.dirty || profileForm.get('lastName')?.touched) {
            <tpl-form-error [errors]="profileForm.get('lastName')?.errors ?? null"></tpl-form-error>
          }
          @if (profileForm.get('firstName')?.dirty || profileForm.get('firstName')?.touched) {
            <tpl-form-error [errors]="profileForm.get('firstName')?.errors ?? null"></tpl-form-error>
          }
        </span>
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">フリガナ</tpl-form-label>
        <div class="input-group">
          <input type="text" placeholder="セイ" formControlName="lastNameKana" />
          <input type="text" placeholder="メイ" formControlName="firstNameKana" />
        </div>
        <span class="form-error-group" form-errors>
          @if (profileForm.get('lastNameKana')?.dirty || profileForm.get('lastNameKana')?.touched) {
            <tpl-form-error [errors]="profileForm.get('lastNameKana')?.errors ?? null"></tpl-form-error>
          }
          @if (profileForm.get('firstNameKana')?.dirty || profileForm.get('firstNameKana')?.touched) {
            <tpl-form-error [errors]="profileForm.get('firstNameKana')?.errors ?? null"></tpl-form-error>
          }
        </span>
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">生年月日</tpl-form-label>
        <idp-birth-date-form formControlName="birthDate"></idp-birth-date-form>
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">性別</tpl-form-label>
        <tpl-radio-group formControlName="gender">
          <tpl-simple-radio-button value="female" tabindex="0">女性</tpl-simple-radio-button>
          <tpl-simple-radio-button value="male" tabindex="0">男性</tpl-simple-radio-button>
          <tpl-simple-radio-button value="other" tabindex="0">その他</tpl-simple-radio-button>
        </tpl-radio-group>
        @if (contactAddressForm.get('gender')?.dirty || contactAddressForm.get('gender')?.touched) {
          <tpl-form-error [errors]="contactAddressForm.get('gender')?.errors ?? null"></tpl-form-error>
        }
      </tpl-form-field>
    </tpl-form-group>

    <tpl-form-group formGroupName="contactAddress">
      @if (!verifyPhoneNumber) {
        <tpl-form-field>
          <tpl-form-label [required]="true">電話番号</tpl-form-label>
          <tpl-form-phone-field
            formControlName="phoneNumber"
            [phoneCountryCodes]="phoneCountryWhitelist"
          ></tpl-form-phone-field>
          @if (contactAddressForm.get('phoneNumber')?.dirty || contactAddressForm.get('phoneNumber')?.touched) {
            <tpl-form-error [errors]="contactAddressForm.get('phoneNumber')?.errors ?? null"></tpl-form-error>
          }
        </tpl-form-field>
      }

      <tpl-form-field>
        <tpl-form-label [required]="true">国</tpl-form-label>
        <tpl-form-select>
          <select formControlName="countryCode">
            <option value="">国を選択してください</option>
            <option
              *ngFor="let country of COUNTRY_SELECT_LIST; trackBy: 'countryCode' | trackByKey"
              [ngValue]="country.countryCode"
            >
              {{ country.text }}
            </option>
          </select>
        </tpl-form-select>
        <tpl-form-error
          *ngIf="contactAddressForm.get('countryCode')?.dirty || contactAddressForm.get('countryCode')?.touched"
          [errors]="contactAddressForm.get('countryCode')?.errors ?? null"
        ></tpl-form-error>
      </tpl-form-field>

      @if (countryCodeValue === 'JP') {
        <tpl-form-field>
          <tpl-form-label [required]="true">郵便番号</tpl-form-label>
          <input
            type="text"
            pattern="\d{3}-?\d{4}"
            formControlName="zipCode"
            placeholder="0000000"
            idpConvertZenkakuToHankaku
            idpFormatZipCode
            autocomplete="postal-code"
          />
          @if (contactAddressForm.get('zipCode')?.dirty || contactAddressForm.get('zipCode')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('zipCode')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'zipCode') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">都道府県</tpl-form-label>
          <tpl-form-select>
            <select formControlName="prefectureCode">
              <option value="">選択してください</option>
              @for (prefecture of PREFECTURE_SELECT_LIST; track prefecture.prefectureCode) {
                <option [value]="prefecture.prefectureCode">
                  {{ prefecture.text }}
                </option>
              }
            </select>
          </tpl-form-select>
          @if (contactAddressForm.get('prefectureCode')?.dirty || contactAddressForm.get('prefectureCode')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('prefectureCode')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'prefectureCode') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">市区町村</tpl-form-label>
          <input type="text" formControlName="city" placeholder="〇〇区" autocomplete="address-level2" />
          @if (contactAddressForm.get('city')?.dirty || contactAddressForm.get('city')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('city')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'city') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">住所１ 番地まで</tpl-form-label>
          <input type="text" formControlName="street" placeholder="〇〇1-1-1" autocomplete="address-line1" />
          @if (contactAddressForm.get('street')?.dirty || contactAddressForm.get('street')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('street')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'street') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label>住所２ 建物名・部屋番号等</tpl-form-label>
          <input type="text" formControlName="building" placeholder="〇〇ハイツ 101" autocomplete="address-line2" />
          <tpl-form-note
            >部屋番号まで入力してください。世帯主の方と姓が異なる場合は、○○様方を入力してください。</tpl-form-note
          >
          @if (contactAddressForm.get('building')?.dirty || contactAddressForm.get('building')?.touched) {
            <tpl-form-error [errors]="contactAddressForm.get('building')?.errors ?? null"></tpl-form-error>
          }
        </tpl-form-field>
      }
    </tpl-form-group>
    <div class="box-action">
      <button
        class="button"
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        登録内容を確認する
      </button>
    </div>
  </idp-box>
</idp-page-container>
