import { Provider } from '@angular/core';
import { mergeDeep } from '@twogate-npm/toolbox-utils';

import { IdpLocaleMaster } from './models/locale-master.type';
import { IdpLocale, IdpLocaleToken, selectLocale } from './models/locale.type';

export function provideIdpLocale(locale?: IdpLocale): Provider {
  return [
    {
      provide: IdpLocaleToken,
      useFactory: () => {
        const defaultLocale = selectLocale();
        if (locale) {
          return mergeDeep(defaultLocale, locale) as IdpLocaleMaster;
        } else {
          return defaultLocale;
        }
      },
    },
  ];
}
