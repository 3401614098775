<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>パスワードの再設定</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <idp-box>
    <span slot="header">パスワードの再設定</span>
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <input
        type="email"
        placeholder="user@example.com"
        [formControl]="emailForm"
        tplPressEnter
        (pressEnter)="submit()"
      />
    </tpl-form-field>
    <div class="description">
      ご登録のメールアドレスを入力し、［送信する］を押してください。「パスワード再設定のご案内」メールをお送りいたします。
      メールに記載のURLにアクセスし、1時間以内にパスワードの再設定を行ってください。
    </div>
    <div class="description">
      ※ドメイン（アドレス）指定受信・拒否などを設定されている場合、メールが届きません。<br />
      「{{ authEmailDomain }}」が受信できるように設定の上、お手続きください。
    </div>
    <div class="description">
      ※メールが届かない場合は、入力の誤りがあるか、ご登録されていないメールアドレスの可能性があります。
    </div>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="emailForm.invalid"
        (click)="submit()"
      >
        送信する
      </button>
    </div>
  </idp-box>
</idp-page-container>
