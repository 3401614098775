import { InjectionToken } from '@angular/core';
import { DeepPartialLocale } from '@twogate-npm/toolbox-angular';

import { defaultLocale } from '../assets/ja-JP';

import { TriadLocaleMaster } from './locale-master.type';

export type TriadLocale = DeepPartialLocale<TriadLocaleMaster>;
export const TriadLocaleToken = new InjectionToken<TriadLocaleMaster>('TriadLocaleToken');

export function selectLocale() {
  return defaultLocale;
}
