import { CommonModule } from '@angular/common';
import { Component, DoCheck, EventEmitter, Input, Output, Signal, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthActionsComponent,
  ButtonComponent,
  LocalePipe,
  SessionService,
  SignOutButtonComponent,
  SiteMenuItemComponent,
  SnsLogoListComponent,
  TriadAssetsToken,
} from '@triple/triad';
import { IconComponent, TrackByKeyPipe } from '@twogate-npm/toolbox-angular';
import { Observable } from 'rxjs';

import { User, UserInfoComponent } from '@idp/features/user';
import { selectCurrentUser } from '@idp/features/user/store/user';

@Component({
  selector: 'idp-site-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ButtonComponent,
    IconComponent,
    TrackByKeyPipe,
    LocalePipe,
    SiteMenuItemComponent,
    SnsLogoListComponent,
    UserInfoComponent,
    AuthActionsComponent,
    SignOutButtonComponent,
  ],
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss'],
})
export class SiteMenuComponent implements DoCheck {
  @Input()
  isOpen = false;
  @Input()
  siteType: 'ticket' | 'idp' = 'ticket';
  @Output()
  readonly internalLinkClicked = new EventEmitter<void>();
  readonly isSignedIn$: Observable<boolean>;
  readonly currentUser$: Signal<User | null | undefined>;
  headerOffset = 0;

  private readonly asset = inject(TriadAssetsToken);
  private readonly sessionService = inject(SessionService);
  private readonly store = inject(Store);

  constructor() {
    this.isSignedIn$ = this.sessionService.isSignedIn$;
    this.currentUser$ = this.store.selectSignal(selectCurrentUser);
  }

  ngDoCheck() {
    // user-notice の高さを考慮する
    // もっと良い方法があれば変更する
    const el = document.querySelector<HTMLElement>('tpl-site-menu-button');
    if (el) {
      this.headerOffset = el.getBoundingClientRect().y;
    }
  }

  get logoImage() {
    return this.asset.logoImage;
  }
  get inSiteLinks() {
    return this.asset.inSiteLinks;
  }
  get siteLinks() {
    return this.asset.siteLinks;
  }
  get snsLinks() {
    return this.asset.snsLinks;
  }
  get siteHeader() {
    return this.asset.siteHeader;
  }

  async signOut() {
    const result = await this.sessionService.signOut();
    if (result) {
      this.internalLinkClicked.emit();
    }
  }
}
