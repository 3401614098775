import { Provider } from '@angular/core';
import { provideStore } from '@ngrx/store';

import { metaReducers } from './meta';
import { reducers } from './reducers';

export function provideIdpStore(): Provider {
  return [
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
  ];
}
