declare global {
  interface Window {
    onloadCallback: () => void;
  }
}

function loadScript(siteKey: string, onLoaded: (grecaptcha: ReCaptchaV2.ReCaptcha) => void): void {
  window.onloadCallback = () => {
    onLoaded(grecaptcha.enterprise);
  };
  const script = document.createElement('script');
  script.innerHTML = '';
  const baseUrl = 'https://www.google.com/recaptcha/enterprise.js';

  script.src = `${baseUrl}?render=${siteKey}&onload=onloadCallback`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

export const loader = { loadScript };
