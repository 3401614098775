<footer class="footer" [class.style-bold]="footerStyle === 'bold'">
  <div class="footer-container">
    <div class="links">
      @for (link of footerLinks; track link.label) {
        <tpl-site-footer-item
          [link]="link"
          [footerStyle]="footerStyle"
          [isRoot]="true"
          [showExternalIcon]="showExternalIcon"
        ></tpl-site-footer-item>
      }
    </div>
    @if (showSnsLinks && snsLinks) {
      <tpl-sns-logo-list
        class="sns-links"
        [list]="snsLinks"
        [justifyContent]="footerStyle === 'bold' ? 'start' : 'center'"
      ></tpl-sns-logo-list>
    }
    <div class="copyright" [innerHTML]="'common.copyright' | triadLocale"></div>
  </div>
</footer>
