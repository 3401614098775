import { Provider } from '@angular/core';
import { mergeDeep } from '@twogate-npm/toolbox-utils';

import { TriadLocaleMaster } from './models/locale-master.type';
import { TriadLocale, TriadLocaleToken, selectLocale } from './models/locale.type';

export function provideTriadLocale(locale?: TriadLocale): Provider {
  return [
    {
      provide: TriadLocaleToken,
      useFactory: () => {
        const defaultLocale = selectLocale();
        if (locale) {
          return mergeDeep(defaultLocale, locale) as TriadLocaleMaster;
        } else {
          return defaultLocale;
        }
      },
    },
  ];
}
