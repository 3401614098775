<div class="container">
  <idp-site-header siteType="idp"></idp-site-header>

  <div class="site-body">
    <div class="site-body-inner">
      <router-outlet></router-outlet>
    </div>
  </div>

  <tpl-site-footer></tpl-site-footer>
</div>
