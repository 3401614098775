import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TriadLink } from '@triad/features/config';
import { ButtonComponent } from '@triad/shared/components/button';

@Component({
  selector: 'tpl-site-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent],
  templateUrl: './site-menu-item.component.html',
  styleUrl: './site-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteMenuItemComponent {
  @Input()
  link!: TriadLink;

  @Input()
  size: 'small' | 'regular' = 'regular';

  @Input()
  color?: 'primary' | 'accent' | 'store';

  get colorClass() {
    return this.color ? `color-${this.color}` : '';
  }
}
