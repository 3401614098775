import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { importProvidersFrom } from '@angular/core';
import { provideDefaultSuspenseFallbackComponent, provideTbxIcon } from '@twogate-npm/toolbox-angular';

import { TriadAssets, TriadConfig, provideTriadConfig } from './features/config';
import { errorHandlerProvider } from './features/error';
import { TriadLocale } from './features/locale/models/locale.type';
import { provideTriadLocale } from './features/locale/providers';
import { provideTriadStorage } from './features/storage/providers';
import { LoaderBlockComponent } from './shared/components/loader';

export function provideTriad(config: TriadConfig, assets: TriadAssets, locale?: TriadLocale) {
  return [
    importProvidersFrom(DialogModule, OverlayModule),
    provideTbxIcon({ basePath: 'assets/icons' }),
    provideTriadConfig(config, assets),
    provideTriadLocale(locale),
    provideTriadStorage(),
    errorHandlerProvider,
    provideDefaultSuspenseFallbackComponent(LoaderBlockComponent, { color: 'basic-light' }),
  ];
}
