<tpl-modal>
  <tpl-modal-header [hideCloseButton]="true">
    <div class="title">{{ data.header }}</div>
  </tpl-modal-header>

  <div class="content" [innerHTML]="message"></div>

  <tpl-modal-footer>
    <ng-container slot="end">
      @for (button of data.buttons; track $index) {
        <button
          tpl-button
          [buttonType]="button.type ?? 'plain'"
          [color]="button.color ?? 'basic'"
          size="small"
          width="auto"
          (click)="buttonHandler($index)"
        >
          {{ button.text }}
        </button>
      }
    </ng-container>
  </tpl-modal-footer>
</tpl-modal>
