<header class="header">
  <div class="header-start">
    <ng-content select="[slot=start]"></ng-content>
  </div>

  <div class="header-title">
    <ng-content></ng-content>
  </div>

  <div class="header-end" #endRef>
    <ng-content #end select="[slot=end]"></ng-content>
  </div>

  @if (endRef.childNodes.length === 0 && !hideCloseButton) {
    <div class="header-end">
      <button tbxTouchable (click)="close()" class="close-button">
        <tbx-icon class="close-button-icon" name="close"></tbx-icon>
      </button>
    </div>
  }
</header>
