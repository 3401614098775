export class KenAllAddressNotFoundError extends Error {
  override readonly name = 'KenAllAddressNotFoundError';
  constructor(message: string, options?: { cause?: unknown }) {
    super(message);
    this.cause = options?.cause;
  }
}
export class KenAllError extends Error {
  override readonly name = 'KenAllError';
  constructor(message: string, options?: { cause?: unknown }) {
    super(message);
    this.cause = options?.cause;
  }
}

export class KenAllAPIKeyNotSetError extends Error {
  override readonly name = 'KenAllAPIKeyNotSetError';
  constructor(message: string, options?: { cause?: unknown }) {
    super(message);
    this.cause = options?.cause;
  }
}
