import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemComponent, ButtonComponent, PageTitleComponent } from '@triple/triad';

import { IdpLocalePipe } from '@idp/features/locale';
import { CheckboxDisplayComponent } from '@idp/shared/components/checkbox-display/checkbox-display.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

@Component({
  selector: 'page-notification-setting',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    PageTitleComponent,
    RouterLink,
    ButtonComponent,
    CheckboxDisplayComponent,
    FormsModule,
    IdpLocalePipe,
  ],
  templateUrl: './notification-setting.page.html',
  styleUrls: ['./notification-setting.page.scss'],
})
export class NotificationSettingPage {
  isTouched = false;
  shouldNotify = false;
}
