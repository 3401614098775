<svg
  class="svg"
  [class.checked]="checked"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14 0.5H4C2.067 0.5 0.5 2.067 0.5 4V14C0.5 15.933 2.067 17.5 4 17.5H14C15.933 17.5 17.5 15.933 17.5 14V4C17.5 2.067 15.933 0.5 14 0.5Z"
    fill="#EEEEEE"
    stroke="currentColor"
  />
  <path
    d="M15.2138 4.00033C15.0154 4.00628 14.8271 4.08974 14.6888 4.23303L6.82908 12.1446L4.31456 9.6135C4.2442 9.53973 4.15992 9.48084 4.06666 9.44026C3.9734 9.39969 3.87304 9.37826 3.77145 9.37722C3.66986 9.37618 3.56908 9.39555 3.47503 9.43421C3.38097 9.47286 3.29552 9.53002 3.22368 9.60233C3.15184 9.67464 3.09506 9.76066 3.05665 9.85533C3.01825 9.95001 2.99901 10.0515 3.00004 10.1537C3.00107 10.256 3.02237 10.357 3.06267 10.4509C3.10298 10.5447 3.16149 10.6296 3.23478 10.7004L6.28919 13.775C6.4324 13.9191 6.6266 14 6.82908 14C7.03157 14 7.22577 13.9191 7.36897 13.775L15.7686 5.31993C15.879 5.21197 15.9543 5.07296 15.9848 4.92114C16.0153 4.76932 15.9996 4.61176 15.9396 4.46912C15.8796 4.32647 15.7782 4.20538 15.6487 4.12173C15.5192 4.03808 15.3676 3.99577 15.2138 4.00033Z"
    fill="currentColor"
  />
</svg>
