import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { IconComponent, TbxCommonComponents } from '@twogate-npm/toolbox-angular';
import { timer } from 'rxjs';

import { ToastService } from '../toast.service';
import { Toast } from '../toast.type';

import { toastAnimations } from './toast.animation';

@Component({
  selector: 'tpl-toast',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, IconComponent],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toastAnimations.toastState],
})
export class ToastComponent implements OnInit {
  @Input()
  toast!: Toast;

  @HostBinding('@state')
  animationState: 'void' | 'visible' | 'hidden' = 'void';

  get config() {
    return this.toast.config;
  }

  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly toastService = inject(ToastService);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  ngOnInit() {
    if (this.config.duration) {
      timer(this.config.duration).subscribe(() => this.close());
    }

    this.animationState = 'visible';
    this.elementRef.nativeElement.classList.add(...this.buttonClass);
  }

  close() {
    this.animationState = 'void';
    this.cdRef.markForCheck();
  }

  @HostListener('@state.done')
  onAnimationDone() {
    if (this.animationState === 'void') {
      this.toastService.close(this.toast);
      this.animationState = 'void';
    }
  }

  private get buttonClass() {
    return [`color-${this.config.color}`];
  }
}
