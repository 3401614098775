import { Injectable, inject } from '@angular/core';

import { IdpConfig, IdpConfigToken } from '../models/idp-config.type';

@Injectable({ providedIn: 'root' })
export class IdpConfigService {
  private readonly idpConfig: IdpConfig = inject(IdpConfigToken);

  get apiEndpoint() {
    return `${this.idpConfig.apiOrigin}/api`;
  }
}
