<div class="account">
  <tbx-icon name="account-circle" class="account-icon"></tbx-icon>
  <div class="account-info">
    <div class="account-info-name">
      <div class="account-info-name-content">{{ name }}</div>
      <div class="account-info-name-title">さま</div>
    </div>
    @if (email) {
      <div class="account-info-email">{{ email }}</div>
    }
  </div>
</div>
