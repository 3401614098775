import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LoaderSpinnerComponent } from '../loader-spinner/loader-spinner.component';
import { LoaderSpinnerColor } from '../loader.type';

@Component({
  selector: 'tpl-loader-block',
  standalone: true,
  imports: [CommonModule, LoaderSpinnerComponent],
  templateUrl: './loader-block.component.html',
  styleUrls: ['./loader-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderBlockComponent {
  @Input()
  color: LoaderSpinnerColor = 'basic-light';
}
