import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemComponent, MenuButtonComponent, TriadLink } from '@triple/triad';
import { TbxCommonModule } from '@twogate-npm/toolbox-angular';

import { IdpLocalePipe } from '@idp/features/locale';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { IdpAssetsToken } from '../../../config';

@Component({
  selector: 'page-mypage',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    MenuButtonComponent,
    TbxCommonModule,
    IdpLocalePipe,
  ],
  templateUrl: './mypage.page.html',
  styleUrls: ['./mypage.page.scss'],
})
export class MypagePage {
  readonly accountItems: TriadLink[] = [
    {
      type: 'internal',
      href: 'profile',
      label: '個人情報確認・変更',
    },
    {
      type: 'internal',
      href: 'update_email',
      label: 'メールアドレス変更',
    },
    {
      type: 'internal',
      href: 'update_password',
      label: 'パスワード変更',
    },
    // {
    //   type: 'internal',
    //   href: 'notification_setting',
    //   label: 'お知らせメールの設定',
    // },
    {
      type: 'internal',
      href: 'unregister',
      label: 'アカウント削除',
    },
  ];

  private readonly idpAssets = inject(IdpAssetsToken);
  get orderHistoryItems() {
    if (!this.idpAssets.relatedSiteLinks) {
      return [];
    }

    return Object.values(this.idpAssets.relatedSiteLinks).filter(Boolean);
  }
}
