import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { LoaderSpinnerComponent } from '../loader-spinner/loader-spinner.component';
import { LoaderConfig } from '../loader.type';

@Component({
  selector: 'tpl-loader',
  standalone: true,
  imports: [CommonModule, LoaderSpinnerComponent],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  dialogData = inject<LoaderConfig>(DIALOG_DATA);
}
