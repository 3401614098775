<div class="form-field-container">
  <div class="form-label">
    <ng-content select="tpl-form-label"></ng-content>
  </div>

  <div class="form-input">
    <ng-content></ng-content>
  </div>

  <div class="form-note"><ng-content select="tpl-form-note"></ng-content></div>

  <div class="form-errors">
    <ng-content select="tpl-form-error,[form-errors]"></ng-content>
  </div>
</div>
