import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent, TbxCommonComponents } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'tpl-user-info-overview',
  standalone: true,
  imports: [CommonModule, IconComponent, TbxCommonComponents],
  templateUrl: './user-info-overview.component.html',
  styleUrl: './user-info-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoOverviewComponent {
  @Input()
  name!: string;

  @Input()
  email?: string;
}
