import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent, FormComponents, PressEnterDirective } from '@triple/triad';

import { SubHeaderComponent } from '@idp/features/layout/components/sub-header/sub-header.component';
import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { PasswordFormComponent } from '../../components/password-form/password-form.component';
import { PasswordPatternErrorComponent } from '../../components/password-pattern-error/password-pattern-error.component';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthUsecaseService } from '../../services/auth-usecase.service';

@Component({
  selector: 'page-reset-password-code-handler',
  standalone: true,
  templateUrl: './reset-password-code-handler.page.html',
  styleUrls: ['./reset-password-code-handler.page.scss'],
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    FormComponents,
    SubHeaderComponent,
    PasswordFormComponent,
    PasswordPatternErrorComponent,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
  ],
})
export class ResetPasswordCodeHandlerPage implements OnInit {
  @Input({
    alias: 'password_reset_code',
  })
  passwordResetCode?: string;

  passwordForm;

  private readonly router = inject(Router);
  private readonly authUsecase = inject(AuthUsecaseService);
  private readonly authFormService = inject(AuthFormService);

  constructor() {
    this.passwordForm = new FormControl('', {
      nonNullable: true,
      validators: [...this.authFormService.passwordValidators()],
    });
  }

  ngOnInit() {
    if (!this.passwordResetCode) {
      // TODO:
    }
  }

  async submit() {
    if (this.passwordForm.invalid) {
      return;
    }

    if (!this.passwordResetCode) {
      return;
    }

    const result = await this.authUsecase.resetPasswordWithCode(this.passwordForm.value, this.passwordResetCode);
    if (result) {
      this.router.navigate(['/']);
    }
  }
}
