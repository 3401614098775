import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { TriadAssetsToken } from '@triad/features/config';
import { LocalePipe } from '@triad/features/locale';
import { SnsLogoListComponent } from '@triad/shared/components/sns-logo';

import { SiteFooterItemComponent } from '../site-footer-item/site-footer-item.component';

export type SiteFooterStyle = 'default' | 'bold';
export type SiteFooterShowExternalIcon = 'all' | 'root' | 'none';

@Component({
  selector: 'tpl-site-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, TbxCommonComponents, LocalePipe, SiteFooterItemComponent, SnsLogoListComponent],
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteFooterComponent {
  readonly footerStyle: SiteFooterStyle = inject(TriadAssetsToken).siteFooter?.style ?? 'default';
  readonly showExternalIcon: SiteFooterShowExternalIcon =
    inject(TriadAssetsToken).siteFooter?.showExternalIcon ?? 'none';
  readonly showSnsLinks = inject(TriadAssetsToken).siteFooter?.showSnsLinks !== false;
  private readonly triadAssets = inject(TriadAssetsToken);

  get footerLinks() {
    return this.triadAssets.footerLinks;
  }
  get snsLinks() {
    return this.triadAssets.snsLinks;
  }
}
