import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideTriad } from '@triple/triad';
import { provideIdp } from '@triple/triad-idp';

import { routes } from './app.routes';
import { idpAssets, idpConfig } from './idp.config';
import { triadLocale } from './triad-locale.config';
import { triadAssets, triadConfig } from './triad.config';

registerLocaleData(localeJa);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideTriad(triadConfig, triadAssets, triadLocale),
    provideIdp(idpConfig, idpAssets),
    provideStoreDevtools({
      maxAge: 50,
      connectInZone: true,
    }),
  ],
};
