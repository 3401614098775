import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ButtonComponent,
  FormComponents,
  BreadcrumbComponent,
  BreadcrumbItemComponent,
  PressEnterDirective,
  ReCaptchaV3Service,
  TriadConfigToken,
  RecaptchaMessageComponent,
  CheckboxComponent,
  LinkComponent,
} from '@triple/triad';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';
import { firstValueFrom } from 'rxjs';

import { IdpAssetsToken, IdpConfigToken } from '@idp/features/config';
import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { AuthUsecaseService } from '../../services/auth-usecase.service';
import { AuthActions } from '../../store/auth';

@Component({
  selector: 'page-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    TbxCommonComponents,
    ButtonComponent,
    FormComponents,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
    RecaptchaMessageComponent,
    CheckboxComponent,
    LinkComponent,
  ],
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  @Input('in_oauth_flow')
  isOAuthFlow = false;
  @Input('require_sms_mfa')
  requireSmsMfa: boolean = false;

  emailForm = new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] });
  agreementForm = new FormControl(false, { nonNullable: true, validators: [Validators.requiredTrue] });

  private readonly store = inject(Store);
  private readonly config = inject(TriadConfigToken);
  private readonly idpConfig = inject(IdpConfigToken);
  private readonly assets = inject(IdpAssetsToken);
  private readonly authUsecase = inject(AuthUsecaseService);
  private readonly recaptchaService = inject(ReCaptchaV3Service);

  get siteKey() {
    return this.config.recaptchaSiteKey;
  }

  get authEmailDomain() {
    return `@${this.idpConfig.authEmailDomain}`;
  }

  get termsLink() {
    return this.assets.termsLink;
  }

  get privacyPolicyLink() {
    return this.assets.privacyPolicyLink;
  }

  get links() {
    return [
      {
        href: this.termsLink,
        text: '利用規約',
      },
      {
        href: this.privacyPolicyLink,
        text: 'プライバシーポリシー',
      },
    ].filter((link) => !!link.href);
  }

  ngOnInit() {
    if (this.isOAuthFlow) {
      this.store.dispatch(AuthActions.setIsOAuthFlow({ isOAuthFlow: true }));
    }
    if (this.requireSmsMfa) {
      this.store.dispatch(AuthActions.setRequireSmsMfa({ requireSmsMfa: true }));
    }

    if (this.siteKey) {
      this.recaptchaService.init(this.siteKey);
    }
  }

  async submit() {
    if (this.emailForm.invalid || this.agreementForm.invalid) {
      return;
    }

    let captchaToken = '';
    if (this.siteKey) {
      captchaToken = await firstValueFrom(this.recaptchaService.execute('signup'));
    }
    if (captchaToken == null) {
      return;
    }

    await this.authUsecase.sendVerificationEmail(this.emailForm.value, {
      captchaToken,
      captchaType: 'score_based',
    });
  }
}
