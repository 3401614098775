import { Injectable, inject } from '@angular/core';
import { BaseLocaleService } from '@twogate-npm/toolbox-angular';

import { TriadLocaleMaster } from '../models/locale-master.type';
import { TriadLocaleToken } from '../models/locale.type';

@Injectable({ providedIn: 'root' })
export class LocaleService extends BaseLocaleService<TriadLocaleMaster> {
  locale = inject(TriadLocaleToken);
}
