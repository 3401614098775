import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { IconComponent, TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'tpl-modal-header',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, IconComponent],
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input()
  hideCloseButton = false;

  private readonly modalRef = inject(ModalComponent, {
    optional: true,
  });

  close() {
    this.modalRef?.close.emit();
  }
}
