import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { loader } from '../utils/load';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaLoaderService {
  public ready?: Observable<ReCaptchaV2.ReCaptcha | null>;
  private static ready?: BehaviorSubject<ReCaptchaV2.ReCaptcha | null>;
  private readonly platformId = inject(PLATFORM_ID);

  init(siteKey: string) {
    if (RecaptchaLoaderService.ready) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      const subject = new BehaviorSubject<ReCaptchaV2.ReCaptcha | null>(null);
      RecaptchaLoaderService.ready = subject;

      loader.loadScript(siteKey, (grecaptcha) => subject.next(grecaptcha));
    }

    this.ready = isPlatformBrowser(this.platformId) ? RecaptchaLoaderService.ready?.asObservable() : of(null);
  }
}
