import { InjectionToken } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';

export const IdpConfigToken = new InjectionToken<IdpConfig>('IDP_CONFIG');
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IdpConfig {
  apiOrigin: string;
  authEmailDomain: string;
  verifyPhoneNumber?: boolean;
  phoneCountryWhitelist?: CountryCode[];
}
