import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TbxCommonComponents, nonNullable } from '@twogate-npm/toolbox-angular';
import { takeUntil } from 'rxjs';

import { ModalComponents } from '@triad/shared/components/modal';

import { RecaptchaComponent } from '../recaptcha/recaptcha.component';

export interface ReCaptchaModalData {
  siteKey: string;
  action: string;
  message: string;
}

export interface ReCaptchaModalResult {
  token: string;
}

@Component({
  selector: 'tpl-recaptcha-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TbxCommonComponents, ModalComponents, RecaptchaComponent],
  templateUrl: './recaptcha-modal.component.html',
  styleUrls: ['./recaptcha-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecaptchaModalComponent implements OnInit, OnDestroy {
  form = new FormControl<string | null>(null);

  private readonly data = inject<ReCaptchaModalData>(DIALOG_DATA);
  private readonly dialogRef = inject<DialogRef<ReCaptchaModalResult, RecaptchaModalComponent>>(DialogRef);
  private readonly onDestroy$ = new EventEmitter();

  ngOnInit() {
    this.form.valueChanges.pipe(nonNullable(), takeUntil(this.onDestroy$)).subscribe((token) => {
      this.dialogRef.close({ token });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit();
  }

  get siteKey() {
    return this.data.siteKey;
  }

  get action() {
    return this.data.action;
  }

  get message() {
    return this.data.message;
  }

  dismiss() {
    this.dialogRef.close();
  }
}
