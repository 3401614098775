<div [class.is-opened]="isOpen" class="side-nav-wrapper">
  <a class="side-nav-background" (click)="internalLinkClicked.emit()"></a>
  <div class="side-nav">
    <div class="offset" [style.paddingTop]="headerOffset + 'px'"></div>
    <div class="side-nav-header">
      <div class="header-image">
        @if (logoImage) {
          <img
            [src]="logoImage.src"
            class="header-image-logo"
            alt="Logo"
            [width]="logoImage.originalWidth"
            [height]="logoImage.originalHeight"
            [style.height.px]="logoImage.displayHeight"
          />
        }
        @if (siteHeader?.showSiteType !== false) {
          <div class="header-site-type" [class.is-idp]="siteType === 'idp'">
            {{ siteType === 'ticket' ? 'LIVE TICKET' : 'HOME' }}
          </div>
        }
      </div>
    </div>
    <div class="side-nav-inner">
      <div class="side-nav-body">
        @if (currentUser$(); as user) {
          <idp-user-info [user]="user"></idp-user-info>
        }

        <div class="local-menu">
          @for (link of inSiteLinks; track link.label) {
            @if (!link.authRequired || (isSignedIn$ | async) === true) {
              <tpl-site-menu-item [link]="link" (click)="internalLinkClicked.emit()"></tpl-site-menu-item>
            }
          }
        </div>
        @if (siteLinks?.length) {
          <div class="global-menu">
            @for (link of siteLinks; track link.label) {
              <tpl-site-menu-item
                [link]="link"
                size="small"
                [color]="link.theme"
                (click)="internalLinkClicked.emit()"
              ></tpl-site-menu-item>
            }
          </div>
        }
        @if ((isSignedIn$ | async) === true) {
          <tpl-sign-out-button (click)="signOut()"></tpl-sign-out-button>
        } @else if ((isSignedIn$ | async) === false) {
          <tpl-auth-actions></tpl-auth-actions>
        }
      </div>
      <div class="side-nav-footer">
        <div class="sns">
          @if (snsLinks) {
            <tpl-sns-logo-list [list]="snsLinks"></tpl-sns-logo-list>
          }
        </div>

        <div class="copyright" [innerHTML]="'common.copyright' | triadLocale"></div>
      </div>
    </div>
  </div>
</div>
