import { InjectionToken } from '@angular/core';
import { DeepPartialLocale } from '@twogate-npm/toolbox-angular';

import { defaultLocale } from '../assets/ja-JP';

import { IdpLocaleMaster } from './locale-master.type';

export type IdpLocale = DeepPartialLocale<IdpLocaleMaster>;
export const IdpLocaleToken = new InjectionToken<IdpLocaleMaster>('IdpLocaleToken');

export function selectLocale() {
  return defaultLocale;
}
