import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { InternalApiHttpError, ReCaptchaType } from '@triple/triad';
import { Result, createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import { catchError, firstValueFrom, map, of, tap } from 'rxjs';

import { IdpConfig, IdpConfigToken } from '@idp/features/config/models/idp-config.type';
import { User } from '@idp/features/user/models/user.type';
import { UserActions } from '@idp/features/user/store/user';

import { AuthApi } from '../apis/auth.api';
import { AuthActions } from '../store/auth';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly store = inject(Store);
  private readonly authApi = inject(AuthApi);
  private readonly idpConfig: IdpConfig = inject(IdpConfigToken);

  sendVerificationEmail(
    email: string,
    {
      captchaToken,
      captchaType,
    }: {
      captchaToken: string;
      captchaType: ReCaptchaType;
    },
  ): Promise<Result<{ userId: string }, InternalApiHttpError>> {
    const source$ = this.authApi.sendVerificationEmail(email, { captchaToken, captchaType }).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('確認メールの送信に失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  verifyEmail(userId: string, code: string): Promise<Result<User & { registered: boolean }, InternalApiHttpError>> {
    const source$ = this.authApi.verifyEmail(userId, code).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('確認コードの検証に失敗しました', { originalErrorResponse: err }))),
      ),
    );

    return firstValueFrom(source$);
  }

  signIn(email: string, password: string) {
    const source$ = this.authApi.signIn(email, password).pipe(
      tap((res) => this.store.dispatch(UserActions.setCurrentUser({ user: res }))),
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('ログインに失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  setPassword(password: string) {
    const source$ = this.authApi.setPassword(password).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('パスワードの設定に失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  updatePassword(password: string) {
    const source$ = this.authApi.updatePassword(password).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('パスワードの更新に失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  resetPassword(email: string) {
    const source$ = this.authApi.resetPassword(email).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(
          createFailure(new InternalApiHttpError('リセットメールの送信に失敗しました', { originalErrorResponse: err })),
        ),
      ),
    );
    return firstValueFrom(source$);
  }

  resetPasswordWithCode(password: string, code: string) {
    const source$ = this.authApi.resetPasswordWithCode(password, code).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(
          createFailure(new InternalApiHttpError('パスワードのリセットに失敗しました', { originalErrorResponse: err })),
        ),
      ),
    );
    return firstValueFrom(source$);
  }

  signOut() {
    const source$ = this.authApi.signOut().pipe(
      tap(() => {
        this.store.dispatch(AuthActions.signOut());
      }),
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('ログアウトに失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  relaunch() {
    window.location.href = `${this.idpConfig.apiOrigin}/authorizations/relaunch`;
  }
}
