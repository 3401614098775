<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <a routerLink="/mypage">{{ 'shared.mypage.title' | locale }}</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>お知らせメールの設定</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <tpl-page-title>お知らせメールの設定</tpl-page-title>
  <div class="page-content">
    <div class="description">
      <p>お知らせメールの配信を希望される場合は、チェックを入れ、[設定を保存する]を押してください。</p>
    </div>
    <label class="checkbox" (click)="isTouched = true">
      <input [(ngModel)]="shouldNotify" type="checkbox" />
      <idp-checkbox-display [checked]="shouldNotify"></idp-checkbox-display>
      <div class="checkbox-text">お知らせメールの配信</div>
    </label>
    <div class="note">※お申込みや公演に関わるメールは配信停止の対象外となります。</div>
    <div class="action">
      <a
        class="button"
        tpl-button
        color="accent"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="!isTouched"
        >設定を保存する</a
      >
      <a
        tpl-button
        class="plain-button size-small"
        buttonType="plain"
        width="auto"
        size="small"
        icon="arrow-left"
        iconPosition="left"
        routerLink="/mypage"
        color="basic"
      >
        {{ 'shared.mypage.title' | locale }}へ戻る
      </a>
    </div>
  </div>
</idp-page-container>
