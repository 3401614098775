import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { ToastComponent } from '../toast/toast.component';
import { ToastService } from '../toast.service';

@Component({
  selector: 'tpl-toast-layout',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, ToastComponent],
  templateUrl: './toast-layout.component.html',
  styleUrls: ['./toast-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastLayoutComponent {
  readonly toastService = inject(ToastService);
}
