import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemComponent, ButtonComponent, PageTitleComponent } from '@triple/triad';

import { IdpLocalePipe } from '@idp/features/locale';
import { CheckboxDisplayComponent } from '@idp/shared/components/checkbox-display/checkbox-display.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { AuthUsecaseService } from '../../services/auth-usecase.service';

@Component({
  selector: 'page-unregister',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    PageTitleComponent,
    RouterLink,
    ButtonComponent,
    CheckboxDisplayComponent,
    FormsModule,
    IdpLocalePipe,
  ],
  templateUrl: './unregister.page.html',
  styleUrls: ['./unregister.page.scss'],
})
export class UnregisterPage {
  hasAgreement = {
    ticket: false,
    entry: false,
    product: false,
    caution: false,
  };

  private readonly authUsecase = inject(AuthUsecaseService);

  unregister() {
    this.authUsecase.unregister();
  }
}
