import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@sentry/browser';
import { InternalApiHttpError } from '@triple/triad';
import { Result, createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import { catchError, firstValueFrom, map, of, tap } from 'rxjs';

import { setCurrentUser } from '@idp/features/user/store/user';

import { EmailChangeApi } from '../apis/email-change.api';

@Injectable({ providedIn: 'root' })
export class EmailChangeService {
  private readonly emailChangeApi = inject(EmailChangeApi);
  private readonly store = inject(Store);

  sendVerificationEmail(email: string): Promise<Result<void, InternalApiHttpError>> {
    const source$ = this.emailChangeApi.sendVerificationEmail(email).pipe(
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('確認メールの送信に失敗しました', { originalErrorResponse: err }))),
      ),
    );
    return firstValueFrom(source$);
  }

  verifyEmail(code: string): Promise<Result<User, InternalApiHttpError>> {
    const source$ = this.emailChangeApi.verifyEmail(code).pipe(
      tap((user) => this.store.dispatch(setCurrentUser({ user }))),
      map((res) => createSuccess(res)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('確認コードの検証に失敗しました', { originalErrorResponse: err }))),
      ),
    );

    return firstValueFrom(source$);
  }
}
