import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { UserService } from '@idp/features/user/service/user.service';

import { IdpSessionService } from '../services/session.service';

export const userStateGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const userApi = inject(UserService);
  const verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;
  return userApi.fetchCurrentUser().pipe(
    map((user) => {
      if (!user) {
        return true;
      }

      if (!['/auth', '/registration'].some((prefix) => state.url.startsWith(prefix))) {
        if (verifyPhoneNumber && !user.smsVerified) {
          return router.createUrlTree(['/auth/verify_phone/register'], {
            queryParams: next.queryParams,
            queryParamsHandling: 'merge',
          });
        } else if (!user.enabled) {
          return router.createUrlTree(['/registration/profile'], {
            queryParams: next.queryParams,
            queryParamsHandling: 'merge',
          });
        }
      }
      return true;
    }),
  );
};

export const authGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const sessionService = inject(IdpSessionService);

  const isSignedIn = await firstValueFrom(sessionService.isSignedIn$);
  if (isSignedIn) {
    return true;
  } else {
    return router.parseUrl('/auth/sign_in');
  }
};

export const authenticatedGuard: CanActivateFn = async (next, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const sessionService = inject(IdpSessionService);
  const userService = inject(UserService);
  const verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;

  const isSignedIn = await firstValueFrom(sessionService.isSignedIn$);
  const currentUser = await firstValueFrom(userService.currentUser$);
  const isEnabled = !!currentUser?.enabled;
  const isSmsVerified = await firstValueFrom(userService.isSmsVerified$);
  const requireSmsMfa = next.queryParamMap.get('require_sms_mfa');

  if (isSignedIn && isEnabled && (!verifyPhoneNumber || isSmsVerified) && !requireSmsMfa) {
    return router.parseUrl('/');
  }

  if (isSignedIn) {
    if (verifyPhoneNumber && !isSmsVerified) {
      if (state.url.startsWith('/auth/verify_phone')) {
        return true;
      } else {
        return router.createUrlTree(['/auth/verify_phone/register'], {
          queryParams: next.queryParams,
          queryParamsHandling: 'merge',
        });
      }
    }
    if (!isEnabled) {
      return router.createUrlTree(['/registration/profile'], {
        queryParams: next.queryParams,
        queryParamsHandling: 'merge',
      });
    }
    if (requireSmsMfa && !currentUser.suppressSmsVerification) {
      if (state.url.startsWith('/auth/verify_phone')) {
        return true;
      }
      return router.createUrlTree(['/auth/verify_phone/mfa-send'], {
        queryParams: next.queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  return true;
};
