/* eslint-disable @typescript-eslint/no-unsafe-assignment */
function mergeDeep(target, source) {
  const isObject = obj => obj && typeof obj === 'object';
  if (!isObject(target) || !isObject(source)) {
    return source;
  }
  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];
    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = Object.assign(targetValue, sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });
  return target;
}
function groupBy(data, getter) {
  return Array.from(data.reduce((chunk, curr) => {
    const key = typeof getter === 'function' ? getter(curr) : curr[getter];
    const arr = chunk.get(key);
    arr ? arr.push(curr) : chunk.set(key, [curr]);
    return chunk;
  }, new Map()));
}
class PaginatedList {
  constructor(pageSize) {
    this.page = 0;
    this.pageSize = pageSize;
  }
  get isComplete() {
    return this.last ? this.last.length % this.pageSize !== 0 || this.last.length === 0 : false;
  }
  setPage(items) {
    this.last = items;
  }
}
class Success {
  constructor(value) {
    this.value = value;
    this.type = 'success';
  }
  isSuccess() {
    return true;
  }
  isFailure() {
    return false;
  }
}
class Failure {
  constructor(error) {
    this.error = error;
    this.type = 'failure';
  }
  isSuccess() {
    return false;
  }
  isFailure() {
    return true;
  }
}
function createSuccess(val) {
  return new Success(val);
}
function createFailure(err) {
  return new Failure(err);
}
function uniqueArray(items) {
  return [...new Set(items)];
}
function uniqueObjectArray(array, getter) {
  const set = new Set();
  return array.filter(item => {
    const key = typeof getter === 'function' ? getter(item) : item[getter];
    if (set.has(key)) {
      return false;
    } else {
      set.add(key);
      return true;
    }
  });
}

/*
 * Public API Surface of utils
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Failure, PaginatedList, Success, createFailure, createSuccess, groupBy, mergeDeep, uniqueArray, uniqueObjectArray };
