import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Address, AddressResolverResponse } from '@ken-all/kenall';
import { Result, createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import {
  BehaviorSubject,
  Observable,
  UnaryFunction,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  pipe,
  switchMap,
  timeout,
} from 'rxjs';

import { TriadConfigToken } from '@triad/features/config';

import { KenAllAPIKeyNotSetError, KenAllAddressNotFoundError, KenAllError } from '../models/ken-all-error.type';
import { Holiday, HolidayResponse } from '../models/ken-all.type';

@Injectable({ providedIn: 'root' })
export class KenAllService {
  ready$ = new BehaviorSubject<boolean>(false);
  token$ = new BehaviorSubject<string>('');

  private readonly apiEndpoint = 'https://api.kenall.jp';
  private readonly kenAllApiVersion = '2022-11-01';
  private readonly http = inject(HttpClient);
  private readonly config = inject(TriadConfigToken);

  constructor() {
    this.init();
  }

  async init() {
    const token = await firstValueFrom(this.fetchApiKey());
    this.ready$.next(true);
    this.token$.next(token);
    return true;
  }

  async ready(): Promise<boolean> {
    return firstValueFrom(
      this.ready$
        .pipe(
          filter((v) => v),
          timeout(10_000),
        )
        .pipe(catchError(() => of(false))),
    );
  }

  fetchApiKey() {
    return of(this.config.kenallApiPublicKey);
  }

  async fetchAddresses(postalCode: string) {
    const ready = await this.ready();
    if (!ready) {
      return createFailure(new KenAllAPIKeyNotSetError('API Key is not set'));
    }

    const source$ = this.http
      .get<AddressResolverResponse>(`${this.apiEndpoint}/v1/postalcode/${postalCode}`, {
        headers: this.authHeaders,
      })
      .pipe(
        map((res) => createSuccess(res.data)),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of(createFailure(new KenAllAddressNotFoundError('Address not found', {})));
          } else {
            return of(createFailure(new KenAllError('KenAll API error', { cause: err })));
          }
        }),
      );

    return firstValueFrom(source$);
  }

  completeAddress(): UnaryFunction<Observable<string>, Observable<Address[] | null>> {
    return pipe(
      filter((v: string) => !!v),
      debounceTime(300),
      map((code) => code.replace(/-/g, '')),
      filter((code) => code.length === 7),
      distinctUntilChanged(),
      switchMap((code) => this.fetchAddresses(code)),
      map((res) => {
        if (res.isSuccess()) {
          return res.value;
        } else {
          return null;
        }
      }),
    );
  }

  async fetchHolidays(
    options: { year?: string | number; from?: string; to?: string } = {},
  ): Promise<Result<Holiday[], KenAllAPIKeyNotSetError | KenAllError>> {
    const ready = await this.ready();
    if (!ready) {
      return createFailure(new KenAllAPIKeyNotSetError('API Key is not set'));
    }

    let params = new HttpParams();
    if (options.year) {
      params = params.set('year', options.year.toString());
    }
    if (options.from) {
      params = params.set('from', options.from);
    }
    if (options.to) {
      params = params.set('to', options.to);
    }

    const source$ = this.http
      .get<HolidayResponse>(`${this.apiEndpoint}/v1/holidays`, { headers: this.authHeaders, params })
      .pipe(
        map((res) => createSuccess(res.data)),
        catchError((err: HttpErrorResponse) => {
          return of(createFailure(new KenAllError('KenAll API error', { cause: err })));
        }),
      );
    return firstValueFrom(source$);
  }

  private get authHeaders() {
    return new HttpHeaders()
      .set('Authorization', `Token ${this.token$.value}`)
      .set('KenAll-API-Version', this.kenAllApiVersion);
  }
}
