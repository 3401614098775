import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { IconComponent, TbxCommonComponents } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'idp-password-pattern-error',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents, IconComponent],
  templateUrl: './password-pattern-error.component.html',
  styleUrls: ['./password-pattern-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordPatternErrorComponent {
  @Input()
  errors!: ValidationErrors | null;

  hasError(key: string) {
    if (this.errors?.[key] === true) {
      return true;
    } else if (this.errors?.[key] === false) {
      return false;
    } else {
      return true;
    }
  }

  isPassed(key: string) {
    if (this.errors == null) {
      return true;
    }

    if (this.errors['required']) {
      return false;
    }

    return !this.errors[key];
  }
}
