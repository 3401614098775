import { HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { normalizeKeys } from 'object-keys-normalizer';
import { map } from 'rxjs';

import { IdpConfigService } from '../../features/config';

function isInternalApiReq(apiEndpoint: string, url: string): boolean {
  return url.includes(new URL(apiEndpoint).host);
}

export const normalizeResponseInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const configService = inject(IdpConfigService);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return next(req).pipe(
    map((res) => {
      if (res instanceof HttpResponse) {
        if (isInternalApiReq(configService.apiEndpoint, req.url)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
          const body = normalizeKeys(res.body, 'camel');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          return res.clone({ body });
        }
      }

      return res;
    }),
  );
};
