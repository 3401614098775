import { Pipe, PipeTransform, inject } from '@angular/core';
import { BaseLocalePipe } from '@twogate-npm/toolbox-angular';

import { IdpLocaleMaster } from '../models/locale-master.type';
import { IdpLocaleService } from '../services/idp-locale.service';

@Pipe({
  name: 'locale',
  standalone: true,
})
export class IdpLocalePipe extends BaseLocalePipe<IdpLocaleMaster> implements PipeTransform {
  localeService = inject(IdpLocaleService);
}
