<footer class="footer">
  <div class="footer-start">
    <ng-content select="[slot=start]"></ng-content>
  </div>

  <div class="footer-content">
    <ng-content></ng-content>
  </div>

  <div class="footer-end">
    <ng-content select="[slot=end]"></ng-content>
  </div>
</footer>
