@if (link.type === 'internal') {
  <a
    class="menu-item"
    tpl-button
    [routerLink]="link.href"
    color="basic"
    [size]="size"
    width="full"
    buttonType="filled"
    icon="arrow-right"
    [ngClass]="colorClass"
  >
    <span class="menu-item-label">{{ link.label }}</span>
  </a>
} @else {
  <a
    class="menu-item"
    tpl-button
    [href]="link.href"
    color="basic"
    [size]="size"
    width="full"
    buttonType="filled"
    icon="arrow-right"
    [ngClass]="colorClass"
  >
    <span class="menu-item-label">{{ link.label }}</span>
  </a>
}
