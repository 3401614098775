import { InjectionToken } from '@angular/core';

import { SiteFooterShowExternalIcon, SiteFooterStyle, SiteMenuButtonBarType } from '@triad/features/layout';
import { ButtonColor } from '@triad/shared/components/button';
import { SnsList } from '@triad/shared/components/sns-logo';

export type TriadLinkType = 'internal' | 'external';

export interface TriadLink {
  label: string;
  href: string;
  type: TriadLinkType;
}
export interface TriadTag {
  label: string;
  theme: 'primary' | 'accent' | 'store';
}
export type NestedTriadLink = (
  | TriadLink
  | {
      label: string;
      type: 'text';
    }
) & {
  children?: NestedTriadLink[];
};

export interface TriadImageAsset {
  src: string;
  originalWidth: number;
  originalHeight: number;
}

export interface TriadAssets {
  logoImage?: TriadImageAsset & {
    displayHeight?: number;
  };

  inSiteLinks?: (TriadLink & { authRequired: boolean })[];
  siteLinks?: (TriadLink & { theme: 'primary' | 'accent' | 'store' })[];
  snsLinks?: SnsList;
  footerLinks?: NestedTriadLink[];
  siteHeader?: {
    showSiteType?: boolean;
    menuButtonBarType?: SiteMenuButtonBarType;
  };
  siteFooter?: {
    style?: SiteFooterStyle;
    showExternalIcon?: SiteFooterShowExternalIcon;
    showSnsLinks?: boolean;
  };
  authAction?: {
    signInButtonColor?: ButtonColor;
    signUpButtonColor?: ButtonColor;
  };
}
export const TriadAssetsToken = new InjectionToken<TriadAssets>('TRIAD_ASSETS');
