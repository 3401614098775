import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SafeHtmlPipe } from '@twogate-npm/toolbox-angular';
import * as flags from 'country-flag-icons/string/3x2';
import parsePhoneNumberFromString, {
  CountryCallingCode,
  CountryCode,
  PhoneNumber,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { filter, map } from 'rxjs';

import { FormErrorComponent } from '../form-error/form-error.component';

export type PhoneNumberForm = FormGroup<{
  countryCode: FormControl<CountryCallingCode>;
  phoneNumber: FormControl<string | null>;
}>;

@Component({
  selector: 'tpl-form-phone-field',
  standalone: true,
  imports: [CommonModule, MatSelectModule, SafeHtmlPipe, ReactiveFormsModule, FormErrorComponent],
  templateUrl: './form-phone-field.component.html',
  styleUrl: './form-phone-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPhoneFieldComponent implements OnInit, ControlValueAccessor {
  @Input()
  phoneCountryCodes: CountryCode[] = [];
  phoneNumberForm: PhoneNumberForm;
  countriesData: {
    text: string;
    callingCode: CountryCallingCode;
    flagData: string;
  }[] = [];
  readonly errorMessages = {
    requiredCountryCode: '国コードは必須です',
    requiredPhoneNumber: '電話番号は必須です',
  };

  private readonly ngControl = inject(NgControl, { host: true, optional: true });
  private readonly fb = inject(FormBuilder);

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.phoneNumberForm = this.fb.group({
      countryCode: new FormControl<CountryCallingCode>('81' as CountryCallingCode, {
        nonNullable: true,
        validators: Validators.required,
      }),
      phoneNumber: new FormControl<string | null>(null, {
        nonNullable: false,
        validators: Validators.required,
      }),
    });

    // NOTE: FormGroupがエラーになったときに、エラーを表示するために、FormControlにもエラーを付与している
    // もしFormGroupのValidatorが複数になった場合は条件の変更が必要
    this.phoneNumberForm.statusChanges.subscribe(() => {
      if (this.phoneNumberForm.errors?.['invalidPhoneNumber']) {
        this.phoneNumberForm.controls.phoneNumber.setErrors({ invalidPhoneNumber: true }, { emitEvent: false });
      } else {
        this.phoneNumberForm.controls.phoneNumber.setErrors(null, { emitEvent: false });
      }
    });
  }

  get countryCodeValue() {
    return this.phoneNumberForm.controls.countryCode.value as string;
  }
  get controlValue() {
    return this.ngControl?.value as string | null;
  }

  ngOnInit(): void {
    this.phoneNumberForm.setValidators(this.phoneNumberValidator(this.phoneCountryCodes));
    this.countriesData = this.phoneCountryCodes.map((code) => {
      return {
        text: `${code} (+${getCountryCallingCode(code) as string})`,
        flagData: flags[code],
        callingCode: getCountryCallingCode(code),
      };
    });
    if (this.countriesData.length === 1) {
      this.phoneNumberForm.patchValue({ countryCode: this.countriesData[0].callingCode });
    }
  }

  writeValue(phoneNumber: string): void {
    if (!phoneNumber) {
      return;
    }
    if (this.phoneCountryCodes.length === 1) {
      const parsed = parsePhoneNumberFromString(phoneNumber, {
        defaultCountry: this.phoneCountryCodes[0],
      });
      if (parsed) {
        this.phoneNumberForm.patchValue(
          {
            countryCode: parsed.countryCallingCode,
            phoneNumber: parsed.formatNational(),
          },
          { emitEvent: false },
        );
      }
    } else if (this.phoneCountryCodes.length > 1) {
      const parsed = parsePhoneNumberFromString(phoneNumber);
      if (parsed) {
        this.phoneNumberForm.patchValue(
          {
            countryCode: parsed.countryCallingCode,
            phoneNumber: parsed.formatNational(),
          },
          { emitEvent: false },
        );
      }
    }
  }

  registerOnChange(fn: (number: string) => void): void {
    this.phoneNumberForm.valueChanges
      .pipe(
        map((formValue) =>
          this.parsePhoneNumber(formValue.phoneNumber as string, formValue.countryCode as CountryCode),
        ),
        filter((v): v is PhoneNumber => !!v && v.isValid()),
        map((number) => {
          if (this.phoneCountryCodes.length === 1) {
            return number.formatNational();
          } else {
            return number.formatInternational();
          }
        }),
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }
  onBlur() {
    if (this.ngControl?.valid && this.controlValue) {
      const countryCodes = this.phoneCountryCodes;
      const phoneNumberForm = this.phoneNumberForm.get('phoneNumber');

      if (!phoneNumberForm) {
        return;
      }

      // 1国のみの場合は、 現地の番号としてフォーマット
      if (countryCodes.length === 1) {
        const phoneNumber = parsePhoneNumberFromString(this.controlValue, this.phoneCountryCodes[0]);
        const formattedPhoneNumber = phoneNumber?.isValid() && phoneNumber?.formatNational();
        if (formattedPhoneNumber) {
          phoneNumberForm.setValue(formattedPhoneNumber);
        }
        return;
      }

      // 2国以上の場合は、国コードと番号を分離しているため、入力で受け取った国コードでパースして、 現地の番号をフォーマット
      if (this.countryCodeValue) {
        const phoneNumber = parsePhoneNumberFromString(this.controlValue, {
          defaultCallingCode: this.countryCodeValue,
        });
        if (phoneNumber?.isValid()) {
          const formattedPhoneNumber = phoneNumber?.formatNational({ v2: true });
          phoneNumberForm.setValue(formattedPhoneNumber);
          return;
        }
      }

      return;
    }
  }

  onTouchedCallback: () => void = () => {};

  private parsePhoneNumber(text: string, callingCode: string) {
    try {
      return parsePhoneNumberFromString(text, { defaultCallingCode: callingCode });
    } catch {
      return null;
    }
  }

  private phoneNumberValidator(countries: CountryCode[]): ValidatorFn {
    if (countries.length === 1) {
      return (control: AbstractControl<ReturnType<PhoneNumberForm['getRawValue']>>): ValidationErrors | null => {
        if (!control.value.phoneNumber) {
          return { requiredPhoneNumber: true };
        }
        const parsed = parsePhoneNumberFromString(control.value.phoneNumber, {
          defaultCountry: countries[0],
        });
        return parsed?.isValid() ? null : { invalidPhoneNumber: true };
      };
    } else {
      return (control: AbstractControl<ReturnType<PhoneNumberForm['getRawValue']>>): ValidationErrors | null => {
        if (!control.value.countryCode) {
          return { requiredCountryCode: true };
        }
        if (!control.value.phoneNumber) {
          return { requiredPhoneNumber: true };
        }
        const parsed = parsePhoneNumberFromString(control.value.phoneNumber, {
          defaultCallingCode: control.value.countryCode,
        });

        return parsed?.isValid() ? null : { invalidPhoneNumber: true };
      };
    }
  }
}
