<div class="wrapper">
  <div class="text-area">
    <ng-content></ng-content>
  </div>

  @if (icon) {
    <div class="icon-area">
      <tbx-icon [name]="icon" class="icon"></tbx-icon>
    </div>
  }
</div>
