import { DialogModule } from '@angular/cdk/dialog';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { Provider, importProvidersFrom, inject } from '@angular/core';
import { SessionService, withAppVersionInterceptor } from '@triple/triad';
import {
  InternalApiRequestInterceptor,
  RetryInterceptor,
  TbxHttpInterceptorConfig,
  TbxHttpInterceptorConfigToken,
} from '@twogate-npm/toolbox-angular';

import { IdpSessionService, withCredentialInterceptor } from './features/auth';
import { IdpConfigService, IdpAssets, IdpConfig, provideIdpConfig } from './features/config';
import { IdpLocale, provideIdpLocale } from './features/locale';
import { provideProfileState } from './features/profile/store';
import { provideUserState } from './features/user/store';
import { normalizeResponseInterceptor } from './shared/interceptors/normalize-response.interceptor';
import { provideIdpStore } from './store';

export function provideIdp(config: IdpConfig, assets: IdpAssets, locale?: IdpLocale): Provider {
  return [
    importProvidersFrom(DialogModule),
    provideHttpClient(
      withInterceptors([
        InternalApiRequestInterceptor,
        RetryInterceptor,
        withCredentialInterceptor,
        normalizeResponseInterceptor,
        withAppVersionInterceptor(config.apiOrigin),
      ]),
      withInterceptorsFromDi(),
    ),
    provideIdpConfig(config, assets),
    provideIdpLocale(locale),
    provideIdpStore(),
    provideUserState(),
    provideProfileState(),
    {
      provide: TbxHttpInterceptorConfigToken,
      useFactory: (): TbxHttpInterceptorConfig => {
        const configService = inject(IdpConfigService);
        return {
          api: {
            endpoint: configService.apiEndpoint,
          },
        };
      },
      deps: [IdpConfigService],
    },
    {
      provide: SessionService,
      useClass: IdpSessionService,
    },
  ];
}
