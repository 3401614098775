import { Pipe, PipeTransform, inject } from '@angular/core';
import { BaseLocalePipe } from '@twogate-npm/toolbox-angular';

import { TriadLocaleMaster } from '../models/locale-master.type';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'triadLocale',
  standalone: true,
})
export class LocalePipe extends BaseLocalePipe<TriadLocaleMaster> implements PipeTransform {
  localeService = inject(LocaleService);
}
