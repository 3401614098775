import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { ContactAddress, InputProfile, Profile, UserProfile } from '../models/profile.type';

interface ProfileParams {
  user: {
    userProfileAttributes: UserProfile;
    contactAddressAttributes: Omit<ContactAddress, 'prefecture'>;
  };
}

@Injectable({ providedIn: 'root' })
export class ProfileApi {
  baseUrl = 'v1/internal/me/profile';
  private readonly http = inject(HttpClient);

  fetchProfile() {
    return this.http.get<Profile>(`${this.baseUrl}`);
  }

  updateProfile(inputProfile: InputProfile) {
    const profileParams: ProfileParams = {
      user: {
        userProfileAttributes: inputProfile.profile,
        contactAddressAttributes: inputProfile.contactAddress,
      },
    };
    return this.http.put<Profile>(`${this.baseUrl}`, profileParams);
  }
}
