import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent, FormComponents, PressEnterDirective } from '@triple/triad';

import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { EmailChangeUsecaseService } from '../../services/email-change-usecase.service';

@Component({
  selector: 'page-update-email-code-handler',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    ButtonComponent,
    FormComponents,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
  ],
  templateUrl: './update-email-code-handler.page.html',
  styleUrls: ['./update-email-code-handler.page.scss'],
})
export class UpdateEmailCodeHandlerPage {
  @Input() email?: string;

  codeForm = new FormControl<string>('', { nonNullable: true, validators: [Validators.required] });

  private readonly router = inject(Router);
  private readonly emailChangeUseCase = inject(EmailChangeUsecaseService);

  submit() {
    if (this.codeForm.invalid) {
      return;
    }

    if (this.email) {
      this.emailChangeUseCase.verifyEmail(this.codeForm.value, this.email);
    }
  }
}
