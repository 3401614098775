import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'idp-checkbox-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkbox-display.component.html',
  styleUrls: ['./checkbox-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxDisplayComponent {
  @Input()
  checked: boolean = false;
}
