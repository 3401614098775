import { Provider } from '@angular/core';
import { TriadConfig, TriadConfigToken, TriadSentryConfigToken } from '@triple/triad';

import { IdpAssets, IdpAssetsToken } from './models/asset.type';
import { IdpConfig, IdpConfigToken } from './models/idp-config.type';

export function provideIdpConfig(config: IdpConfig, assets: IdpAssets): Provider {
  return [
    {
      provide: IdpConfigToken,
      useValue: config,
    },
    {
      provide: IdpAssetsToken,
      useValue: assets,
    },
    {
      provide: TriadSentryConfigToken,
      useFactory: (triadConfig: TriadConfig) => {
        return {
          dsn: 'https://184fcaa3720e49cda9a63f3d9b55744f@o54978.ingest.sentry.io/4505278453776384',
          release: triadConfig.versions.revision,
        };
      },
      deps: [TriadConfigToken],
    },
  ];
}
