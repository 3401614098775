import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';

import { RadioButtonHorizontalPosition, RadioButtonVerticalPosition } from '../radio-button-position.type';

@Component({
  selector: 'tpl-radio-group',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent<T> implements ControlValueAccessor {
  @Input()
  verticalPosition: RadioButtonVerticalPosition = 'middle';

  @Input()
  horizontalPosition: RadioButtonHorizontalPosition = 'right';

  value?: T;

  private readonly ngControl = inject(NgControl, { self: true });
  private readonly cdRef = inject(ChangeDetectorRef);

  constructor() {
    this.ngControl.valueAccessor = this;
  }

  writeValue(value: T) {
    this.value = value;
    this.onChangeCallback(this.value);
    this.cdRef.markForCheck();
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: unknown) => void = () => {};
}
