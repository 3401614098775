<idp-page-container>
  <idp-box>
    <span slot="header">パスワードの再設定</span>
    <div class="description">新しいパスワードを設定してください</div>
    <tpl-form-field>
      <tpl-form-label>パスワード</tpl-form-label>
      <idp-password-form
        [form]="passwordForm"
        passwordType="new-password"
        tplPressEnter
        (pressEnter)="submit()"
      ></idp-password-form>
      <idp-password-pattern-error [errors]="passwordForm.errors"></idp-password-pattern-error>
    </tpl-form-field>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="passwordForm.invalid"
        (click)="submit()"
      >
        設定する
      </button>
    </div>
  </idp-box>
</idp-page-container>
