import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonComponent, BreadcrumbComponent, BreadcrumbItemComponent, PageTitleComponent } from '@triple/triad';
import { PrefecturePipe, TbxCommonModule, nonNullable } from '@twogate-npm/toolbox-angular';
import { Observable, filter } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { IdpLocalePipe } from '@idp/features/locale';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';
import { COUNTRY_SELECT_LIST } from '@idp/shared/libs/options/country';
import { CountryPipe } from '@idp/shared/pipes/country/country.pipe';
import { GenderPipe } from '@idp/shared/pipes/gender/gender.pipe';
import { PhoneNumberPipe } from '@idp/shared/pipes/phone-number/phone-number.pipe';

import { ProfileEditModalComponent } from '../../components/profile-edit-modal/profile-edit-modal.component';
import { Profile } from '../../models/profile.type';
import { ProfileUsecaseService } from '../../services/profile-usecase.service';
import { selectProfile } from '../../store/profile';

@Component({
  selector: 'page-profile',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TbxCommonModule,
    RouterLink,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpLocalePipe,
    IdpPageContainerComponent,
    PageTitleComponent,
    GenderPipe,
    PrefecturePipe,
    PhoneNumberPipe,
    CountryPipe,
  ],
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  COUNTRY_SELECT_LIST = COUNTRY_SELECT_LIST;

  readonly state$: Observable<Profile>;
  readonly verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;

  private readonly store = inject(Store);
  private readonly profileUsecase = inject(ProfileUsecaseService);
  private readonly dialog = inject(Dialog);

  constructor() {
    this.state$ = this.store.select(selectProfile).pipe(
      filter((profile) => !!profile),
      nonNullable(),
    );
  }

  ngOnInit() {
    this.profileUsecase.fetchProfile();
  }

  async openEditModal(profile: Profile) {
    const dialogRef = this.dialog.open(ProfileEditModalComponent, {
      maxWidth: '580px',
      width: '95%',
      data: { profile },
    });
    await this.profileUsecase.fetchProfile();
    return dialogRef;
  }
}
