import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { TriadConfigToken } from '@triad/features/config';

export const withAppVersionInterceptor: (apiEndpoint: string) => HttpInterceptorFn =
  (apiEndpoint: string) => (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const config = inject(TriadConfigToken);
    const version = config.versions.triadVersion;

    if (req.url.startsWith(apiEndpoint)) {
      return next(req.clone({ setHeaders: { 'x-app-version': version } }));
    } else {
      return next(req);
    }
  };
