import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { User } from '../models/user.type';

export const featureKey = 'user';

export const setCurrentUser = createAction('[User] Set Current User', props<{ user: User | null }>());
export const markSmsVerified = createAction('[User] Verify SMS');

export const UserActions = {
  setCurrentUser,
  markSmsVerified,
};

export interface State {
  currentUser: User | null | undefined;
}

const initialState: State = {
  currentUser: undefined,
};

const userReducer = createReducer(
  initialState,
  on(UserActions.setCurrentUser, (state, { user }) => ({ ...state, currentUser: user })),
  on(UserActions.markSmsVerified, (state) => ({
    ...state,
    currentUser: state.currentUser ? { ...state.currentUser, smsVerified: true } : null,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>(featureKey);
export const selectCurrentUser = createSelector(selectFeature, (state) => state.currentUser);
