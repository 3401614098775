<header class="header">
  @if (logoImage) {
    <a class="header-image" routerLink="/">
      <img
        [src]="logoImage.src"
        class="header-image-logo"
        alt="Logo"
        [width]="logoImage.originalWidth"
        [height]="logoImage.originalHeight"
        [style.height.px]="logoImage.displayHeight"
      />
      @if (showSiteType !== false) {
        <div class="header-site-type" [class.is-idp]="siteType === 'idp'">
          {{ siteType === 'ticket' ? 'LIVE TICKET' : 'HOME' }}
        </div>
      }
    </a>
  }

  <div class="menu">
    <tpl-site-menu-button
      [siteType]="siteType"
      [isOpen]="isOpen"
      [barType]="menuButtonBarType"
      (click)="isOpen = !isOpen"
    ></tpl-site-menu-button>
  </div>
</header>

<idp-site-menu [isOpen]="isOpen" [siteType]="siteType" (internalLinkClicked)="isOpen = false"></idp-site-menu>
