import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TbxIconModule } from '@twogate-npm/toolbox-angular';

import { NestedTriadLink } from '@triad/features/config';

import { SiteFooterShowExternalIcon, SiteFooterStyle } from '../site-footer/site-footer.component';

@Component({
  selector: 'tpl-site-footer-item',
  standalone: true,
  imports: [CommonModule, RouterModule, TbxIconModule],
  templateUrl: './site-footer-item.component.html',
  styleUrl: './site-footer-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteFooterItemComponent {
  @Input()
  link!: NestedTriadLink;
  @Input()
  footerStyle!: SiteFooterStyle;
  @Input()
  showExternalIcon!: SiteFooterShowExternalIcon;
  @Input()
  @HostBinding('class.is-root')
  isRoot = false;

  @HostBinding('class.style-bold')
  get isStyleBold() {
    return this.footerStyle === 'bold';
  }
}
