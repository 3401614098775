import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'tpl-checkbox',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TbxCommonComponents],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  color: 'primary' | 'accent' | 'attention' = 'primary';
  @HostBinding('attr.tabindex')
  tabindex = 0;

  checkbox = new FormControl(false);

  get checkboxClasses() {
    return [`color-${this.color}`];
  }

  private readonly ngControl = inject(NgControl, {
    self: true,
    optional: true,
  });

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.checkbox.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.onChangeCallback(value);
      this.onTouchedCallback();
    });
  }

  toggleCheckbox() {
    this.checkbox.setValue(!this.checkbox.value);
  }

  writeValue(checked: boolean): void {
    this.checkbox.setValue(checked, { emitEvent: false });
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: unknown) => void = () => {};

  @HostListener('keydown.space', ['$event'])
  setCheckFromKey($event: KeyboardEvent) {
    $event.preventDefault();
    this.toggleCheckbox();
  }
}
