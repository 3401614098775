import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RecaptchaLoaderService } from '../../services/recaptcha-loader.service';

let nextId = 0;

export type NeverUndefined<T> = T extends undefined ? never : T;

export type RecaptchaErrorParameters = Parameters<NeverUndefined<ReCaptchaV2.Parameters['error-callback']>>;

// チェックボックスタイプ
@Component({
  selector: 'tpl-re-captcha',
  template: ``,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input()
  @HostBinding('attr.id')
  id = `ngrecaptcha-${nextId++}`;

  @Input() siteKey!: string;
  @Input() theme!: ReCaptchaV2.Theme;
  @Input() type!: ReCaptchaV2.Type;
  @Input() size!: ReCaptchaV2.Size;
  // https://cloud.google.com/recaptcha-enterprise/docs/actions-website?hl=ja
  @Input() action!: string;
  @Input() tabIndex!: number;
  @Input() badge!: ReCaptchaV2.Badge;
  @Input() errorMode: 'handled' | 'default' = 'default';

  @Output() resolved = new EventEmitter<string | null>();
  @Output() errored = new EventEmitter<RecaptchaErrorParameters>();

  private subscription?: Subscription;
  private widget!: number | null;
  private grecaptcha!: ReCaptchaV2.ReCaptcha;
  private executeRequested!: boolean;

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  // 併用するためには score-based の方で読み込みが必要
  private readonly loader = inject(RecaptchaLoaderService);
  private readonly zone = inject(NgZone);
  private readonly control = inject(NgControl, {
    self: true,
    optional: true,
  });

  constructor() {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.loader.ready?.subscribe((grecaptcha) => {
      if (grecaptcha != null && grecaptcha.render instanceof Function) {
        this.grecaptcha = grecaptcha;
        this.renderRecaptcha();
      }
    });
  }

  ngOnDestroy(): void {
    // reset the captcha to ensure it does not leave anything behind
    // after the component is no longer needed
    this.grecaptchaReset();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  writeValue(v: boolean) {
    if (!v) {
      this.reset();
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  /**
   * Executes the invisible recaptcha.
   * Does nothing if component's size is not set to "invisible".
   */
  public execute(): void {
    if (this.size !== 'invisible') {
      return;
    }

    if (this.widget != null) {
      this.grecaptcha.execute(this.widget);
    } else {
      // delay execution of recaptcha until it actually renders
      this.executeRequested = true;
    }
  }

  public reset(): void {
    if (this.widget != null) {
      if (this.grecaptcha.getResponse(this.widget)) {
        // Only emit an event in case if something would actually change.
        // That way we do not trigger "touching" of the control if someone does a "reset"
        // on a non-resolved captcha.
        this.resolved.emit(null);
        this.onChangeCallback(null);
        this.onTouchedCallback();
      }

      this.grecaptchaReset();
    }
  }

  /**
   * ⚠️ Warning! Use this property at your own risk!
   *
   * While this member is `public`, it is not a part of the component's public API.
   * The semantic versioning guarantees _will not be honored_! Thus, you might find that this property behavior changes in incompatible ways in minor or even patch releases.
   * You are **strongly advised** against using this property.
   * Instead, use more idiomatic ways to get reCAPTCHA value, such as `resolved` EventEmitter, or form-bound methods (ngModel, formControl, and the likes).å
   */
  get __unsafe_widgetValue(): string | null {
    return this.widget != null ? this.grecaptcha.getResponse(this.widget) : null;
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: unknown) => void = () => {};

  /** @internal */
  private expired() {
    this.resolved.emit(null);
    this.onChangeCallback(null);
    this.onTouchedCallback();
  }

  /** @internal */
  private onError(args: RecaptchaErrorParameters) {
    this.errored.emit(args);
  }

  /** @internal */
  private captchaResponseCallback(response: string) {
    this.resolved.emit(response);
    this.onChangeCallback(response);
    this.onTouchedCallback();
  }

  /** @internal */
  private grecaptchaReset() {
    if (this.widget != null) {
      this.zone.runOutsideAngular(() => this.grecaptcha.reset(this.widget as number));
    }
  }

  /** @internal */
  private renderRecaptcha() {
    // This `any` can be removed after @types/grecaptcha get updated
    const renderOptions: ReCaptchaV2.Parameters & ReCaptchaV2.Action = {
      badge: this.badge,
      callback: (response: string) => {
        this.zone.run(() => this.captchaResponseCallback(response));
      },
      'expired-callback': () => {
        this.zone.run(() => this.expired());
      },
      action: this.action,
      sitekey: this.siteKey,
      size: this.size,
      tabindex: this.tabIndex,
      theme: this.theme,
      type: this.type,
    };

    if (this.errorMode === 'handled') {
      renderOptions['error-callback'] = (...args: RecaptchaErrorParameters) => {
        this.zone.run(() => this.onError(args));
      };
    }

    this.widget = this.grecaptcha.render(this.elementRef.nativeElement, renderOptions);

    if (this.executeRequested === true) {
      this.executeRequested = false;
      this.execute();
    }
  }
}
