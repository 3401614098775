import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LoaderSpinnerColor } from '../loader.type';

@Component({
  selector: 'tpl-loader-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderSpinnerComponent {
  @Input()
  color: LoaderSpinnerColor = 'basic-light';

  get spinnerClasses() {
    return [`color-${this.color}`];
  }
}
