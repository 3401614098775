import { InjectionToken } from '@angular/core';
import { TriadLink, TriadTag } from '@triple/triad';

export interface IdpAssets {
  privacyPolicyLink?: string;
  termsLink?: string;

  home?: {
    image?: string;
    keyVisualVerticalPadding?: number;
    keyVisualHorizontalPadding?: number;
    links?: (TriadLink & { tag?: TriadTag })[];
  };

  relatedSiteLinks?: {
    ticketSiteEntryHistoryLink?: TriadLink;
    ecommerceSitePurchaseHistoryLink?: TriadLink;
  };
}
export const IdpAssetsToken = new InjectionToken<IdpAssets>('IDP_ASSETS');
