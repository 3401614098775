import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[tplPressEnter]',
  standalone: true,
})
export class PressEnterDirective {
  @Output()
  pressEnter = new EventEmitter<KeyboardEvent>();

  private isComposition = false;

  @HostListener('keydown.enter', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.isComposition) {
      return;
    }
    event.preventDefault();
    this.pressEnter.emit(event);
  }

  @HostListener('compositionstart')
  compositionStart() {
    this.isComposition = true;
  }

  @HostListener('compositionend')
  compositionEnd() {
    this.isComposition = false;
  }
}
