<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>{{ 'shared.mypage.title' | locale }}</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  @if (orderHistoryItems.length) {
    <div class="section">
      <h2 class="section-heading">注文履歴</h2>
      <div class="section-content">
        @for (item of orderHistoryItems; track item.href) {
          <tpl-menu-button class="section-content-item" [item]="item"></tpl-menu-button>
        }
      </div>
    </div>
  }
  <div class="section">
    <h2 class="section-heading">アカウント設定</h2>
    <div class="section-content">
      @for (item of accountItems; track item.href) {
        <tpl-menu-button class="section-content-item" [item]="item"></tpl-menu-button>
      }
    </div>
  </div>
</idp-page-container>
