import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ToastLayoutComponent } from './toast-layout/toast-layout.component';
import {
  Toast,
  ToastConfig,
  ToastConfigInput,
  ToastHorizontalPosition,
  ToastPosition,
  ToastVerticalPosition,
  defaultToastConfig,
} from './toast.type';

@Injectable({ providedIn: 'root' })
export class ToastService {
  get allToasts$() {
    return this.toasts$.asObservable();
  }
  private toasts$ = new BehaviorSubject<{ length: number } & { [position in ToastPosition]: Toast[] }>({
    topLeft: [],
    topRight: [],
    topCenter: [],
    bottomLeft: [],
    bottomRight: [],
    bottomCenter: [],
    length: 0,
  });
  private overlayRef: OverlayRef | null = null;
  private readonly overlay = inject(Overlay);
  private previousToast: Toast | null = null;

  open(config: ToastConfigInput) {
    const toastConfig = { ...defaultToastConfig, ...config };
    // dedupe
    if (
      this.previousToast?.config.verticalPosition === toastConfig.verticalPosition &&
      this.previousToast?.config.horizontalPosition === toastConfig.horizontalPosition &&
      this.previousToast?.config.message === toastConfig.message &&
      this.previousToast?.config.dedupe
    ) {
      this.close(this.previousToast);
    }

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        panelClass: 'tpl-toast-container',
      });
      this.overlayRef.attach(new ComponentPortal(ToastLayoutComponent));
    }

    this.addToast(toastConfig);
  }

  close(toast: Toast) {
    this.closeToast(toast);
    if (this.toasts$.value.length === 0) {
      this.overlayRef?.detach();
      this.overlayRef = null;
    }
  }

  private addToast(config: ToastConfig) {
    const id = this.generateId();
    const toasts = { ...this.toasts$.value };
    const position = this.getToastPosition(config.verticalPosition, config.horizontalPosition);
    const newToast = { id, config };
    toasts[position] = [...toasts[position], newToast];
    toasts.length += 1;
    this.toasts$.next(toasts);
    this.previousToast = { ...newToast };
  }

  private closeToast(toast: Toast) {
    const toasts = { ...this.toasts$.value };
    const position = this.getToastPosition(toast.config.verticalPosition, toast.config.horizontalPosition);
    const originalLength = toasts[position].length;
    toasts[position] = toasts[position].filter((t) => t.id !== toast.id);
    if (originalLength !== toasts[position].length) {
      toasts.length -= 1;
    }
    this.toasts$.next(toasts);
    if (toast.id === this.previousToast?.id) {
      this.previousToast = null;
    }
  }

  private generateId() {
    const timestamp = new Date().getTime().toString();
    const random = Math.random().toString(36).substring(2);
    return `${timestamp}-${random}`;
  }

  private getToastPosition(
    verticalPosition: ToastVerticalPosition,
    horizontalPosition: ToastHorizontalPosition,
  ): ToastPosition {
    return `${verticalPosition}${horizontalPosition[0].toUpperCase()}${horizontalPosition.slice(1)}` as ToastPosition;
  }
}
