import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '@sentry/browser';
import {
  BreadcrumbComponent,
  BreadcrumbItemComponent,
  ButtonComponent,
  FormComponents,
  PressEnterDirective,
} from '@triple/triad';
import { TbxCommonComponents, nonNullable } from '@twogate-npm/toolbox-angular';
import { Observable, filter } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { IdpLocalePipe } from '@idp/features/locale';
import { selectCurrentUser } from '@idp/features/user/store/user';
import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { EmailChangeUsecaseService } from '../../services/email-change-usecase.service';

@Component({
  selector: 'page-update-email',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    TbxCommonComponents,
    ButtonComponent,
    FormComponents,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
    IdpLocalePipe,
  ],
  templateUrl: './update-email.page.html',
  styleUrls: ['./update-email.page.scss'],
})
export class UpdateEmailPage {
  emailForm = new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] });
  readonly state$: Observable<User>;
  private readonly emailChangeUsecase = inject(EmailChangeUsecaseService);

  private readonly store = inject(Store);
  private readonly idpConfig = inject(IdpConfigToken);

  get authEmailDomain() {
    return `@${this.idpConfig.authEmailDomain}`;
  }

  constructor() {
    this.state$ = this.store.select(selectCurrentUser).pipe(
      filter((user) => !!user),
      nonNullable(),
    );
  }

  async submit() {
    if (this.emailForm.invalid) {
      return;
    }

    await this.emailChangeUsecase.sendVerificationEmail(this.emailForm.value);
  }
}
