import { Routes } from '@angular/router';

import { authGuard, userStateGuard } from './features/auth/guards/auth.guard';
import { SiteLayoutComponent } from './features/layout/components/site-layout/site-layout.component';

export const mainRouteChildren: Routes = [
  {
    path: '',
    loadComponent: () => import('./features/home/pages/home/home.page'),
  },
  // TODO: 未実装
  // {
  //   path: 'notifications',
  //   loadChildren: () => import('./features/notification/notification.routing'),
  // },
  {
    path: 'mypage',
    loadChildren: () => import('./features/mypage/mypage.routing'),
    canActivate: [authGuard],
  },
];

export const secondaryRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.routing'),
    canActivate: [userStateGuard],
  },
  {
    path: 'registration',
    loadChildren: () => import('./features/auth/registration.routing'),
    canActivate: [userStateGuard],
  },
];

export const appRoutes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    canActivate: [userStateGuard],
    children: [
      {
        path: '',
        children: [...mainRouteChildren],
      },
    ],
  },
  ...secondaryRoutes,
];

export default appRoutes;
