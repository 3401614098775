import { Provider } from '@angular/core';

import { TriadAssets, TriadAssetsToken } from './models/asset.type';
import { TriadConfig, TriadConfigToken } from './models/config.type';

export function provideTriadConfig(config: TriadConfig, assets: TriadAssets): Provider {
  return [
    {
      provide: TriadConfigToken,
      useValue: config,
    },
    {
      provide: TriadAssetsToken,
      useValue: assets,
    },
  ];
}
