import { Routes } from '@angular/router';

import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { authGuard, authenticatedGuard } from './guards/auth.guard';
import { EmailCodeHandlerPage } from './pages/email-code-handler/email-code-handler.page';
import { PasswordResetPage } from './pages/password-reset/password-reset.page';
import { RegisterPage } from './pages/register/register.page';
import { RegisterPasswordPage } from './pages/register-password/register-password.page';
import { ResetPasswordCodeHandlerPage } from './pages/reset-password-code-handler/reset-password-code-handler.page';
import { SignInPage } from './pages/sign-in/sign-in.page';
import { provideAuthState } from './store';

export const authRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    providers: [provideAuthState()],
    canActivate: [authenticatedGuard],
    children: [
      {
        path: '',
        children: [
          {
            path: 'register',
            component: RegisterPage,
          },
          {
            path: 'sign_in',
            component: SignInPage,
          },
          {
            path: 'verify_email',
            component: EmailCodeHandlerPage,
          },
          {
            path: 'verify_phone',
            canActivate: [authGuard],
            loadChildren: () => import('../verify-phone/verify-phone.routing'),
          },
          {
            path: 'register_password',
            component: RegisterPasswordPage,
          },
          {
            path: 'password_reset',
            children: [
              {
                path: '',
                component: PasswordResetPage,
              },
              {
                path: 'handler',
                component: ResetPasswordCodeHandlerPage,
              },
            ],
          },
        ],
      },
      {
        path: '',
        redirectTo: 'sign_in',
        pathMatch: 'full',
      },
    ],
  },
];

export default authRoutes;
