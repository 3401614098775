import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, LoaderService, ToastService } from '@triple/triad';

import { EmailChangeService } from './email-change-service';

@Injectable({ providedIn: 'root' })
export class EmailChangeUsecaseService {
  private readonly router = inject(Router);
  private readonly emailChangeService = inject(EmailChangeService);
  private readonly alertService = inject(AlertService);
  private readonly loaderService = inject(LoaderService);
  private readonly toastService = inject(ToastService);

  async sendVerificationEmail(email: string) {
    const loader = this.loaderService.showLoader({ message: 'メールを送信しています' });
    const result = await this.emailChangeService.sendVerificationEmail(email);
    loader.close();

    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.alertService.showAlert({
      header: '送信完了',
      message: '確認メールを送信しました。メールをご確認ください',
      buttons: [
        {
          text: 'OK',
          color: 'basic',
        },
      ],
    });

    this.router.navigate(['/mypage', 'update_email', 'handler'], {
      queryParams: { email },
    });
    return true;
  }

  async verifyEmail(code: string, email: string) {
    const loader = this.loaderService.showLoader({ message: 'メールを確認しています' });
    const result = await this.emailChangeService.verifyEmail(code);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.toastService.open({
      message: 'メールアドレスを変更しました',
      duration: 5000,
    });
    this.router.navigate(['/mypage']);
    return true;
  }
}
