import { Dialog } from '@angular/cdk/dialog';
import { Injectable, inject } from '@angular/core';

import { LoaderConfig } from '../loader.type';

import { LoaderComponent } from './loader.component';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private readonly defaultLoaderConfig: LoaderConfig = {
    message: '読み込み中...',
  };
  private readonly dialog = inject(Dialog);

  showLoader(config: LoaderConfig = this.defaultLoaderConfig) {
    const loaderRef = this.dialog.open(LoaderComponent, {
      maxWidth: '300px',
      data: config,
      disableClose: true,
    });

    return loaderRef;
  }
}
