<idp-page-container>
  <idp-box>
    <span slot="header">確認コードの入力</span>
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <div class="form-value">{{ email }}</div>
    </tpl-form-field>
    <tpl-form-field>
      <tpl-form-label>確認コード</tpl-form-label>
      <input
        type="text"
        placeholder="XXXXXX"
        inputmode="numeric"
        pattern="[0-9]*"
        autocomplete="one-time-code"
        [formControl]="codeForm"
        tplPressEnter
        (pressEnter)="submit()"
      />
    </tpl-form-field>
    <div class="description">
      入力されたメールに確認コードをお送りしました。
      1時間以内にコードを入力してメールアドレスの認証を完了させてください。
    </div>
    <div class="box-action">
      <button
        tpl-button
        color="basic"
        icon="arrow-right"
        width="fixed"
        [disabled]="codeForm.invalid"
        (click)="submit()"
      >
        認証する
      </button>
      <button
        tpl-button
        class="plain-button size-small"
        buttonType="plain"
        width="auto"
        size="small"
        icon="arrow-left"
        iconPosition="left"
        routerLink="/auth/register"
        color="basic"
      >
        メールを再送信する
      </button>
    </div>
  </idp-box>
</idp-page-container>
