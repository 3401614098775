import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class SessionService {
  abstract get isSignedIn$(): Observable<boolean>;

  abstract navigateSignUp({ returnPath }: { returnPath?: string }): void;
  abstract navigateSignIn({ returnPath }: { returnPath?: string }): void;

  abstract signOut(): Promise<boolean>;
}
