@if (link.type === 'external') {
  <a class="link-item" [href]="link.href" target="_blank" rel="noopener noreferrer">
    {{ link.label }}
    @if (showExternalIcon === 'all' || (showExternalIcon === 'root' && isRoot)) {
      <tbx-icon class="external-icon" name="external-link"></tbx-icon>
    }
  </a>
} @else if (link.type === 'internal') {
  <a [routerLink]="link.href" class="link-item">{{ link.label }}</a>
} @else {
  <span class="link-item">{{ link.label }}</span>
}
@if (link.children) {
  <div class="link-item-children">
    @for (child of link.children; track child.label) {
      <tpl-site-footer-item
        [link]="child"
        [footerStyle]="footerStyle"
        [showExternalIcon]="showExternalIcon"
      ></tpl-site-footer-item>
    }
  </div>
}
