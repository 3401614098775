@if (item.type === 'internal') {
  <a
    class="type-stroked"
    tpl-button
    color="basic"
    width="auto"
    buttonType="stroked"
    icon="arrow-right"
    [routerLink]="item.href"
    queryParamsHandling="merge"
  >
    <div class="button-inner">
      <div class="button-text">{{ item.label }}</div>
      @if (tag) {
        <div class="button-tag" [ngClass]="tag.theme">{{ tag.label }}</div>
      }
    </div>
  </a>
} @else {
  <a
    class="type-stroked"
    tpl-button
    color="basic"
    width="auto"
    buttonType="stroked"
    icon="external-link"
    [href]="item.href"
  >
    <div class="button-inner">
      <div class="button-text">{{ item.label }}</div>
      @if (tag) {
        <div class="button-tag" [ngClass]="tag.theme">{{ tag.label }}</div>
      }
    </div>
  </a>
}
