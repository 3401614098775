import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { Profile, InputProfile } from '../models/profile.type';

export const featureKey = 'profile';

export const setProfile = createAction('[Profile] Set Profile', props<{ profile: Profile | null }>());
export const setInputProfile = createAction('[Profile] Set Input Profile', props<{ inputProfile: InputProfile }>());

export const ProfileActions = {
  setProfile,
  setInputProfile,
};

export interface State {
  profile: Profile | null | undefined;
  inputProfile: InputProfile | undefined;
}

const initialState: State = {
  profile: undefined,
  inputProfile: undefined,
};

const profileReducer = createReducer(
  initialState,
  on(ProfileActions.setProfile, (state, { profile }) => ({ ...state, profile })),
  on(ProfileActions.setInputProfile, (state, { inputProfile }) => ({ ...state, inputProfile })),
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>(featureKey);
export const selectProfile = createSelector(selectFeature, (state) => state.profile);
export const selectInputProfile = createSelector(selectFeature, (state) => state.inputProfile);
