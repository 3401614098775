import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'addressFieldErrors',
  standalone: true,
})
export class AddressFieldErrorsPipe implements PipeTransform {
  transform(
    errors: ValidationErrors | null,
    name: 'zipCode' | 'prefectureCode' | 'city' | 'street',
  ): ValidationErrors | null {
    if (!errors) {
      return null;
    }
    const result: Record<string, unknown> = {};
    const nameKebabCase = name.replace(/([A-Z])/g, '-$1').toLowerCase();

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && key.startsWith(nameKebabCase)) {
        result[key] = errors[key] as unknown;
      }
    }

    return result;
  }
}
