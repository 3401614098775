import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AlertService, LoaderService, ToastService } from '@triple/triad';

import { InputProfile } from '../models/profile.type';

import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class ProfileUsecaseService {
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly alertService = inject(AlertService);
  private readonly loaderService = inject(LoaderService);
  private readonly toastService = inject(ToastService);
  private readonly profileService = inject(ProfileService);

  fetchProfile() {
    return this.profileService.fetchProfile();
  }

  async registerProfile(param: InputProfile) {
    const loader = this.loaderService.showLoader({ message: 'プロフィールを登録しています' });
    const result = await this.profileService.updateProfile(param);
    loader.close();

    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    await this.router.navigate(['/registration', 'complete']);
    this.toastService.open({ message: 'プロフィールを登録しました', duration: 3000 });
    return true;
  }

  async updateProfile(param: InputProfile) {
    const loader = this.loaderService.showLoader({ message: 'プロフィールを更新しています' });
    const result = await this.profileService.updateProfile(param);
    loader.close();

    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.toastService.open({ message: 'プロフィールを更新しました', duration: 3000 });
    return true;
  }

  async saveProfileTemporary(param: InputProfile) {
    this.profileService.saveProfileTemporary(param);
    await this.router.navigate(['/registration', 'profile', 'confirm']);

    return true;
  }
}
