import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TriadService } from '@triple/triad';
import { IdpService } from '@triple/triad-idp';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly triadService = inject(TriadService);
  private readonly idpService = inject(IdpService);

  ngOnInit() {
    this.triadService.initialize();
    this.idpService.initialize();
  }
}
