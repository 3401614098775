import { Routes } from '@angular/router';

import { UnregisterPage } from '../auth/pages/unregister/unregister.page';
import { UpdateEmailPage } from '../auth/pages/update-email/update-email.page';
import { UpdateEmailCodeHandlerPage } from '../auth/pages/update-email-code-handler/update-email-code-handler.page';
import { UpdatePasswordPage } from '../auth/pages/update-password/update-password.page';
import { ProfilePage } from '../profile/pages/profile/profile.page';

import { MypagePage } from './pages/mypage/mypage.page';
import { NotificationSettingPage } from './pages/notification-setting/notification-setting.page';

export const mypageRoutes: Routes = [
  {
    path: '',
    component: MypagePage,
  },
  {
    path: 'profile',
    component: ProfilePage,
  },
  {
    path: 'update_password',
    component: UpdatePasswordPage,
  },
  {
    path: 'update_email',
    children: [
      {
        path: '',
        component: UpdateEmailPage,
      },
      {
        path: 'handler',
        component: UpdateEmailCodeHandlerPage,
      },
    ],
  },
  {
    path: 'unregister',
    component: UnregisterPage,
  },
  {
    path: 'notification_setting',
    component: NotificationSettingPage,
  },
];

export default mypageRoutes;
