import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent, TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { PasswordPatternErrorComponent } from '../password-pattern-error/password-pattern-error.component';

@Component({
  selector: 'idp-password-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TbxCommonComponents,
    IconComponent,
    PasswordPatternErrorComponent,
  ],
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormComponent {
  @Input()
  passwordType: 'current-password' | 'new-password' = 'current-password';
  @Input()
  form!: FormControl<string>;

  passwordVisibility = false;

  get inputType() {
    return this.passwordVisibility ? 'text' : 'password';
  }
  get iconType() {
    return this.passwordVisibility ? 'eye-off' : 'eye';
  }

  togglePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
  }
}
