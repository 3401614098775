import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone, inject } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { TriadConfigToken } from '../../config';
import { TriadSentryConfigToken } from '../models/sentry-token.type';

type Errors = Error | ErrorEvent | HttpErrorResponse;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private bannedBreadcrumbUrls: string[] = [
    'https://www.google-analytics.com', // ga
    'https://stats.g.doubleclick.net', // ga
    'https://cdn.cookielaw.org', // one trust
    'https://cloudflareinsights.com/cdn-cgi/rum', // cloudflare Real User Measurement
  ];
  private readonly ngZone = inject(NgZone);
  private readonly config = inject(TriadConfigToken);
  private readonly sentryConfig = inject(TriadSentryConfigToken);

  constructor() {
    Sentry.init({
      enabled: this.config.production && location.host !== 'localhost:4200' && location.host !== 'localhost:4300',
      environment: this.config.environment,
      debug: this.config.environment !== 'production',
      dsn: this.sentryConfig.dsn,
      release: this.sentryConfig.release,
      sampleRate: this.config.environment === 'production' ? 0.5 : 1,
      denyUrls: [
        // one trust
        /cdn\.cookielaw\.org/,
        // recaptcha
        /www\.gstatic\.com\/recaptcha/,
        /multipay\.komoju\.com/,
      ],
      ignoreErrors: [
        /network error/i,
        /invalid action/i,
        /loading chunk/i,
        /No Firebase App/i,
        /Non-Error exception captured/i,
        // https://github.com/google/recaptcha/issues/269
        // reCAPTCHA の Timeout エラーで放置していると必ず起きるので無視
        /(Uncaught)?\s*\(in promise\):?\s*Timeout/i,
        /Timeout \(n\)/,
        // firebase 9 にあげてからこのエラーがよく出るので無視
        /Firebase: Error \(auth\/network-request-failed\)/,
        // web animation api のサポートが無いブラウザー(古いブラウザー)ではエラーがでるので無視
        /animate is not a function/,
        /invalid reception unification/,
        // load chunk error
        /Importing a module script failed/,
        /Failed to fetch dynamically imported module/,
        /error loading dynamically imported module/,
        // ios 15.4 未満でのエラー、 ios 15.4 以上でのみ動作する
        /this\.komojuFields\.nativeElement.submit is not a function/,
        // ios chrome で翻訳機能を使うと出るよくわからないエラー
        /undefined is not an object \(evaluating 'a\.L'\)/,
        // recaptcha のネットワークエラーのときに稀に出るエラー
        /XhrError/,
        // デプロイ時に古いバージョンのキャッシュが残っているときに出るエラー
        // cloudflare は 404 のとき、 index.html を返すので、 このエラーが出る
        /'text\/html' is not a valid JavaScript MIME type/,
      ],
      integrations: [new Sentry.Integrations.GlobalHandlers({ onerror: false, onunhandledrejection: false })],
      beforeBreadcrumb: (breadcrumb, hint) => this.filterBreadcrumb(breadcrumb, hint),
    });
  }

  extractError(err: (Errors & { ngOriginalError?: Errors }) | null) {
    if (err == null) {
      return null;
    }

    const error = err.ngOriginalError ? err.ngOriginalError : err;

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      // ネットワーク不通と400番以上のエラーではSentryにあげない
      if (error.status === 0 || error.status >= 400) {
        return null;
      }

      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    if (error.message) {
      Sentry.addBreadcrumb({
        message: 'Unknown error',
        data: error,
      });
      return new Error(error.message);
    }

    // unknown error
    this.ngZone.runOutsideAngular(() => {
      Sentry.withScope((scope) => {
        scope.addBreadcrumb({
          message: 'Unknown error',
          data: error,
        });
      });
      Sentry.captureException(err);
    });
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const extractedError = this.extractError(error);

    if (extractedError !== null) {
      if (!this.config.production) {
        console.error(extractedError);
      }
      this.ngZone.runOutsideAngular(() => {
        Sentry.captureException(extractedError);
      });
    }
  }

  private filterBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint) {
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.type === 'http' &&
      this.bannedBreadcrumbUrls.some((v) => (breadcrumb.data?.['url'] as string).includes(v))
    ) {
      return null;
    }
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.type === 'http' &&
      /^.*\.svg$/.test(breadcrumb.data?.['url'] as string)
    ) {
      return null;
    }
    return breadcrumb;
  }
}
