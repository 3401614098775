<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <a routerLink="/mypage">{{ 'shared.mypage.title' | locale }}</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>個人情報確認・編集</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <tpl-page-title>個人情報確認・編集</tpl-page-title>
  @if (state$ | async; as state) {
    <div class="page-content">
      <div class="description">
        登録内容に変更がある場合、「編集する」ボタンから新しい情報を入力し、「保存する」を押してください。
      </div>
      <dl class="profile-body">
        <dt>メールアドレス</dt>
        <dd>{{ state.email }}</dd>
        @if (verifyPhoneNumber) {
          <dt>電話番号</dt>
          <dd>{{ state.phoneNumber | phoneNumber }}</dd>
        }
        <dt>氏名</dt>
        <dd>{{ state.profile.lastName }} {{ state.profile.firstName }}</dd>
        <dt>フリガナ</dt>
        <dd>{{ state.profile.lastNameKana }} {{ state.profile.firstNameKana }}</dd>
        <dt>生年月日</dt>
        <dd>{{ state.profile.birthDate | date: 'yyyy年M月d日' }}</dd>
        <dt>性別</dt>
        <dd>{{ state.profile.gender | gender }}</dd>
        @if (!verifyPhoneNumber) {
          <dt>電話番号</dt>
          <dd>{{ state.contactAddress.phoneNumber | phoneNumber }}</dd>
        }
        <dt>国</dt>
        <dd>{{ state.contactAddress.countryCode | country }}</dd>
        @if (
          state.contactAddress.zipCode ||
          state.contactAddress.prefectureCode ||
          state.contactAddress.city ||
          state.contactAddress.street ||
          state.contactAddress.building
        ) {
          <dt>住所</dt>
          <dd>
            @if (state.contactAddress.zipCode) {
              <div>〒{{ state.contactAddress.zipCode }}</div>
            }
            @if (state.contactAddress.prefectureCode || state.contactAddress.city || state.contactAddress.street) {
              <div>
                <span>{{ state.contactAddress.prefectureCode | prefecture: true }}</span>
                <span>{{ state.contactAddress.city }}</span>
                <span>{{ state.contactAddress.street }}</span>
              </div>
            }
            @if (state.contactAddress.building) {
              <div>{{ state.contactAddress.building }}</div>
            }
          </dd>
        }
      </dl>
      <div class="action">
        <a
          class="button"
          tpl-button
          color="accent"
          width="fixed"
          buttonType="filled"
          icon="arrow-right"
          (click)="openEditModal(state)"
        >
          編集する
        </a>
        <button
          class="plain-button size-small"
          tpl-button
          color="basic"
          buttonType="plain"
          width="auto"
          icon="arrow-left"
          iconPosition="left"
          routerLink="../"
        >
          {{ 'shared.mypage.title' | locale }}へ戻る
        </button>
      </div>
    </div>
  }
</idp-page-container>
