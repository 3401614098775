import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonComponent } from '@triad/shared/components/button';

@Component({
  selector: 'tpl-sign-out-button',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './sign-out-button.component.html',
  styleUrl: './sign-out-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutButtonComponent {}
