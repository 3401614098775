import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TriadLink, TriadTag } from '@triad/features/config';

import { ButtonComponent } from '../button';

@Component({
  selector: 'tpl-menu-button',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent],
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuButtonComponent {
  @Input() item!: TriadLink;
  @Input() tag?: TriadTag;
}
