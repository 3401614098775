<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <a routerLink="/mypage">{{ 'shared.mypage.title' | locale }}</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>アカウントの削除</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <tpl-page-title>アカウントの削除</tpl-page-title>
  <div class="page-content">
    <section>
      <div class="description">
        <p>
          アカウントの削除における注意事項に同意いただける方のみ、チェックボックスにチェックをし、[アカウントを削除する]を押してください。
        </p>
      </div>
    </section>
    <div class="section-title">アカウント削除における注意事項</div>
    <section>
      <ul>
        <li>
          アカウントを削除されますと、他連携サイト（チケット、オンラインストア等）も含め、サイトログインができなくなります。
        </li>
        <li>オンラインストアをお申込み中の場合は、商品がお手元に届いてからのお手続きを推奨いたします。</li>
        <li>
          その他ご利用中のサービスがある場合は、サービスのご利用が完了してからのお手続きを推奨いたします。ご不明な点がございます場合はアカウント削除前に各サービスのお問い合わせ先へお問い合わせください。
        </li>
        <li>アカウント削除手続き完了後のアカウント復旧はいたしかねます。十分にご注意ください。</li>
      </ul>
    </section>
    <section>
      <div class="description attention">
        <p>＜＜チケットをご購入済みのお客様＞＞</p>
        <p>
          チケット購入後、公演前にアカウントを削除されますと、ご購入済みチケットの確認が取れなくなりますので、デジタルチケットの取得ができなくなります。デジタルチケットが無い場合、公演にはご参加いただけません。
        </p>
        <p>また、返金もできませんので、アカウント削除される場合は、くれぐれもご注意ください。</p>
      </div>
    </section>
    <div class="checkbox-list">
      <label class="checkbox-item">
        <input [(ngModel)]="hasAgreement.ticket" type="checkbox" />
        <idp-checkbox-display [checked]="hasAgreement.ticket"></idp-checkbox-display>
        <div class="checkbox-text">購入済みのチケットはございませんか？</div>
      </label>
      <label class="checkbox-item">
        <input [(ngModel)]="hasAgreement.entry" type="checkbox" />
        <idp-checkbox-display [checked]="hasAgreement.entry"></idp-checkbox-display>
        <div class="checkbox-text">
          アカウントを削除してもチケットのお申込みは自動でキャンセルされません。
          <br />
          チケットお申込みのキャンセル、クレジットカード登録の削除を行ってからアカウントの削除をおこなってください。
        </div>
      </label>
      <label class="checkbox-item">
        <input [(ngModel)]="hasAgreement.product" type="checkbox" />
        <idp-checkbox-display [checked]="hasAgreement.product"></idp-checkbox-display>
        <div class="checkbox-text">お手元に届いていないオンラインストア商品はございませんか？</div>
      </label>
      <label class="checkbox-item">
        <input [(ngModel)]="hasAgreement.caution" type="checkbox" />
        <idp-checkbox-display [checked]="hasAgreement.caution"></idp-checkbox-display>
        <div class="checkbox-text">上記のアカウント削除における注意事項を全て了承しアカウントの削除を希望します。</div>
      </label>
    </div>
    <div class="action">
      <a
        class="button"
        tpl-button
        color="attention"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="!hasAgreement.ticket || !hasAgreement.product || !hasAgreement.caution"
        (click)="unregister()"
        >アカウントを削除する</a
      >
      <a
        tpl-button
        class="plain-button size-small"
        buttonType="plain"
        width="auto"
        size="small"
        icon="arrow-left"
        iconPosition="left"
        routerLink="/mypage"
        color="basic"
      >
        {{ 'shared.mypage.title' | locale }}へ戻る
      </a>
    </div>
  </div>
</idp-page-container>
