import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  ButtonComponent,
  FormComponents,
  KEN_ALL_PREFECTURE_CODE,
  KenAllService,
  RadioGroupComponent,
  SimpleRadioButtonComponent,
} from '@triple/triad';
import { TbxCommonComponents, nonNullable } from '@twogate-npm/toolbox-angular';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { filter, takeUntil } from 'rxjs';

import { setIsOAuthFlow } from '@idp/features/auth/store/auth';
import { IdpConfigToken } from '@idp/features/config';
import { IdpBoxComponent, IdpPageContainerComponent } from '@idp/shared';
import { FormatPhoneNumberDirective } from '@idp/shared/directives/format-phone-number.directive';
import { ConvertZenkakuDirective } from '@idp/shared/directives/format-zenkaku.directive';
import { FormatZipCodeDirective } from '@idp/shared/directives/format-zip-code.directive';
import { COUNTRY_SELECT_LIST, FOREIGN } from '@idp/shared/libs/options/country';
import { PREFECTURE_SELECT_LIST } from '@idp/shared/libs/options/prefecture';

import { BirthDateFormComponent } from '../../components/birth-date-form/birth-date-form.component';
import { AddressForm, ProfileForm } from '../../models/profile-form.type';
import { InputProfile } from '../../models/profile.type';
import { AddressFieldErrorsPipe } from '../../pipes/address-field-errors.pipe';
import { ProfileFormService } from '../../services/profile-form.service';
import { ProfileUsecaseService } from '../../services/profile-usecase.service';
import { selectInputProfile } from '../../store/profile';

const FOREIGN_ADDRESS_MASK: Pick<
  InputProfile['contactAddress'],
  'zipCode' | 'prefectureCode' | 'city' | 'street' | 'building'
> = {
  zipCode: '',
  prefectureCode: '99',
  city: '',
  street: '',
  building: '',
};

@Component({
  selector: 'page-register-profile',
  standalone: true,
  imports: [
    CommonModule,
    IdpPageContainerComponent,
    IdpBoxComponent,
    FormComponents,
    TbxCommonComponents,
    RadioGroupComponent,
    SimpleRadioButtonComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FormatPhoneNumberDirective,
    ConvertZenkakuDirective,
    AddressFieldErrorsPipe,
    BirthDateFormComponent,
    FormatZipCodeDirective,
  ],
  templateUrl: './register-profile.page.html',
  styleUrls: ['./register-profile.page.scss'],
})
export class RegisterProfilePage implements OnInit, OnDestroy {
  @Input('in_oauth_flow')
  isOAuthFlow?: boolean;
  @ViewChild(FormatPhoneNumberDirective)
  phoneNumberDirective!: FormatPhoneNumberDirective;

  PREFECTURE_SELECT_LIST = PREFECTURE_SELECT_LIST;
  COUNTRY_SELECT_LIST = COUNTRY_SELECT_LIST;

  form: FormGroup<{
    profile: ProfileForm;
    contactAddress: AddressForm;
  }>;

  get profileForm() {
    return this.form.controls.profile;
  }
  get contactAddressForm() {
    return this.form.controls.contactAddress;
  }
  get countryCodeValue() {
    return this.form.value.contactAddress?.countryCode;
  }

  readonly verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;
  readonly phoneCountryWhitelist = inject(IdpConfigToken).phoneCountryWhitelist ?? ['JP'];
  readonly errorMessages = inject(ProfileFormService).addressErrorMessages;
  private readonly profileUsecase = inject(ProfileUsecaseService);
  private readonly kenAllService = inject(KenAllService);
  private readonly profileFormService = inject(ProfileFormService);
  private readonly onDestroy$ = new EventEmitter();
  private readonly store = inject(Store);
  private readonly cdRef = inject(ChangeDetectorRef);

  constructor() {
    this.form = new FormGroup({
      profile: this.profileFormService.getProfileForm(),
      contactAddress: this.profileFormService.getAddressForm({ phoneNumberOptional: this.verifyPhoneNumber }),
    });

    this.store
      .select(selectInputProfile)
      .pipe(
        filter((inputProfile) => !!inputProfile),
        nonNullable(),
        takeUntilDestroyed(),
      )
      .subscribe((inputProfile) => {
        this.form.setValue(inputProfile);
      });

    this.contactAddressForm.controls.zipCode.valueChanges
      .pipe(nonNullable(), this.kenAllService.completeAddress(), takeUntil(this.onDestroy$))
      .subscribe((addresses) => {
        if (addresses && addresses.length > 0) {
          const address = addresses[0];
          this.form.patchValue({
            contactAddress: {
              prefectureCode: KEN_ALL_PREFECTURE_CODE[address.prefecture],
              city: address.city,
              street: address.town,
            },
          });
        }
      });
  }

  ngOnInit() {
    if (this.isOAuthFlow) {
      this.store.dispatch(setIsOAuthFlow({ isOAuthFlow: true }));
    }
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  async submit() {
    if (!this.form.valid) {
      return;
    }

    const { profile, contactAddress } = this.form.getRawValue();
    const profileAttributes = {
      profile: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        firstNameKana: profile.firstNameKana,
        lastNameKana: profile.lastNameKana,
        birthDate: profile.birthDate as `${number}-${number}-${number}`,
        gender: profile.gender,
      },
      contactAddress: {
        phoneNumber: contactAddress.phoneNumber,
        countryCode: contactAddress.countryCode,
        zipCode: contactAddress.zipCode,
        prefectureCode: contactAddress.prefectureCode,
        city: contactAddress.city,
        street: contactAddress.street,
        building: contactAddress.building,
        ...(contactAddress.countryCode === 'JP' ? {} : FOREIGN_ADDRESS_MASK),
      },
    };
    await this.saveProfileTemporary(profileAttributes);
  }

  private saveProfileTemporary(profile: InputProfile) {
    return this.profileUsecase.saveProfileTemporary(profile);
  }

  private async setInitialValue() {
    if (this.form.value.contactAddress?.countryCode !== '') {
      return;
    }
    const profile = await this.profileUsecase.fetchProfile();
    const parsedPhoneNumber = profile.phoneNumber
      ? parsePhoneNumberFromString(profile.phoneNumber, this.phoneCountryWhitelist[0])
      : null;
    if (parsedPhoneNumber?.isValid() && parsedPhoneNumber.country !== 'JP') {
      this.form.controls.contactAddress.controls.countryCode.patchValue(FOREIGN);
    } else {
      this.form.controls.contactAddress.controls.countryCode.patchValue('JP');
    }

    this.cdRef.detectChanges();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    if (this.form.dirty) {
      event.returnValue = true;
    }
  }
}
