import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core';
import { WINDOW } from '../helpers.js';

/** HttpContext integration collects information about HTTP request headers */
class HttpContext {
  constructor() {
    HttpContext.prototype.__init.call(this);
  }
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = 'HttpContext';
  }

  /**
   * @inheritDoc
   */
  __init() {
    this.name = HttpContext.id;
  }

  /**
   * @inheritDoc
   */
  setupOnce() {
    addGlobalEventProcessor(event => {
      if (getCurrentHub().getIntegration(HttpContext)) {
        // if none of the information we want exists, don't bother
        if (!WINDOW.navigator && !WINDOW.location && !WINDOW.document) {
          return event;
        }

        // grab as much info as exists and add it to the event
        const url = event.request && event.request.url || WINDOW.location && WINDOW.location.href;
        const {
          referrer
        } = WINDOW.document || {};
        const {
          userAgent
        } = WINDOW.navigator || {};
        const headers = {
          ...(event.request && event.request.headers),
          ...(referrer && {
            Referer: referrer
          }),
          ...(userAgent && {
            'User-Agent': userAgent
          })
        };
        const request = {
          ...event.request,
          ...(url && {
            url
          }),
          headers
        };
        return {
          ...event,
          request
        };
      }
      return event;
    });
  }
}
HttpContext.__initStatic();
export { HttpContext };
