import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';
import { mergeDeep } from '@twogate-npm/toolbox-utils';

@Component({
  selector: 'tpl-form-error',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents],
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent {
  @Input()
  errors!: ValidationErrors | null;
  @Input()
  set messages(input: { [key: string]: string | ((value: unknown) => string) }) {
    this.mergedMessages = mergeDeep(input, this.defaultMessages);
  }

  private defaultMessages = {
    required: '必須です',
    email: 'メールアドレスが正しくありません',
    maxlength: '文字数が多すぎます',
    minlength: '文字数が少なすぎます',
    pattern: '正しく入力してください',
    invalidPhoneNumber: '電話番号の形式が間違っています',
    invalidKana: 'カタカナで入力してください',
    invalidPhoneCountry: '対応していない国番号です',
  };
  private mergedMessages: { [key: string]: string | ((value: unknown) => string) } = this.defaultMessages;

  get errorMessages(): string[] {
    return Object.entries(this.errors ?? [])
      .map(([key, value]) => {
        const message = this.mergedMessages[key];
        if (!message) {
          return null;
        }
        if (typeof message === 'string') {
          return message;
        } else {
          return message(value);
        }
      })
      .filter((v): v is string => !!v);
  }
}
