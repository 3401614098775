import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { fromEvent, takeUntil } from 'rxjs';

@Directive({ selector: '[idpFormatPhoneNumber]', standalone: true })
export class FormatPhoneNumberDirective implements AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new EventEmitter<void>();

  private readonly control = inject(NgControl);
  private readonly elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef);

  ngAfterViewInit() {
    fromEvent(this.elementRef.nativeElement, 'blur')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.format();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  format() {
    if (this.control.valid) {
      if (typeof this.control.value !== 'string') return;
      const phoneNumber = parsePhoneNumberFromString(this.control.value, 'JP');
      if (phoneNumber?.isValid()) {
        const formattedPhoneNumber =
          phoneNumber.country === 'JP' ? phoneNumber.formatNational() : phoneNumber.formatInternational();
        this.control.control?.setValue(formattedPhoneNumber);
      }
    }
  }
}
