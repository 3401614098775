<div class="toast">
  @if (config.icon) {
    <div class="icon">
      <tbx-icon [name]="config.icon"></tbx-icon>
    </div>
  }
  <div class="message">
    {{ config.message }}
  </div>
  @if (config.action) {
    <div class="action">
      <button tbxTouchable (click)="close()" class="action-button">
        {{ config.action }}
      </button>
    </div>
  } @else {
    <div class="close">
      <button tbxTouchable (click)="close()" class="close-button">
        <tbx-icon class="close-button-icon" name="close"></tbx-icon>
      </button>
    </div>
  }
</div>
