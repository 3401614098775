import { Injectable, inject } from '@angular/core';

import { SentryDataService } from './features/error';

@Injectable({ providedIn: 'root' })
export class IdpService {
  private readonly sentryDataService = inject(SentryDataService);

  initialize() {
    this.sentryDataService.initialize();
  }
}
