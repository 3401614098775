import { ActionReducer, MetaReducer } from '@ngrx/store';

import { AuthActions } from '@idp/features/auth/store/auth';

export function resetState(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return (state, action) => {
    if (action.type === AuthActions.signOut.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer[] = [resetState];
