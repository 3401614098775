import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'staging',
  production: true,
  tenantId: 'karvan',

  apiOrigin: 'https://karvan.auth.app-staging.id-platform.net',

  recaptchaSiteKey: '6LevouUpAAAAABLVWV2kAniFyOs1hhlDl3cuQ_fv',
  recaptchaCheckboxSiteKey: '6LdYoeUpAAAAAJgREQkONFJVyBDqGcjvl_uAuJaE',

  groupSiteUrls: {
    ticket: '',
    idp: '',
    store: '',
  },
};

export * from './environment.type';
