<tpl-modal (close)="close()">
  <tpl-modal-header>個人情報の編集</tpl-modal-header>
  <div class="page-content" [formGroup]="form">
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <div class="form-value">{{ profile.email }}</div>
    </tpl-form-field>
    @if (verifyPhoneNumber) {
      <tpl-form-field>
        <tpl-form-label>電話番号</tpl-form-label>
        <div class="form-value">{{ profile.phoneNumber | phoneNumber }}</div>
      </tpl-form-field>
    }

    <tpl-form-group formGroupName="profile">
      <tpl-form-field>
        <tpl-form-label [required]="true">氏名</tpl-form-label>
        <div class="input-group">
          <input type="text" placeholder="姓" formControlName="lastName" />
          <div class="form-value">{{ profile.profile.firstName }}</div>
        </div>
        <tpl-form-note>邉・邊・髙・﨑などの旧字は、新字（常用漢字）で入力してください。</tpl-form-note>
        @if (profileForm.get('lastName')?.dirty || profileForm.get('lastName')?.touched) {
          <tpl-form-error [errors]="profileForm.get('lastName')?.errors ?? null"></tpl-form-error>
        }
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">フリガナ</tpl-form-label>
        <div class="input-group">
          <input type="text" placeholder="セイ" formControlName="lastNameKana" />
          <div class="form-value">{{ profile.profile.firstNameKana }}</div>
        </div>
        @if (profileForm.get('lastNameKana')?.dirty || profileForm.get('lastNameKana')?.touched) {
          <tpl-form-error [errors]="profileForm.get('lastNameKana')?.errors ?? null"></tpl-form-error>
        }
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">生年月日</tpl-form-label>
        <div class="form-value">{{ profile.profile.birthDate | date: 'yyyy年M月d日' }}</div>
      </tpl-form-field>

      <tpl-form-field>
        <tpl-form-label [required]="true">性別</tpl-form-label>
        <div class="form-value">{{ profile.profile.gender | gender }}</div>
      </tpl-form-field>
    </tpl-form-group>

    <tpl-form-group formGroupName="contactAddress">
      @if (!verifyPhoneNumber) {
        <tpl-form-field>
          <tpl-form-label [required]="true">電話番号</tpl-form-label>
          <tpl-form-phone-field
            formControlName="phoneNumber"
            [phoneCountryCodes]="phoneCountryWhitelist"
          ></tpl-form-phone-field>
          @if (contactAddressForm.get('phoneNumber')?.dirty || contactAddressForm.get('phoneNumber')?.touched) {
            <tpl-form-error [errors]="contactAddressForm.get('phoneNumber')?.errors ?? null"></tpl-form-error>
          }
        </tpl-form-field>
      }

      <tpl-form-field>
        <tpl-form-label [required]="true">国</tpl-form-label>
        <tpl-form-select>
          <select formControlName="countryCode">
            <option value="">国を選択してください</option>
            @for (country of COUNTRY_SELECT_LIST; track country.countryCode) {
              <option [ngValue]="country.countryCode">
                {{ country.text }}
              </option>
            }
          </select>
        </tpl-form-select>
        @if (contactAddressForm.get('countryCode')?.dirty || contactAddressForm.get('countryCode')?.touched) {
          <tpl-form-error [errors]="contactAddressForm.get('countryCode')?.errors ?? null"></tpl-form-error>
        }
      </tpl-form-field>

      @if (countryCodeValue === 'JP') {
        <tpl-form-field>
          <tpl-form-label [required]="true">郵便番号</tpl-form-label>
          <input
            type="text"
            formControlName="zipCode"
            placeholder="0000000"
            idpConvertZenkakuToHankaku
            autocomplete="postal-code"
          />
          @if (contactAddressForm.get('zipCode')?.dirty || contactAddressForm.get('zipCode')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('zipCode')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'zipCode') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">都道府県</tpl-form-label>
          <tpl-form-select>
            <select formControlName="prefectureCode">
              <option value="">選択してください</option>
              @for (prefecture of PREFECTURE_SELECT_LIST; track prefecture.prefectureCode) {
                <option [value]="prefecture.prefectureCode">
                  {{ prefecture.text }}
                </option>
              }
            </select>
          </tpl-form-select>
          @if (contactAddressForm.get('prefectureCode')?.dirty || contactAddressForm.get('prefectureCode')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('prefectureCode')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'prefectureCode') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">市区町村</tpl-form-label>
          <input type="text" formControlName="city" autocomplete="address-level2" />
          @if (contactAddressForm.get('city')?.dirty || contactAddressForm.get('city')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('city')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'city') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label [required]="true">住所１ 番地まで</tpl-form-label>
          <input type="text" formControlName="street" autocomplete="address-level3" />
          @if (contactAddressForm.get('street')?.dirty || contactAddressForm.get('street')?.touched) {
            <tpl-form-error
              [errors]="
                contactAddressForm.get('street')?.errors ??
                (contactAddressForm.errors | addressFieldErrors: 'street') ??
                null
              "
              [messages]="errorMessages"
            ></tpl-form-error>
          }
        </tpl-form-field>

        <tpl-form-field>
          <tpl-form-label>住所２ 建物名・部屋番号等</tpl-form-label>
          <input type="text" formControlName="building" autocomplete="address-level4" />
          <tpl-form-note
            >部屋番号まで入力してください。世帯主の方と姓が異なる場合は、○○様方を入力してください。</tpl-form-note
          >
          @if (contactAddressForm.get('building')?.dirty || contactAddressForm.get('building')?.touched) {
            <tpl-form-error [errors]="contactAddressForm.get('building')?.errors ?? null"></tpl-form-error>
          }
        </tpl-form-field>
      }
    </tpl-form-group>
  </div>
  <tpl-modal-footer>
    <div class="modal-action" slot="end">
      <button tpl-button class="type-stroked" color="basic-light" size="small" width="auto" (click)="close()">
        キャンセル
      </button>
      <button
        tpl-button
        color="accent"
        size="small"
        width="auto"
        icon="arrow-right"
        (click)="submit()"
        [disabled]="form.invalid"
      >
        保存する
      </button>
    </div>
  </tpl-modal-footer>
</tpl-modal>
