<div class="form">
  <div class="birth-date-form" [formGroup]="birthDateForm" (click)="focus()" [class.focused]="focused">
    <input
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      maxlength="4"
      placeholder="1900"
      autocomplete="bday-year"
      class="birth-date-input year"
      formControlName="year"
      #yearRef
      (keydown)="keyPressInYearForm($event)"
      (focus)="focusForm()"
      (blur)="blurForm('year')"
      (click)="clickForm($event)"
    />
    <div class="divider">/</div>
    <input
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      maxlength="2"
      placeholder="01"
      autocomplete="bday-month"
      class="birth-date-input month"
      formControlName="month"
      #monthRef
      (keydown)="keyPressInMonthForm($event)"
      (focus)="focusForm()"
      (blur)="blurForm('month')"
      (click)="clickForm($event)"
    />
    <div class="divider">/</div>
    <input
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      maxlength="2"
      placeholder="01"
      autocomplete="bday-day"
      class="birth-date-input day"
      formControlName="day"
      #dayRef
      (keydown)="keyPressInDayForm($event)"
      (focus)="focusForm()"
      (blur)="blurForm('day')"
      (click)="clickForm($event)"
    />
  </div>

  @if (birthDateForm.dirty || birthDateForm.touched) {
    <tpl-form-error
      [errors]="birthDateForm.errors ?? null"
      [messages]="{ invalidDate: '正しい日付ではありません' }"
    ></tpl-form-error>
  }
</div>
