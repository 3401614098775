import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'tpl-form-select',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectComponent {}
