import { Injectable, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import parsePhoneNumberFromString, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

import { IdpConfigToken } from '@idp/features/config';

import { AddressForm, ProfileForm } from '../models/profile-form.type';
import { ContactAddress } from '../models/profile.type';

@Injectable({ providedIn: 'root' })
export class ProfileFormService {
  readonly addressErrorMessages = {
    'zip-code-required': '必須です',
    'prefecture-code-required': '必須です',
    'city-required': '必須です',
    'street-required': '必須です',
  };
  private readonly formBuilder = inject(FormBuilder);
  private readonly phoneCountryWhitelist = inject(IdpConfigToken).phoneCountryWhitelist ?? ['JP'];
  private readonly nameRegExp = /^[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}ー々a-zA-Z]+$/u;

  getProfileForm(): ProfileForm {
    return this.formBuilder.group({
      firstName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.maxLength(50), Validators.required, Validators.pattern(this.nameRegExp)],
      }),
      lastName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.maxLength(50), Validators.required, Validators.pattern(this.nameRegExp)],
      }),
      firstNameKana: new FormControl('', {
        nonNullable: true,
        validators: [this.kanaValidator, Validators.required],
      }),
      lastNameKana: new FormControl('', {
        nonNullable: true,
        validators: [this.kanaValidator, Validators.required],
      }),
      birthDate: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      gender: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    });
  }

  getAddressForm(options?: { phoneNumberOptional?: boolean }): AddressForm {
    return this.formBuilder.group(
      {
        countryCode: new FormControl<string | null>('', { nonNullable: true, validators: [this.emptyStringValidator] }),
        zipCode: new FormControl('', {
          nonNullable: true,
          validators: [Validators.pattern(/^\d{3}-?\d{4}$/)],
        }),
        prefectureCode: new FormControl('', { nonNullable: true }),
        city: new FormControl('', {
          nonNullable: true,
          validators: [Validators.maxLength(60)],
        }),
        street: new FormControl('', {
          nonNullable: true,
          validators: [Validators.maxLength(60)],
        }),
        building: new FormControl('', { nonNullable: true, validators: [Validators.maxLength(60)] }),
        phoneNumber: this.getPhoneNumberForm({ optional: options?.phoneNumberOptional }),
      },
      {
        validators: [
          this.addressRequiredValidator('zipCode'),
          this.addressRequiredValidator('prefectureCode'),
          this.addressRequiredValidator('city'),
          this.addressRequiredValidator('street'),
        ],
      },
    );
  }

  getPhoneNumberForm(options?: { restrictCountry?: boolean; optional?: boolean }): FormControl<string> {
    return new FormControl('', {
      nonNullable: true,
      validators: [
        this.phoneNumberValidator,
        ...(options?.restrictCountry ? [this.restrictCountryValidator(this.phoneCountryWhitelist)] : []),
        ...(options?.optional ? [] : [Validators.required]),
      ],
    });
  }

  phoneNumberValidator(control: AbstractControl<string | null>): ValidationErrors | null {
    if (!control.value) return null;
    return isValidPhoneNumber(control.value, 'JP') ? null : { invalidPhoneNumber: true };
  }

  restrictCountryValidator(phoneCountryWhitelist: CountryCode[]) {
    return (control: AbstractControl<string | null>): ValidationErrors | null => {
      if (control.value == null) return { invalidPhoneNumber: true };
      const phoneNumber = parsePhoneNumberFromString(control.value, 'JP');
      if (!phoneNumber?.isValid() || !phoneNumber?.country) return { invalidPhoneNumber: true };
      return phoneCountryWhitelist?.includes(phoneNumber?.country) ? null : { invalidPhoneCountry: true };
    };
  }

  kanaValidator(control: AbstractControl<string>): ValidationErrors | null {
    return /^([ァ-ヴ][ァ-ヴー・\s]*)$/.test(control.value) ? null : { invalidKana: true };
  }

  addressRequiredValidator(name: 'zipCode' | 'prefectureCode' | 'city' | 'street'): ValidatorFn {
    return (control: AbstractControl<ContactAddress>): ValidationErrors | null => {
      const countryCode = control.value.countryCode;
      const value = control.value[name];
      const key = `${name.replace(/([A-Z])/g, '-$1').toLowerCase()}-required`;

      if (countryCode === 'JP') {
        return value ? null : { [key]: true };
      } else {
        return null;
      }
    };
  }

  emptyStringValidator(control: AbstractControl<string>): ValidationErrors | null {
    if (control.value === '') {
      return { required: true };
    } else {
      return null;
    }
  }
}
