@if (toastService.allToasts$ | async; as toasts) {
  <div class="toast-container top left">
    @for (toast of toasts.topLeft; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>
  <div class="toast-container top center">
    @for (toast of toasts.topCenter; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>
  <div class="toast-container top right">
    @for (toast of toasts.topRight; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>

  <div class="toast-container bottom left">
    @for (toast of toasts.bottomLeft; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>
  <div class="toast-container bottom center">
    @for (toast of toasts.bottomCenter; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>
  <div class="toast-container bottom right">
    @for (toast of toasts.bottomRight; track toast.id) {
      <tpl-toast [toast]="toast"></tpl-toast>
    }
  </div>
}
