import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface TripleErrorResponse {
  error?: {
    code: string;
    message?: string;
    params?: {
      messages?: { [key: string]: string[] };
    };
  };
}

export interface TripleValidationErrorResponse {
  error: {
    code: 'validation_error';
    params: {
      details: { [key: string]: ({ error: string } & { [key: string]: string })[] };
      messages?: { [key: string]: string[] };
    };
  };
}

export function getMessageFromMessages(messages: { [key: string]: string[] }) {
  return Object.values(messages)
    .reduce((previous, current) => previous.concat(...current), [])
    .join('<br>');
}

@Injectable({ providedIn: 'root' })
export class ApiErrorResponseService {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isValidationError(error: any): error is TripleValidationErrorResponse {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return error?.error?.code === 'validation_error' && Boolean(error?.error?.params);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isTripleError(error: any): error is TripleErrorResponse {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return error?.error?.code !== 'validation_error' && Boolean(error?.error?.message);
  }

  getErrorMessageFromResponse(error: HttpErrorResponse): string | null {
    if (this.isValidationError(error.error)) {
      const messages = error.error.error.params.messages;
      if (messages) {
        return getMessageFromMessages(messages);
      }
    } else if (this.isTripleError(error.error)) {
      if (error.error.error?.params?.messages) {
        return getMessageFromMessages(error.error.error.params.messages);
      } else if (error.error.error?.message) {
        return error.error.error.message;
      }
    }

    return null;
  }
}
