import { importProvidersFrom } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage-angular';

export function provideTriadStorage() {
  return [
    importProvidersFrom([
      IonicStorageModule.forRoot({
        name: '__triad_db',
      }),
    ]),
  ];
}
