import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AlertService,
  LoaderService,
  ReCaptchaModalService,
  ReCaptchaType,
  ToastService,
  TriadConfigToken,
} from '@triple/triad';
import { nonNullable } from '@twogate-npm/toolbox-angular';
import { firstValueFrom, map } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { UserService } from '@idp/features/user';
import { UserActions, selectCurrentUser } from '@idp/features/user/store/user';

import { AuthActions, selectIsOAuthFlow, selectRequireSmsMfa } from '../store/auth';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthUsecaseService {
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly config = inject(TriadConfigToken);
  private readonly verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;
  private readonly authService = inject(AuthService);
  private readonly alertService = inject(AlertService);
  private readonly loaderService = inject(LoaderService);
  private readonly toastService = inject(ToastService);
  private readonly recaptchaModalService = inject(ReCaptchaModalService);
  private readonly userService = inject(UserService);

  async sendVerificationEmail(
    email: string,
    { captchaToken, captchaType }: { captchaToken: string; captchaType: ReCaptchaType },
  ): Promise<boolean> {
    const loader = this.loaderService.showLoader({ message: 'メールを送信しています' });
    const result = await this.authService.sendVerificationEmail(email, { captchaToken, captchaType });
    loader.close();

    if (result.isFailure()) {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        result.error.originalErrorResponse.error?.error?.code === 'captcha_score_too_low' &&
        this.config.recaptchaCheckboxSiteKey
      ) {
        const token = await this.recaptchaModalService.openReCaptchaModal({
          siteKey: this.config.recaptchaCheckboxSiteKey,
          action: 'signup',
          message: `ロボットの疑いがあるため、登録を受け付けることができませんでした。<br />ご不便をおかけして申し訳ございませんが、以下のチェックボックスをクリックしてお申込みを続行してください。`,
        });
        if (token) {
          return this.sendVerificationEmail(email, { captchaToken: token, captchaType: 'checkbox' });
        }
        return true;
      }
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.alertService.showAlert({
      header: '送信完了',
      message: '確認メールを送信しました。メールをご確認ください',
      buttons: [
        {
          text: 'OK',
          color: 'basic',
        },
      ],
    });

    this.router.navigate(['/auth', 'verify_email'], { queryParams: { userId: result.value.userId, email } });
    return true;
  }

  async verifyEmail(userId: string, code: string, email: string) {
    const loader = this.loaderService.showLoader({ message: 'メールを確認しています' });
    const result = await this.authService.verifyEmail(userId, code);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    if (result.value.registered) {
      this.toastService.open({
        message: '既に登録されているアカウントです',
        duration: 5000,
      });
      await this.router.navigate(['/auth/sign_in']);
      return true;
    }

    this.router.navigate(['/auth/register_password'], { queryParamsHandling: 'merge' });
    return true;
  }

  async signIn(email: string, password: string) {
    const loader = this.loaderService.showLoader({ message: 'ログインしています' });
    const result = await this.authService.signIn(email, password);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    const requiredSmsMfa = await firstValueFrom(this.store.select(selectRequireSmsMfa));
    if (requiredSmsMfa && !result.value.suppressSmsVerification) {
      this.router.navigate(['/auth/verify_phone/mfa-send']);
    } else {
      await this.navigateAfterAuthSuccess();
    }
    return true;
  }

  async setPassword(password: string) {
    const loader = this.loaderService.showLoader({ message: 'パスワードを設定しています' });
    const result = await this.authService.setPassword(password);
    if (result.isFailure()) {
      loader.close();
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    const user = await firstValueFrom(this.userService.fetchCurrentUser()).catch(() => null);
    loader.close();
    if (user) {
      this.toastService.open({
        message: 'パスワードを設定しました',
        duration: 5000,
      });

      return true;
    }

    return true;
  }

  async updatePassword(password: string) {
    const loader = this.loaderService.showLoader({ message: 'パスワードを更新しています' });
    const result = await this.authService.updatePassword(password);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.toastService.open({
      message: 'パスワードを更新しました',
      duration: 5000,
    });

    return true;
  }

  async resetPassword(email: string) {
    const loader = this.loaderService.showLoader({ message: '送信中...' });
    const result = await this.authService.resetPassword(email);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.alertService.showAlert({
      header: '送信完了',
      message:
        'パスワードリセットメールを送信しました。メールをご確認ください。 <br> <br>メールが届かない場合は、入力の誤りがあるか、ご登録されていないメールアドレスの可能性があります。',
      buttons: [
        {
          text: 'OK',
          color: 'basic',
        },
      ],
    });

    return true;
  }

  async resetPasswordWithCode(password: string, passwordResetCode: string) {
    const loader = this.loaderService.showLoader({ message: '送信中...' });
    const result = await this.authService.resetPasswordWithCode(password, passwordResetCode);
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.toastService.open({ message: 'パスワードのリセットが完了しました', duration: 5000 });
    return true;
  }

  async signOut() {
    const confirm = await this.alertService.showConfirmation({
      message: 'ログアウトしますか？',
      buttonText: 'ログアウト',
    });

    if (!confirm) {
      return false;
    }

    const loader = this.loaderService.showLoader({ message: 'ログアウトしています' });
    const result = await this.authService.signOut();
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.router.navigate(['/auth', 'sign_in']);
    this.toastService.open({ message: 'ログアウトしました', duration: 5000 });
    return true;
  }

  async navigateAfterAuthSuccess() {
    const { enabled, smsVerified } = await firstValueFrom(
      this.store.select(selectCurrentUser).pipe(
        map((v) => v),
        nonNullable(),
      ),
    );

    if (enabled) {
      const isOAuthFlow = await firstValueFrom(this.store.select(selectIsOAuthFlow));
      if (isOAuthFlow) {
        this.authService.relaunch();
      } else {
        this.router.navigate(['/']);
      }
    } else {
      if (this.verifyPhoneNumber && !smsVerified) {
        this.router.navigate(['/auth/verify_phone/register'], { queryParamsHandling: 'merge' });
      } else {
        this.router.navigate(['/registration', 'profile']);
      }
    }
  }

  async unregister() {
    const confirm = await this.alertService.showConfirmation({
      message: 'アカウントを削除しますか？',
      buttonText: '削除',
    });

    if (!confirm) {
      return false;
    }

    const loader = this.loaderService.showLoader({ message: 'アカウントを削除しています' });
    const result = await this.userService.deleteCurrentUser();
    loader.close();
    if (result.isFailure()) {
      this.alertService.showErrorAlertFromResponse(result.error.originalErrorResponse, result.error.message);
      return false;
    }

    this.router.navigateByUrl('/');
    this.store.dispatch(AuthActions.signOut());
    this.store.dispatch(UserActions.setCurrentUser({ user: null }));
    this.toastService.open({ message: 'アカウントを削除しました', duration: 5000 });
    return true;
  }
}
