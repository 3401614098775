import { Pipe, PipeTransform } from '@angular/core';

import { COUNTRY_SELECT_LIST } from '../../libs/options/country';

@Pipe({ name: 'country', standalone: true })
export class CountryPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    const country = COUNTRY_SELECT_LIST.find((countryItem) => countryItem.countryCode === value);
    if (!country) return '';

    return country.text;
  }
}
