import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ButtonComponent,
  FormComponents,
  BreadcrumbComponent,
  BreadcrumbItemComponent,
  PressEnterDirective,
} from '@triple/triad';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { AuthUsecaseService } from '../../services/auth-usecase.service';
import { AuthActions } from '../../store/auth';

@Component({
  selector: 'page-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    TbxCommonComponents,
    ButtonComponent,
    FormComponents,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpPageContainerComponent,
    IdpBoxComponent,
    PressEnterDirective,
  ],
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
})
export class SignInPage implements OnInit {
  @Input('in_oauth_flow')
  isOAuthFlow = false;
  @Input('require_sms_mfa')
  requireSmsMfa: boolean = false;

  form = new FormGroup({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.minLength(6)] }),
  });

  private readonly store = inject(Store);
  private readonly authUsecase = inject(AuthUsecaseService);

  ngOnInit() {
    if (this.isOAuthFlow) {
      this.store.dispatch(AuthActions.setIsOAuthFlow({ isOAuthFlow: true }));
    }
    if (this.requireSmsMfa) {
      this.store.dispatch(AuthActions.setRequireSmsMfa({ requireSmsMfa: true }));
    }
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }

    const { email, password } = this.form.getRawValue();
    await this.authUsecase.signIn(email, password);
  }
}
