import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
  PressEnterDirective,
  BreadcrumbComponent,
  BreadcrumbItemComponent,
  ButtonComponent,
  FormComponents,
} from '@triple/triad';

import { SubHeaderComponent } from '@idp/features/layout/components/sub-header/sub-header.component';
import { IdpLocalePipe } from '@idp/features/locale';
import { IdpBoxComponent } from '@idp/shared/components/box/box.component';
import { IdpPageContainerComponent } from '@idp/shared/components/page-container/page-container.component';

import { PasswordFormComponent } from '../../components/password-form/password-form.component';
import { PasswordPatternErrorComponent } from '../../components/password-pattern-error/password-pattern-error.component';
import { AuthFormService } from '../../services/auth-form.service';
import { AuthUsecaseService } from '../../services/auth-usecase.service';

@Component({
  selector: 'page-update-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    FormComponents,
    SubHeaderComponent,
    PasswordFormComponent,
    PasswordPatternErrorComponent,
    IdpPageContainerComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    IdpBoxComponent,
    RouterLink,
    PressEnterDirective,
    IdpLocalePipe,
  ],
  templateUrl: './update-password.page.html',
  styleUrls: ['./update-password.page.scss'],
})
export class UpdatePasswordPage {
  passwordForm;

  private readonly authUsecase = inject(AuthUsecaseService);
  private readonly authFormService = inject(AuthFormService);

  constructor() {
    this.passwordForm = new FormControl('', {
      nonNullable: true,
      validators: [...this.authFormService.passwordValidators()],
    });
  }

  async submit() {
    if (this.passwordForm.invalid) {
      return;
    }

    await this.authUsecase.updatePassword(this.passwordForm.value);
  }
}
