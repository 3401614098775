import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@twogate-npm/toolbox-angular';

// _color は色がついているもの (色変更不可)
export type SnsLogoName =
  | 'facebook_color'
  | 'facebook'
  | 'instagram_color'
  | 'instagram'
  | 'line_color'
  | 'line'
  | 'tiktok_color'
  | 'tiktok'
  | 'twitch_color'
  | 'twitch'
  | 'twitter_color'
  | 'twitter'
  | 'x_color'
  | 'x'
  | 'youtube_color'
  | 'youtube';

@Component({
  selector: 'tpl-sns-logo',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './sns-logo.component.html',
  styleUrl: './sns-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnsLogoComponent {
  @Input()
  name!: SnsLogoName;

  @Input()
  link!: string;

  get iconName() {
    return `brand/${this.name}`;
  }
}
