import { AfterViewInit, Directive, EventEmitter, OnDestroy, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs';

// カタカナは未対応
export const zenkakuToHankaku = (str: string) => {
  return str.replace(/[！-～]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

@Directive({ selector: '[idpConvertZenkakuToHankaku]', standalone: true })
export class ConvertZenkakuDirective implements AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new EventEmitter<void>();
  private readonly control = inject(NgControl);

  ngAfterViewInit() {
    this.control.control?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
      if (typeof val !== 'string') return;
      this.control.control?.setValue(zenkakuToHankaku(val), { emitEvent: false });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }
}
