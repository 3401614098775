import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TbxCommonComponents } from '@twogate-npm/toolbox-angular';

@Component({
  selector: 'tpl-modal',
  standalone: true,
  imports: [CommonModule, TbxCommonComponents],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Output()
  close = new EventEmitter();

  @ViewChild('modalContent', { static: true })
  modalContent?: ElementRef<HTMLElement>;

  scrollToTop(options?: ScrollToOptions) {
    this.modalContent?.nativeElement?.scrollTo(
      options
        ? options
        : {
            top: 0,
            behavior: 'smooth',
          },
    );
  }
}
