export type ToastVerticalPosition = 'top' | 'bottom';
export type ToastHorizontalPosition = 'left' | 'right' | 'center';
export type ToastPosition = `${ToastVerticalPosition}${Capitalize<ToastHorizontalPosition>}`;

export interface ToastConfigInput extends Partial<ToastConfig> {
  message: string;
}
export interface ToastConfig {
  message: string;
  verticalPosition: ToastVerticalPosition;
  horizontalPosition: ToastHorizontalPosition;
  color: 'primary' | 'accent' | 'error' | 'default';

  icon?: string;
  action?: string;
  duration?: number;
  dedupe?: boolean;
}

export const defaultToastConfig: Pick<ToastConfig, 'verticalPosition' | 'horizontalPosition' | 'color' | 'dedupe'> = {
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  color: 'default',
  dedupe: true,
};

export interface Toast {
  id: string;
  config: ToastConfig;
}
