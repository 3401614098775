import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { User } from '@idp/features/user/models/user.type';

@Injectable({ providedIn: 'root' })
export class EmailChangeApi {
  private readonly baseUrl = '/v1/internal';
  private readonly http = inject(HttpClient);

  sendVerificationEmail(email: string) {
    return this.http.post<void>(`${this.baseUrl}/email_change/request`, {
      email,
    });
  }

  verifyEmail(code: string) {
    return this.http.post<User>(`${this.baseUrl}/email_change`, {
      emailVerificationCode: code,
    });
  }
}
