import { _optionalChain } from '@sentry/utils/esm/buildPolyfills';
import { logger, isThenable } from '@sentry/utils';
import { shouldDisableAutoInstrumentation } from './utils/node-utils.js';
function isValidPrismaClient(possibleClient) {
  return possibleClient && !!possibleClient['$use'];
}

/** Tracing integration for @prisma/client package */
class Prisma {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = 'Prisma';
  }

  /**
   * @inheritDoc
   */
  __init() {
    this.name = Prisma.id;
  }

  /**
   * Prisma ORM Client Instance
   */

  /**
   * @inheritDoc
   */
  constructor(options = {}) {
    Prisma.prototype.__init.call(this);
    if (isValidPrismaClient(options.client)) {
      this._client = options.client;
    } else {
      (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && logger.warn(`Unsupported Prisma client provided to PrismaIntegration. Provided client: ${JSON.stringify(options.client)}`);
    }
  }

  /**
   * @inheritDoc
   */
  setupOnce(_, getCurrentHub) {
    if (!this._client) {
      (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && logger.error('PrismaIntegration is missing a Prisma Client Instance');
      return;
    }
    if (shouldDisableAutoInstrumentation(getCurrentHub)) {
      (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && logger.log('Prisma Integration is skipped because of instrumenter configuration.');
      return;
    }
    this._client.$use((params, next) => {
      const scope = getCurrentHub().getScope();
      const parentSpan = _optionalChain([scope, 'optionalAccess', _2 => _2.getSpan, 'call', _3 => _3()]);
      const action = params.action;
      const model = params.model;
      const span = _optionalChain([parentSpan, 'optionalAccess', _4 => _4.startChild, 'call', _5 => _5({
        description: model ? `${model} ${action}` : action,
        op: 'db.sql.prisma'
      })]);
      const rv = next(params);
      if (isThenable(rv)) {
        return rv.then(res => {
          _optionalChain([span, 'optionalAccess', _6 => _6.finish, 'call', _7 => _7()]);
          return res;
        });
      }
      _optionalChain([span, 'optionalAccess', _8 => _8.finish, 'call', _9 => _9()]);
      return rv;
    });
  }
}
Prisma.__initStatic();
export { Prisma };
