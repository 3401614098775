import { TriadAssets, TriadConfig } from '@triple/triad';
import { versions } from '@version';

import { environment } from '@environment';

export const triadConfig: TriadConfig = {
  type: 'idp',
  environment: environment.name,
  production: environment.production,
  tenantId: environment.tenantId,

  recaptchaSiteKey: environment.recaptchaSiteKey,
  recaptchaCheckboxSiteKey: environment.recaptchaCheckboxSiteKey,
  kenallApiPublicKey: 'c3f1641cf78bdf0ed7896ec44fcc874e52c65084',

  versions,
};

export const triadAssets: TriadAssets = {
  logoImage: {
    src: 'assets/images/logo.svg',
    originalWidth: 200,
    originalHeight: 50,
  },
  inSiteLinks: [
    {
      label: 'ホーム',
      href: '/',
      type: 'internal',
      authRequired: false,
    },
    {
      label: 'アカウント情報',
      href: '/mypage',
      type: 'internal',
      authRequired: true,
    },
  ],
  siteLinks: [
    {
      label: 'LIVE TICKET',
      href: environment.groupSiteUrls.ticket,
      theme: 'primary',
      type: 'external',
    },
    {
      label: 'STORE',
      href: environment.groupSiteUrls.store,
      theme: 'store',
      type: 'external',
    },
  ],
  footerLinks: [
    {
      label: 'はじめての方へ',
      href: 'pages/about',
      type: 'internal',
    },
    {
      label: 'ご利用ガイド',
      href: 'pages/guide',
      type: 'internal',
    },
    {
      label: 'よくある質問',
      href: 'pages/faq',
      type: 'internal',
    },
    {
      label: '利用規約',
      href: 'pages/terms',
      type: 'internal',
    },
    {
      label: 'プライバシーポリシー',
      href: 'pages/privacy',
      type: 'internal',
    },
  ],
};
