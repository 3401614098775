import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AlertComponent } from './alert.component';
import { AlertConfig, AlertResult, ConfirmationConfig } from './alert.type';
import { ApiErrorResponseService, getMessageFromMessages } from './api-error-response.service';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private readonly defaultConfirmationConfig: Required<ConfirmationConfig> = {
    header: '確認',
    message: '',
    buttonText: 'OK',
    buttonColor: 'basic',
    cancelButtonText: 'キャンセル',
    cancelButtonColor: 'basic',
  };
  private readonly dialog = inject(Dialog);
  private readonly apiErrorResponseService = inject(ApiErrorResponseService);

  showAlert(config: AlertConfig) {
    const dialogRef = this.dialog.open<AlertResult, AlertConfig, AlertComponent>(AlertComponent, {
      width: '100%',
      maxWidth: '280px',
      data: config,
      disableClose: true,
    });
    return dialogRef;
  }

  async showConfirmation(config: ConfirmationConfig): Promise<boolean> {
    const dialogRef = this.dialog.open<AlertResult>(AlertComponent, {
      width: '100%',
      maxWidth: '280px',
      data: {
        header: config.header ?? this.defaultConfirmationConfig.header,
        message: config.message,
        buttons: [
          {
            text: config.cancelButtonText ?? this.defaultConfirmationConfig.cancelButtonText,
            color: config.cancelButtonColor ?? this.defaultConfirmationConfig.cancelButtonColor,
            role: 'cancel',
          },
          {
            text: config.buttonText ?? this.defaultConfirmationConfig.buttonText,
            color: config.buttonColor ?? this.defaultConfirmationConfig.buttonColor,
            role: 'confirm',
          },
        ],
      },
      disableClose: true,
    });
    const resp = await firstValueFrom(dialogRef.closed);
    if (resp) {
      return resp.role === 'confirm';
    } else {
      return false;
    }
  }

  async showSimpleErrorAlert(options: Partial<AlertConfig> & { message: string }, waitClose: boolean = false) {
    const dialogRef = this.showAlert({
      header: options.header ?? 'エラー',
      message: options.message,
      buttons: [
        {
          text: '閉じる',
          role: 'confirm',
        },
      ],
    });
    if (waitClose) {
      await firstValueFrom(dialogRef.closed);
    }
    return dialogRef;
  }

  async showErrorAlertFromResponse(error: HttpErrorResponse, defaultMessage = 'エラーが発生しました') {
    if (error.status === 0) {
      return this.showSimpleErrorAlert({ message: '通信に失敗しました' });
    }
    if (this.apiErrorResponseService.isValidationError(error.error)) {
      const messages = error.error.error.params.messages;
      const message = messages ? getMessageFromMessages(messages) : defaultMessage;
      return this.showSimpleErrorAlert({ message });
    } else if (this.apiErrorResponseService.isTripleError(error.error)) {
      if (error.error.error?.params?.messages) {
        const message = getMessageFromMessages(error.error.error.params.messages);
        return this.showSimpleErrorAlert({ message });
      } else {
        return this.showSimpleErrorAlert({ message: error.error.error?.message ?? defaultMessage });
      }
    } else {
      return this.showSimpleErrorAlert({ message: defaultMessage });
    }
  }
}
