import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  FormComponents,
  KEN_ALL_PREFECTURE_CODE,
  KenAllService,
  ModalComponents,
  RadioGroupComponent,
  SimpleRadioButtonComponent,
} from '@triple/triad';
import { TbxCommonComponents, nonNullable } from '@twogate-npm/toolbox-angular';
import { firstValueFrom, takeUntil } from 'rxjs';

import { IdpConfigToken } from '@idp/features/config';
import { UserService } from '@idp/features/user';
import { GenderPipe } from '@idp/shared';
import { FormatPhoneNumberDirective } from '@idp/shared/directives/format-phone-number.directive';
import { ConvertZenkakuDirective } from '@idp/shared/directives/format-zenkaku.directive';
import { COUNTRY_SELECT_LIST } from '@idp/shared/libs/options/country';
import { PREFECTURE_SELECT_LIST } from '@idp/shared/libs/options/prefecture';
import { PhoneNumberPipe } from '@idp/shared/pipes/phone-number/phone-number.pipe';

import { AddressForm, ProfileForm } from '../../models/profile-form.type';
import { InputProfile, Profile } from '../../models/profile.type';
import { AddressFieldErrorsPipe } from '../../pipes/address-field-errors.pipe';
import { ProfileFormService } from '../../services/profile-form.service';
import { ProfileUsecaseService } from '../../services/profile-usecase.service';

const FOREIGN_ADDRESS_MASK: Pick<
  InputProfile['contactAddress'],
  'zipCode' | 'prefectureCode' | 'city' | 'street' | 'building'
> = {
  zipCode: '',
  prefectureCode: '99',
  city: '',
  street: '',
  building: '',
};

@Component({
  selector: 'idp-profile-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponents,
    FormComponents,
    TbxCommonComponents,
    ReactiveFormsModule,
    ButtonComponent,
    SimpleRadioButtonComponent,
    RadioGroupComponent,
    GenderPipe,
    FormatPhoneNumberDirective,
    ConvertZenkakuDirective,
    PhoneNumberPipe,
    AddressFieldErrorsPipe,
  ],
  templateUrl: './profile-edit-modal.component.html',
  styleUrls: ['./profile-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEditModalComponent implements OnDestroy {
  PREFECTURE_SELECT_LIST = PREFECTURE_SELECT_LIST;
  COUNTRY_SELECT_LIST = COUNTRY_SELECT_LIST;

  form: FormGroup<{
    profile: ProfileForm;
    contactAddress: AddressForm;
  }>;

  get profileForm() {
    return this.form.controls.profile;
  }
  get contactAddressForm() {
    return this.form.controls.contactAddress;
  }
  get countryCodeValue() {
    return this.form.value.contactAddress?.countryCode;
  }

  readonly profile = inject<{ profile: Profile }>(DIALOG_DATA).profile;
  readonly verifyPhoneNumber = inject(IdpConfigToken).verifyPhoneNumber ?? false;
  readonly phoneCountryWhitelist = inject(IdpConfigToken).phoneCountryWhitelist ?? ['JP'];
  readonly errorMessages = inject(ProfileFormService).addressErrorMessages;
  private readonly profileUsecase = inject(ProfileUsecaseService);
  private readonly kenAllService = inject(KenAllService);
  private readonly dialogRef = inject(DialogRef<ProfileEditModalComponent>);
  private readonly profileFormService = inject(ProfileFormService);
  private readonly userService = inject(UserService);
  private readonly onDestroy$ = new EventEmitter();

  constructor() {
    this.form = new FormGroup({
      profile: this.profileFormService.getProfileForm(),
      contactAddress: this.profileFormService.getAddressForm({ phoneNumberOptional: this.verifyPhoneNumber }),
    });

    this.form.patchValue({
      profile: {
        firstName: this.profile.profile.firstName,
        lastName: this.profile.profile.lastName,
        firstNameKana: this.profile.profile.firstNameKana,
        lastNameKana: this.profile.profile.lastNameKana,
        birthDate: this.profile.profile.birthDate,
        gender: this.profile.profile.gender,
      },
      contactAddress: {
        countryCode: this.profile.contactAddress.countryCode,
        zipCode: this.profile.contactAddress.zipCode,
        prefectureCode: this.profile.contactAddress.prefectureCode,
        city: this.profile.contactAddress.city,
        street: this.profile.contactAddress.street,
        building: this.profile.contactAddress.building,
        phoneNumber: this.profile.contactAddress.phoneNumber,
      },
    });

    this.contactAddressForm.controls.zipCode.valueChanges
      .pipe(nonNullable(), this.kenAllService.completeAddress(), takeUntil(this.onDestroy$))
      .subscribe((addresses) => {
        if (addresses && addresses.length > 0) {
          const address = addresses[0];
          this.form.patchValue({
            contactAddress: {
              prefectureCode: KEN_ALL_PREFECTURE_CODE[address.prefecture],
              city: address.city,
              street: address.town,
            },
          });
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }

  async submit() {
    if (!this.form.valid) {
      return;
    }

    const { profile, contactAddress } = this.form.getRawValue();
    const profileAttribute = {
      profile: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        firstNameKana: profile.firstNameKana,
        lastNameKana: profile.lastNameKana,
        birthDate: profile.birthDate as `${number}-${number}-${number}`,
        gender: profile.gender,
      },
      contactAddress: {
        phoneNumber: contactAddress.phoneNumber,
        countryCode: contactAddress.countryCode,
        zipCode: contactAddress.zipCode,
        prefectureCode: contactAddress.prefectureCode,
        city: contactAddress.city,
        street: contactAddress.street,
        building: contactAddress.building,
        ...(contactAddress.countryCode === 'JP' ? {} : FOREIGN_ADDRESS_MASK),
      },
    };
    await this.updateProfile(profileAttribute);
    await firstValueFrom(this.userService.fetchCurrentUser()).catch(() => {});
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  private updateProfile(profile: InputProfile) {
    return this.profileUsecase.updateProfile(profile);
  }
}
