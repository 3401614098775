<label class="form-label">
  <span class="inner-text">
    <ng-content></ng-content>
  </span>

  <div class="validate-text">
    @if (required) {
      <span class="validate-text-item required-text">必須</span>
    }
  </div>
</label>
