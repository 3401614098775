import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ReCaptchaType } from '@triple/triad';

import { User } from '@idp/features/user/models/user.type';

@Injectable({ providedIn: 'root' })
export class AuthApi {
  private readonly baseUrl = '/v1/authentication';
  private readonly http = inject(HttpClient);

  signIn(email: string, password: string) {
    return this.http.post<User>(`${this.baseUrl}/sessions`, { email, password });
  }

  sendVerificationEmail(
    email: string,
    {
      captchaToken,
      captchaType,
    }: {
      captchaToken: string;
      captchaType: ReCaptchaType;
    },
  ) {
    return this.http.post<{ userId: string }>(`${this.baseUrl}/registrations/send_verification_email`, {
      email,
      captchaToken,
      captchaType,
    });
  }

  verifyEmail(userId: string, code: string) {
    return this.http.post<User & { registered: boolean }>(`${this.baseUrl}/registrations/verify_email`, {
      emailVerificationCode: code,
      userId,
    });
  }

  resetPassword(email: string) {
    return this.http.post(`${this.baseUrl}/password_resets/request`, {
      email,
    });
  }

  resetPasswordWithCode(password: string, code: string) {
    return this.http.post(`${this.baseUrl}/password_resets`, {
      password,
      passwordResetCode: code,
    });
  }

  setPassword(password: string) {
    return this.http.post(`${this.baseUrl}/passwords`, {
      user: {
        password,
      },
    });
  }

  updatePassword(password: string) {
    return this.http.put(`${this.baseUrl}/passwords`, {
      user: {
        password,
      },
    });
  }

  signOut() {
    return this.http.post(`${this.baseUrl}/logout`, {});
  }
}
