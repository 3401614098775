import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class AuthFormService {
  constructor() {}

  passwordValidators(): ValidatorFn[] {
    return [
      Validators.maxLength(100),
      Validators.minLength(8),
      Validators.required,
      this.includeLowerCaseValidator(),
      this.includeUpperCaseValidator(),
      this.includeNumberValidator(),
    ];
  }

  private includeLowerCaseValidator(): ValidatorFn {
    return (control: AbstractControl<string>) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /(?=.*?[a-z])/.test(value) ? null : { includeLowerCase: true };
    };
  }

  private includeUpperCaseValidator(): ValidatorFn {
    return (control: AbstractControl<string>) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /(?=.*?[A-Z])/.test(value) ? null : { includeUpperCase: true };
    };
  }

  private includeNumberValidator(): ValidatorFn {
    return (control: AbstractControl<string>) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /(?=.*?\d)/.test(value) ? null : { includeNumber: true };
    };
  }

  private includeSymbolValidator(): ValidatorFn {
    return (control: AbstractControl<string>) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /(?=.*?[!-\/:-@\[-`{-~])/.test(value) ? null : { includeSymbol: true };
    };
  }
}
