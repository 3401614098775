<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>ログイン</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <idp-box [formGroup]="form">
    <span slot="header">すでに会員の方</span>
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <input
        type="email"
        placeholder="user@example.com"
        formControlName="email"
        tplPressEnter
        (pressEnter)="submit()"
      />
    </tpl-form-field>
    <tpl-form-field>
      <tpl-form-label>パスワード</tpl-form-label>
      <input type="password" placeholder="********" formControlName="password" tplPressEnter (pressEnter)="submit()" />
    </tpl-form-field>
    <div class="box-action">
      <button
        class="button"
        tpl-button
        color="basic"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        ログイン
      </button>
      <button
        class="plain-button size-small"
        tpl-button
        color="basic"
        buttonType="plain"
        width="auto"
        icon="arrow-right"
        routerLink="/auth/password_reset"
      >
        パスワードを忘れた方はこちら
      </button>
    </div>
  </idp-box>
  <idp-box>
    <span slot="header">新規会員登録をご希望の方</span>
    <div class="box-action">
      <a
        class="button"
        tpl-button
        color="accent"
        width="fixed"
        buttonType="filled"
        icon="arrow-right"
        routerLink="/auth/register"
      >
        アカウントを作成
      </a>
    </div>
    <div class="description">
      本サービスのご利用には会員登録が必要です。
      <br />
      ご登録される方は「アカウント作成」より登録画面へ進みください。
    </div>
  </idp-box>
</idp-page-container>
