import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { InternalApiHttpError } from '@triple/triad';
import { createFailure, createSuccess } from '@twogate-npm/toolbox-utils';
import { catchError, firstValueFrom, map, of, tap } from 'rxjs';

import { ProfileApi } from '../apis/profile.api';
import { InputProfile } from '../models/profile.type';
import { ProfileActions } from '../store/profile';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private readonly store = inject(Store);
  private readonly profileApi = inject(ProfileApi);

  fetchProfile() {
    const source$ = this.profileApi.fetchProfile().pipe(
      tap((profile) => {
        this.store.dispatch(ProfileActions.setProfile({ profile }));
      }),
    );

    return firstValueFrom(source$);
  }

  updateProfile(param: InputProfile) {
    const source$ = this.profileApi.updateProfile(param).pipe(
      tap((profile) => {
        this.store.dispatch(ProfileActions.setProfile({ profile }));
      }),
      map((profile) => createSuccess(profile)),
      catchError((err: HttpErrorResponse) =>
        of(createFailure(new InternalApiHttpError('プロフィールの更新に失敗しました', { originalErrorResponse: err }))),
      ),
    );

    return firstValueFrom(source$);
  }

  saveProfileTemporary(param: InputProfile) {
    this.store.dispatch(ProfileActions.setInputProfile({ inputProfile: param }));
  }
}
