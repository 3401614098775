import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostListener,
  Input,
  inject,
} from '@angular/core';
import { IconComponent } from '@twogate-npm/toolbox-angular';

import { RadioGroupComponent } from '../radio-group/radio-group.component';

@Component({
  selector: 'tpl-simple-radio-button',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './simple-radio-button.component.html',
  styleUrls: ['./simple-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleRadioButtonComponent<T> implements DoCheck {
  @Input()
  value!: T;

  isActive: boolean = false;

  private readonly group = inject(RadioGroupComponent, { optional: true });
  private readonly cdRef = inject(ChangeDetectorRef);

  ngDoCheck() {
    const active = this.getActive();
    if (active !== this.isActive) {
      this.isActive = active;
      this.cdRef.markForCheck();
    }
  }

  getActive() {
    return this.group?.value === this.value;
  }

  writeValue() {
    this.group?.writeValue(this.value);
  }

  @HostListener('keydown.space', ['$event'])
  setCheckFromKey($event: KeyboardEvent) {
    $event.preventDefault();
    this.writeValue();
  }
}
