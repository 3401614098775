import { Store } from '@ngrx/store';
import { configureScope } from '@sentry/browser';

import { TriadConfig } from '@triad/features/config';

export abstract class SentryDataServiceBase {
  abstract store: Store;
  abstract config: TriadConfig;

  abstract setUserScope(): void;

  initialize() {
    this.setUserScope();
    this.setVersionScope();
  }

  setVersionScope() {
    configureScope((scope) => {
      scope.setTag('tenant', this.config.tenantId);
      scope.setTags(this.config.versions);
    });
  }
}
