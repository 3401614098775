import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({ name: 'phoneNumber', standalone: true })
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';

    const parse = parsePhoneNumberFromString(value, 'JP');
    if (parse?.isValid()) {
      return parse.country === 'JP' ? parse.formatNational() : parse.formatInternational();
    }
    return '';
  }
}
