import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormComponents } from '@triple/triad';

@Component({
  selector: 'idp-birth-date-form',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormComponents],
  templateUrl: './birth-date-form.component.html',
  styleUrl: './birth-date-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirthDateFormComponent implements ControlValueAccessor {
  @ViewChild('yearRef')
  yearRef?: ElementRef<HTMLInputElement>;

  @ViewChild('monthRef')
  monthRef?: ElementRef<HTMLInputElement>;

  @ViewChild('dayRef')
  dayRef?: ElementRef<HTMLInputElement>;

  birthDateForm = new FormGroup(
    {
      year: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      }),
      month: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      }),
      day: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      }),
    },
    {
      validators: [this.dateValidator()],
    },
  );

  focused = false;

  private readonly ngControl = inject(NgControl, {
    self: true,
    optional: true,
  });

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.birthDateForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.onTouchedCallback();

      // yearRef が フォーカスされていて、 4文字入力されたら、monthRef にフォーカスを移す
      if (this.yearRef?.nativeElement === document.activeElement && value.year?.length === 4) {
        this.monthRef?.nativeElement.focus();
      } else if (this.monthRef?.nativeElement === document.activeElement && value.month?.length === 2) {
        this.dayRef?.nativeElement.focus();
      }
      if (this.birthDateForm.valid) {
        this.onChangeCallback(`${value.year}-${value.month}-${value.day}`);
      } else {
        this.onChangeCallback('');
      }
    });
  }

  writeValue(date: string): void {
    if (!date) {
      return;
    }
    const dateObj = new Date(date);
    // month と day は 1桁の場合は 0埋めする
    this.birthDateForm.setValue({
      year: dateObj.getFullYear().toString(),
      month: (dateObj.getMonth() + 1).toString().padStart(2, '0'),
      day: dateObj.getDate().toString().padStart(2, '0'),
    });
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  keyPressInYearForm(event: KeyboardEvent) {
    if (this.yearRef?.nativeElement.selectionStart === 4 && this.yearRef?.nativeElement.selectionEnd === 4) {
      if (event.key === 'ArrowRight') {
        this.monthRef?.nativeElement.focus();
        event.preventDefault();
      }
    }
  }
  keyPressInMonthForm(event: KeyboardEvent) {
    if (this.monthRef?.nativeElement.selectionStart === 0 && this.monthRef?.nativeElement.selectionEnd === 0) {
      if (event.key === 'Backspace') {
        this.yearRef?.nativeElement.focus();
      } else if (event.key === 'ArrowLeft') {
        this.yearRef?.nativeElement.focus();
        event.preventDefault();
      }
    } else if (this.monthRef?.nativeElement.selectionStart === 2 && this.monthRef?.nativeElement.selectionEnd === 2) {
      if (event.key === 'ArrowRight') {
        this.dayRef?.nativeElement.focus();
        event.preventDefault();
      }
    }
  }
  keyPressInDayForm(event: KeyboardEvent) {
    if (this.dayRef?.nativeElement.selectionStart === 0 && this.dayRef?.nativeElement.selectionEnd === 0) {
      if (event.key === 'Backspace') {
        this.monthRef?.nativeElement.focus();
      } else if (event.key === 'ArrowLeft') {
        this.monthRef?.nativeElement.focus();
        event.preventDefault();
      }
    }
  }

  focus() {
    const { year, month, day } = this.birthDateForm.getRawValue();
    if (year.length < 4 && month.length === 0 && day.length === 0) {
      this.yearRef?.nativeElement.focus();
    } else if (month.length < 2 && day.length === 0) {
      this.monthRef?.nativeElement.focus();
    } else {
      this.dayRef?.nativeElement.focus();
    }
  }
  focusForm() {
    this.focused = true;
  }
  blurForm(type: 'year' | 'month' | 'day') {
    this.focused = false;
    if (type === 'year') {
      this.formatYear();
    } else if (type === 'month') {
      this.formatMonth();
    } else if (type === 'day') {
      this.formatDay();
    }
  }
  clickForm($event: MouseEvent) {
    // フォームを直接クリックしたときに、 focus() が呼ばれないようにする
    $event.stopPropagation();
  }

  formatYear() {
    const { year } = this.birthDateForm.getRawValue();
    const formatted = year
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^0-9]/g, '');
    this.birthDateForm.patchValue({ year: formatted });
  }
  formatMonth() {
    const { month } = this.birthDateForm.getRawValue();
    const formatted = month
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^0-9]/g, '');

    if (formatted.length === 1) {
      this.birthDateForm.patchValue({ month: `0${formatted}` });
    } else {
      this.birthDateForm.patchValue({ month: formatted });
    }
  }
  formatDay() {
    const { day } = this.birthDateForm.getRawValue();
    const formatted = day
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^0-9]/g, '');

    if (formatted.length === 1) {
      this.birthDateForm.patchValue({ day: `0${formatted}` });
    } else {
      this.birthDateForm.patchValue({ day: formatted });
    }
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl<{ year: string; month: string; day: string }>) => {
      const value = control.value;
      if (value && value.year?.length === 4 && value.month?.length === 2 && value.day?.length === 2) {
        const year = parseInt(value.year);
        const month = parseInt(value.month) - 1;
        const day = parseInt(value.day);

        const date = new Date(year, month, day);
        if (
          1900 < year &&
          date.getFullYear() === year &&
          date.getMonth() === month &&
          date.getDate() === day &&
          date.getTime() < Date.now()
        ) {
          return null;
        } else {
          return { invalidDate: true };
        }
      }
      return { required: true };
    };
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: string) => void = () => {};
}
