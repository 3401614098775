import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SnsLogoComponent, SnsLogoName } from '../sns-logo/sns-logo.component';

export type SnsList = {
  name: SnsLogoName;
  link: string;
}[];

@Component({
  selector: 'tpl-sns-logo-list',
  standalone: true,
  imports: [CommonModule, SnsLogoComponent],
  templateUrl: './sns-logo-list.component.html',
  styleUrl: './sns-logo-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnsLogoListComponent {
  @Input()
  list!: SnsList;
  @Input()
  @HostBinding('style.justifyContent')
  justifyContent: 'start' | 'center' | 'end' = 'center';
}
