<div class="password-form">
  <input
    [type]="inputType"
    placeholder="********"
    [autocomplete]="passwordType"
    class="password-form-input"
    [formControl]="form"
  />

  <div class="password-form-visibility" (click)="togglePasswordVisibility()">
    @if (passwordVisibility) {
      <tbx-icon name="eye-off" class="password-form-visibility-icon"></tbx-icon>
    } @else {
      <tbx-icon name="eye" class="password-form-visibility-icon"></tbx-icon>
    }
  </div>
</div>
