import { InjectionToken } from '@angular/core';

import { EnvironmentName } from './environment-name.type';

export interface Link {
  label: string;
  href: string;
}

export type LibType = 'ticket' | 'idp';

export const TriadConfigToken = new InjectionToken<TriadConfig>('TRIAD_CONFIG');
export interface TriadConfig {
  type: LibType;
  environment: EnvironmentName;
  production: boolean;
  tenantId: string;

  recaptchaSiteKey?: string;
  recaptchaCheckboxSiteKey?: string;
  kenallApiPublicKey: string;

  versions: {
    revision: string;
    branch: string;
    triadVersion: string;
  };
}

export type TriadGroupSiteType = LibType | 'store';
export type TriadGroupSiteUrls = { [key in TriadGroupSiteType]: string };
