import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

export const featureKey = 'auth';

export const setIsOAuthFlow = createAction('[Auth] Set Is OAuth Flow', props<{ isOAuthFlow: boolean }>());
export const resetIsOAuthFlow = createAction('[Auth] Reset Is OAuth Flow');
export const setRequireSmsMfa = createAction('[Auth] Set Require Sms Mfa', props<{ requireSmsMfa: boolean }>());
export const signOut = createAction('[Auth] Sign Out');

export const AuthActions = {
  setIsOAuthFlow,
  resetIsOAuthFlow,
  setRequireSmsMfa,
  signOut,
};

export interface State {
  isOAuthFlow: boolean;
  requireSmsMfa: boolean;
}
export const initialState: State = {
  isOAuthFlow: false,
  requireSmsMfa: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.setIsOAuthFlow, (state, { isOAuthFlow }) => ({ ...state, isOAuthFlow })),
  on(AuthActions.resetIsOAuthFlow, (state) => ({ ...state, isOAuthFlow: initialState.isOAuthFlow })),
  on(AuthActions.setRequireSmsMfa, (state, { requireSmsMfa }) => ({ ...state, requireSmsMfa })),
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>(featureKey);
export const selectIsOAuthFlow = createSelector(selectFeature, (state) => state.isOAuthFlow);
export const selectRequireSmsMfa = createSelector(selectFeature, (state) => state.requireSmsMfa);
