import { HttpContextToken, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { IdpConfigService } from '../../config';

export const SKIP_CREDENTIAL_INTERCEPTOR = new HttpContextToken(() => false);

export const withCredentialInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next) => {
  const configService = inject(IdpConfigService);
  if (req.url.startsWith(configService.apiEndpoint) && !req.context.get(SKIP_CREDENTIAL_INTERCEPTOR)) {
    return next(req.clone({ withCredentials: true }));
  } else {
    return next(req);
  }
};
