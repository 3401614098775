import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { User } from '../models/user.type';

@Injectable({ providedIn: 'root' })
export class UserApi {
  baseUrl = 'v1/internal/me';
  private readonly http = inject(HttpClient);

  fetchCurrentUser() {
    return this.http.get<User>(`${this.baseUrl}`);
  }
  deleteCurrentUser() {
    return this.http.delete<void>(`${this.baseUrl}`);
  }
}
