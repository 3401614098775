<tpl-breadcrumb>
  <tpl-breadcrumb-item>
    <a routerLink="/">HOME</a>
  </tpl-breadcrumb-item>
  <tpl-breadcrumb-item>
    <span>会員登録</span>
  </tpl-breadcrumb-item>
</tpl-breadcrumb>

<idp-page-container>
  <idp-box>
    <span slot="header">メールアドレスの有効確認</span>
    <tpl-form-field>
      <tpl-form-label>メールアドレス</tpl-form-label>
      <input
        type="email"
        placeholder="user@example.com"
        [formControl]="emailForm"
        tplPressEnter
        (pressEnter)="submit()"
      />
    </tpl-form-field>
    <div class="description">
      メールアドレスを入力し、［送信する］を押してください。 確認コードをメールでお送りいたします。
      次に表示されるページで1時間以内に確認コードを入力し、お客様情報をご登録ください。
    </div>
    <div class="description">
      ※ドメイン（アドレス）指定受信・拒否などを設定されている場合、 メールが届きません。「{{
        authEmailDomain
      }}」が受信できるように設定の上、お手続きください。
    </div>

    <div class="agreement">
      <tpl-checkbox [formControl]="agreementForm">
        @for (link of links; track $index; let last = $last) {
          <a tpl-link [href]="link.href" target="_blank" rel="noopener noreferrer">{{ link.text }}</a>
          @if (!last) {
            及び
          }
        }
        に同意する
      </tpl-checkbox>
    </div>

    <div class="box-action">
      <button
        tpl-button
        color="basic"
        icon="arrow-right"
        width="fixed"
        [disabled]="emailForm.invalid || agreementForm.invalid"
        (click)="submit()"
      >
        送信する
      </button>
    </div>

    <tpl-recaptcha-message></tpl-recaptcha-message>
  </idp-box>
</idp-page-container>
