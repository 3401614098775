import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SessionService } from '@triple/triad';
import { filter, map } from 'rxjs';

import { UserActions, selectCurrentUser } from '@idp/features/user/store/user';

import { AuthActions } from '../store/auth';

import { AuthUsecaseService } from './auth-usecase.service';

@Injectable({
  providedIn: 'root',
})
export class IdpSessionService extends SessionService {
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly authUsecase = inject(AuthUsecaseService);

  get isSignedIn$() {
    return this.store.select(selectCurrentUser).pipe(
      filter((v) => v !== undefined),
      map((v) => !!v),
    );
  }

  navigateSignUp() {
    this.router.navigate(['/auth', 'register']);
  }

  navigateSignIn() {
    this.router.navigate(['/auth', 'sign_in']);
  }

  async signOut() {
    const result = await this.authUsecase.signOut();
    if (result) {
      this.store.dispatch(AuthActions.signOut());
      this.store.dispatch(UserActions.setCurrentUser({ user: null }));
    }
    return result;
  }
}
