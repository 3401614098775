import { Directive, HostListener, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[idpFormatZipCode]', standalone: true })
export class FormatZipCodeDirective {
  private readonly control = inject(NgControl);

  @HostListener('blur')
  format() {
    if (this.control.valid) {
      if (typeof this.control.value !== 'string') return;
      const zipCode = this.control.value.replace(/[^0-9]/g, '');
      if (zipCode.length === 7) {
        this.control.control?.setValue(`${zipCode.slice(0, 3)}-${zipCode.slice(3)}`);
      }
    }
  }
}
