import { Injectable, inject } from '@angular/core';

import { TriadConfigToken } from '../public-api';

@Injectable({
  providedIn: 'root',
})
export class TriadService {
  private readonly config = inject(TriadConfigToken);

  initialize() {
    this.logCurrentVersion();
  }

  private logCurrentVersion() {
    const version = this.config.versions.triadVersion;
    // eslint-disable-next-line no-console
    console.log(
      `%cv${version}`,
      'color: #fff; background-color: brown; padding: 4px 8px; border-radius: 4px; font-size: 13px; font-weight: bold',
    );

    const body = document.querySelector('body');
    if (body) {
      body.setAttribute('data-version', version);
    }
  }
}
