<div class="note">パスワードは以下の条件を満たす必要があります</div>

<div class="requirements">
  <div class="item" [class.passed]="isPassed('includeNumber')">
    @if (isPassed('includeNumber')) {
      <tbx-icon name="check"></tbx-icon>
    }
    <div class="text">数字を含む</div>
  </div>
  <div class="item" [class.passed]="isPassed('includeUpperCase')">
    @if (isPassed('includeUpperCase')) {
      <tbx-icon name="check"></tbx-icon>
    }
    <div class="text">大文字英字を含む</div>
  </div>
  <div class="item" [class.passed]="isPassed('includeLowerCase')">
    @if (isPassed('includeLowerCase')) {
      <tbx-icon name="check"></tbx-icon>
    }
    <div class="text">小文字英字を含む</div>
  </div>
  <div class="item" [class.passed]="isPassed('minlength')">
    @if (isPassed('minlength')) {
      <tbx-icon name="check"></tbx-icon>
    }
    <div class="text">8文字以上</div>
  </div>
</div>
