export interface Prefecture {
  prefectureCode: string; // JIS X 0401
  text: string;
  topic: string | null;
  type: string;
}

export const PREFECTURE_SELECT_LIST: Prefecture[] = [
  { prefectureCode: '01', text: '北海道', topic: 'hokkaido', type: '' },
  { prefectureCode: '02', text: '青森', topic: 'aomori', type: '県' },
  { prefectureCode: '03', text: '岩手', topic: 'iwate', type: '県' },
  { prefectureCode: '04', text: '宮城', topic: 'miyagi', type: '県' },
  { prefectureCode: '05', text: '秋田', topic: 'akita', type: '県' },
  { prefectureCode: '06', text: '山形', topic: 'yamagata', type: '県' },
  { prefectureCode: '07', text: '福島', topic: 'fukushima', type: '県' },
  { prefectureCode: '08', text: '茨城', topic: 'ibaraki', type: '県' },
  { prefectureCode: '09', text: '栃木', topic: 'tochigi', type: '県' },
  { prefectureCode: '10', text: '群馬', topic: 'gumma', type: '県' },
  { prefectureCode: '11', text: '埼玉', topic: 'saitama', type: '県' },
  { prefectureCode: '12', text: '千葉', topic: 'chiba', type: '県' },
  { prefectureCode: '13', text: '東京', topic: 'tokyo', type: '都' },
  { prefectureCode: '14', text: '神奈川', topic: 'kanagawa', type: '県' },
  { prefectureCode: '15', text: '新潟', topic: 'niigata', type: '県' },
  { prefectureCode: '16', text: '富山', topic: 'toyama', type: '県' },
  { prefectureCode: '17', text: '石川', topic: 'ishikawa', type: '県' },
  { prefectureCode: '18', text: '福井', topic: 'fukui', type: '県' },
  { prefectureCode: '19', text: '山梨', topic: 'yamanashi', type: '県' },
  { prefectureCode: '20', text: '長野', topic: 'nagano', type: '県' },
  { prefectureCode: '21', text: '岐阜', topic: 'gifu', type: '県' },
  { prefectureCode: '22', text: '静岡', topic: 'shizuoka', type: '県' },
  { prefectureCode: '23', text: '愛知', topic: 'aichi', type: '県' },
  { prefectureCode: '24', text: '三重', topic: 'mie', type: '県' },
  { prefectureCode: '25', text: '滋賀', topic: 'shiga', type: '県' },
  { prefectureCode: '26', text: '京都', topic: 'kyoto', type: '府' },
  { prefectureCode: '27', text: '大阪', topic: 'osaka', type: '府' },
  { prefectureCode: '28', text: '兵庫', topic: 'hyogo', type: '県' },
  { prefectureCode: '29', text: '奈良', topic: 'nara', type: '県' },
  { prefectureCode: '30', text: '和歌山', topic: 'wakayama', type: '県' },
  { prefectureCode: '31', text: '鳥取', topic: 'tottori', type: '県' },
  { prefectureCode: '32', text: '島根', topic: 'shimane', type: '県' },
  { prefectureCode: '33', text: '岡山', topic: 'okayama', type: '県' },
  { prefectureCode: '34', text: '広島', topic: 'hiroshima', type: '県' },
  { prefectureCode: '35', text: '山口', topic: 'yamaguchi', type: '県' },
  { prefectureCode: '36', text: '徳島', topic: 'tokushima', type: '県' },
  { prefectureCode: '37', text: '香川', topic: 'kagawa', type: '県' },
  { prefectureCode: '38', text: '愛媛', topic: 'ehime', type: '県' },
  { prefectureCode: '39', text: '高知', topic: 'kochi', type: '県' },
  { prefectureCode: '40', text: '福岡', topic: 'fukuoka', type: '県' },
  { prefectureCode: '41', text: '佐賀', topic: 'saga', type: '県' },
  { prefectureCode: '42', text: '長崎', topic: 'nagasaki', type: '県' },
  { prefectureCode: '43', text: '熊本', topic: 'kumamoto', type: '県' },
  { prefectureCode: '44', text: '大分', topic: 'oita', type: '県' },
  { prefectureCode: '45', text: '宮崎', topic: 'miyazaki', type: '県' },
  { prefectureCode: '46', text: '鹿児島', topic: 'kagoshima', type: '県' },
  { prefectureCode: '47', text: '沖縄', topic: 'okinawa', type: '県' },
  { prefectureCode: '99', text: '海外（その他）', topic: null, type: '' },
];
