<div class="input-group" [formGroup]="phoneNumberForm">
  <mat-select
    class="country-code-selector"
    formControlName="countryCode"
    [panelWidth]="200"
    placeholder="国コード"
    *ngIf="countriesData.length !== 1"
  >
    @for (country of countriesData; track country.text) {
      <mat-option [value]="country.callingCode">
        <div class="option">
          <div class="flag" [innerHTML]="country.flagData | safeHtml"></div>
          {{ country.text }}
        </div>
      </mat-option>
    }
  </mat-select>

  <input
    type="tel"
    autocomplete="tel"
    color="accent"
    [formControl]="phoneNumberForm.controls.phoneNumber"
    (blur)="onBlur()"
    placeholder="09001234567"
    class="phone-input"
  />
</div>
@if (phoneNumberForm.dirty || phoneNumberForm.touched) {
  <tpl-form-error [errors]="phoneNumberForm.errors ?? null" [messages]="errorMessages"></tpl-form-error>
}
