import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { configureScope } from '@sentry/browser';
import { SentryDataServiceBase, TriadConfigToken } from '@triple/triad';
import { nonNullable } from '@twogate-npm/toolbox-angular';

import { selectCurrentUser } from '@idp/features/user/store/user';

@Injectable({ providedIn: 'root' })
export class SentryDataService extends SentryDataServiceBase {
  readonly store = inject(Store);
  readonly config = inject(TriadConfigToken);

  setUserScope() {
    this.store
      .select(selectCurrentUser)
      .pipe(nonNullable())
      .subscribe((user) => {
        configureScope((scope) => {
          scope.setUser({ id: user.id });
        });
      });
  }
}
